import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import { addSubscription } from "../../Reducers/authReducer";
import { useGetUserDataQuery } from '../../services/login';
import Button from '@mui/material/Button';
import { useGetSubscriptionsQuery } from '../../services/subscriptions';
import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Modal } from 'react-bootstrap'
import Purchase from '../Purchase';
import { Helmet } from "react-helmet";
import isMediaLanguageCode from '../../Components/isMediaLanguageCode';

function Pricing() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, isCustomer, languageCode, countryCode, subscription, mediaLang } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch();

  const { data: userData } = useGetUserDataQuery(
    { userId: userId, userToken: userToken },
    { count: 1 },
    { refetchOnMountOrArgChange: true }
  )

  const { data: infoData, error, isError, isFetching, isLoading, isSuccess } = useGetSubscriptionsQuery(
    { country_code: countryCode, language_code: isMediaLanguageCode(mediaLang) },
    { count: 1 },
    { refetchOnMountOrArgChange: true }
  )

  let subscriptions;
  let subscriptionData;

  if (infoData?.defaultCountry) {
    subscriptions = infoData?.defaultCountry?.subscriptions
    subscriptionData = infoData?.defaultCountry
  } else if (infoData?.country) {
    subscriptions = JSON.parse(infoData?.country?.subscriptions)
    subscriptionData = infoData?.country
  } else {
    subscriptions = []
    subscriptionData = ''
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  
  const [getPurchaseModal, setPurchaseModal] = React.useState(false);

  async function selectSubscription(name, code, trial, price, offer, title, text, slug, country, currency) {
    // const getSubscriptionData = subscriptionData.find((l) => l.code === data)
    dispatch(addSubscription({
      subscription: {
        name: name,
        code: code,
        trial: trial,
        price: price,
        offer: offer,
        title: title,
        text: text,
        slug: slug,
        country: country,
        currency: currency
      }
    }));
    // history.push(`/${languageCode}/purchase`)
    setPurchaseModal(true)
  }

  function hidePurchaseModal() {
    setPurchaseModal(false)
  }


 

  function getButtonText(code, price) {
    if (userData?.user?.membership) {
      if (userData?.user?.membership?.plan === code) {
        return t('activated')
      } else {
        if (userData?.user?.membership?.price >= price) {
          return t('select')
        }

        if (userData?.user?.membership?.price <= price) {
          return t('upgrade')
        }
      }
    } else {
      return t('select')
    }
  }

  function setDisabled(code, price) {
    if (userData?.user?.membership?.plan === code) {
      return true
    } else if (userData?.user?.membership?.price >= price) {
      return true
    } else {
      return false
    }
  }

 

  function getPriceCalculated(getPrice) {
    if (subscriptionData?.currency === 'BHD') {
      return getPrice / 1000
    } else if (subscriptionData?.currency === 'JOD') {
      return getPrice / 1000
    } else if (subscriptionData?.currency === 'KWD') {
      return getPrice / 1000
    } else if (subscriptionData?.currency === 'OMR') {
      return getPrice / 1000
    } else if (subscriptionData?.currency === 'TND') {
      return getPrice / 1000
    } else {
      return getPrice / 100
    }
  }



  function checkDirectionlng() {
    if (languageCode === 'ar') {
      return true
    } else if (languageCode === 'urdu') {
      return true
    } else {
      return false
    }
  }


  function DisplayPriceList(item, i) {
    return (
      <div className="col-md-12 subscription-options-list-card p0" style={{ marginBottom: '10px' }} key={i}>
        <div className="pricing-card" style={{
          background: subscription?.code === item.code ? '#2c224508' : '#2c224508',
          borderRadius: '10px',
          padding: '20px',
          position: 'relative',
          width: '100%',
          border: '0.5px solid #03314b1c'

        }}>
          <div className="pricing-card-header">

            <div>

              <div style={{ fontSize: '1.4rem', fontWeight: 300 }}> {t('month_full_access_1')}</div>
              {item.offer ?
                <>
                  <div>
                    <small className="line-through" style={{ fontSize: '14px', fontWeight: 400 }}>
                      {subscriptionData?.currency} {getPriceCalculated(item.price)}
                    </small>
                    <br />
                    <div className="price-size" style={{ fontFamily: 'sans-serif', display: 'flex', alignItems: 'center' }}>
                      <div style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                        {subscriptionData?.currency} {getPriceCalculated(item.offer)}
                      </div>
                      <small style={{ fontSize: '14px', fontWeight: 300 }}>/</small>
                      <small style={{ fontSize: '14px', fontWeight: 300 }}><i>{t('paid')} {t('monthly')}</i></small>
                    </div>
                  </div>
                  <div className="price-offer-tag">{item.offer_slug}</div>
                </>
                :
                <>
                  <div className="price-size" style={{ fontFamily: 'sans-serif', display: 'flex', alignItems: 'center' }}>
                    <div style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                      {subscriptionData?.currency} {getPriceCalculated(item.price)}
                    </div>
                    <small style={{ fontSize: '14px', fontWeight: 300 }}>/</small>
                    <small style={{ fontSize: '14px', fontWeight: 300 }}><i>{t('paid')} {t('monthly')}</i></small>
                  </div>
                </>
              }
            </div>

            <div>
              {item.highlight &&
                <div style={{ display: 'flex', justifyContent: 'center' }}><span className="price-highlight">{item.highlight}</span></div>
              }
            </div>
          </div>

          <br />
          {userData?.user?.membership?.plan === item.code &&
            <div className="active-tag">{t('active')}</div>
          }
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingBottom: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'center', color: '#25273478' }}><LockIcon style={{ fontSize: '16px' }} /> &nbsp;<span style={{ fontSize: '14px' }}>Secure</span></div>
            <Button
              color="primary"
              variant="contained"
              size="medium"
              onClick={(() => setDisabled(item.code, item.price) ? '' : selectSubscription(item.name, item.code, item.trial, item.price, item.offer, item.title, item.text, item.slug, subscriptionData?.code, subscriptionData?.currency))}
              disabled={setDisabled(item.code, item.price)}
              style={{ width: 'auto', background: '#446275', fontWeight: 400, color: '#f7f7f7' }}
            >
              {getButtonText(item.code, item.price)}
            </Button>
          </div>
        </div>
      </div>
    )
  }


  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'ud') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  const fontFamily_Text = {
    textAlign: languageCode === 'ar' || languageCode === 'ud' ? 'right' : 'left',
    fontFamily: fontFamilyForAll,
  }


  return (
    <div className="Plans-page">

      <Helmet>
        <title>{t('membership_banner.title')} | LiT: Big Ideas in Little Time</title>
        <meta name="description" content={t('membership_banner.text')} />
      </Helmet>

      <div className="container">
        <Modal style={fontFamily_Text} className="purchase_modal" show={getPurchaseModal} onHide={hidePurchaseModal} centered animation={false}>
          <Modal.Body>
            <div className="Header-close-modal" style={{ position: 'absolute', right: '10px', top: '10px', zIndex: 1 }} onClick={hidePurchaseModal}>
              <IconButton
                aria-haspopup="true"
              >
                <CloseIcon className="Header-close-modal-icon" />
              </IconButton>
            </div>

            <Purchase />
          </Modal.Body>
        </Modal>


        <div className='section-card'>
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className={`col-md-6 p0`}>
              <div className='sp-title-p' style={{ background: '#fcfcfc', border: '1px solid #03314b1c', marginTop: '20px', borderRadius: '10px', padding: '20px' }}>
                <div>
                  <h1 style={{ color: '#333', fontSize: '1.2rem' }}>
                    {t('membership_banner.text')}
                  </h1>
                  {/* <div style={{ color: '#333', fontSize: '14px' }}> {t('membership_banner.text')} </div> */}
                </div>
                <br />
                <div className="pricing-trial-eligible">
                  <h5><strong>{t('membership_benefits_heading')}</strong></h5>
                  <br />
                </div>

                <div className='price-steps-card'>
                  <div style={{ border: '0.1px solid #2b2245', width: '1px', height: '100px' }}></div>
                  <div style={{ margin: '-3px 20px', fontSize: '14px' }}>{t('membership_benefits.a')}</div>
                  <div className={checkDirectionlng() ? 'radio-steps-rtl' : 'radio-steps-ltr'}>
                    <input type="radio" checked style={{ width: '15px', height: '15px' }} />
                  </div>
                </div>

                <div className='price-steps-card'>
                  <div style={{ border: '0.1px solid #2b2245', width: '1px', height: '100px' }}></div>
                  <div style={{ margin: '-3px 20px', fontSize: '14px' }}>{t('membership_benefits.b')}</div>
                  <div className={checkDirectionlng() ? 'radio-steps-rtl' : 'radio-steps-ltr'}>
                    <input type="radio" checked style={{ width: '15px', height: '15px' }} />
                  </div>
                </div>

                <div className='price-steps-card'>
                  <div style={{ margin: '-3px 20px', fontSize: '14px' }}>{t('membership_benefits.c')}</div>
                  <div className={checkDirectionlng() ? 'radio-steps-rtl' : 'radio-steps-ltr'}>
                    <input type="radio" checked style={{ width: '15px', height: '15px' }} />
                  </div>
                </div>
 
              </div>
              <br />

              <div className="row subscription-options-list-row" style={{ justifyContent: 'start' }}>
                  <>
                    {subscriptions?.map((item, i) => {
                      return (DisplayPriceList(item, i))
                    }
                    )}
                  </>
                </div>
            </div>
            
          </div>

        </div>
      </div>
      <br />
    </div >
  );
}

export default Pricing;
