import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Modal } from 'react-bootstrap'
import "antd/dist/antd.min.css";

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LoginForm from './LoginForm';

function LoginModal({ isSigninModal, signinModal }) {

  return (
    <>


      <Modal className="Header-Signin-dialogue login_modal" show={isSigninModal} onHide={signinModal} centered animation={false}>
        <Modal.Body>
          <div className="Header-close-modal" onClick={signinModal}>
            <IconButton
              aria-haspopup="true"
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
          </div>
          <LoginForm signinModal={signinModal} />
        </Modal.Body>
      </Modal>


    </>
  );
}

export default LoginModal;
