import * as React from 'react';
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './index.scss';
import { useDispatch, useSelector } from "react-redux";
import ListItem from '@mui/material/ListItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';

export default function Categories({ categories }) {
  const { userId, userToken, languageCode, countryCode, mediaLang } = useSelector((state) => state.auth);
  const { t } = useTranslation()
  const location = useLocation();
  const list = new URLSearchParams(location.search).get('list') || 'all'

  let fontFamilyForAll
  let dropDir
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
    dropDir = 'right'
  } else if (languageCode === 'ud') {
    fontFamilyForAll = 'AlQalam Shakir'
    dropDir = 'right'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
    dropDir = 'left'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='Header-waller-connect1'>
      <ListItem
        style={{ padding: '6px 8px' }}
        button
        id="lock-button"
        aria-haspopup="listbox"
        aria-controls="lock-menu"
        aria-label="when device is locked"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickListItem}
      >
        <div className='mobile-hide' style={fontFamily}>{t('categories')}</div>
        {window.innerWidth < 992 ? <MenuIcon className="expand-style" /> :
          <ExpandMoreIcon className="expand-style" />
        }
      </ListItem>
      <Menu
        className="header-category-list"
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}

        transformOrigin={{ horizontal: dropDir, vertical: 'top' }}
        anchorOrigin={{ horizontal: dropDir, vertical: 'bottom' }}
      >


        <div style={{ overflow: 'auto', borderBottom: '1px solid #eeee', width: '100%', padding: '5px 0px 5px 10px', marginBottom: '10px', flexWrap: 'nowrap', overflow: 'auto', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'start' }}>
          <Button
            style={fontFamily}
            disabled={list === 'books' ? true : false}
            component={Link}
            to={`/${languageCode}/${mediaLang}/search/all?list=${'books'}`}
            onClick={(event) => handleMenuItemClick(event)}
          >
            <div style={{ display: 'flex', alignItems: 'center', color: '#446275' }}>
              <img src={`/BooksIcon.png`} style={{ width: '20px' }} />
              <span style={{ margin: '0 7px', textTransform: 'capitalize', fontWeight: 400, fontSize: '1rem' }}>  {t('books')}</span>
            </div>
          </Button>
          <Button
            style={fontFamily}
            disabled={list === 'documentary' ? true : false}
            component={Link}
            to={`/${languageCode}/${mediaLang}/search/all?list=${'documentary'}`}
            onClick={(event) => handleMenuItemClick(event)}
          >
            <div style={{ display: 'flex', alignItems: 'center', color: '#446275' }}>
              <img src={`/documentaryIcon.png`} style={{ width: '20px' }} />
              <span style={{ margin: '0 7px', textTransform: 'capitalize', fontWeight: 400, fontSize: '1rem' }}>  {t('documentary')}</span>
            </div>
          </Button>
          <Button
            style={fontFamily}
            disabled={list === 'courses' ? true : false}
            component={Link}
            to={`/${languageCode}/${mediaLang}/search/all?list=${'courses'}`}
            onClick={(event) => handleMenuItemClick(event)}
          >
            <div style={{ display: 'flex', alignItems: 'center', color: '#446275' }}>
              <img src={`/TutorialsIcon.png`} style={{ width: '20px' }} />
              <span style={{ margin: '0 7px', textTransform: 'capitalize', fontWeight: 400, fontSize: '1rem' }}>  {t('courses')}</span>
            </div>
          </Button>
        </div>


        {categories?.map((item, i) =>
          <MenuItem
            className="header-category-list-item"
            style={fontFamily}
            component={Link}
            to={`/${languageCode}/${mediaLang}/search/${item.title}`}
            key={i}
            onClick={(event) => handleMenuItemClick(event, i)}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={item.icon_url} style={{ width: '20px' }} />
              <span style={{ margin: '0 7px' }}>{item.title}</span>
            </div>

          </MenuItem>
        )}

      </Menu>
    </div>
  );
}