import React, { useState, useEffect, useMemo, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from './Pages/Home';
import Search from './Pages/Search';
import Book from './Pages/Book';
import Summary from './Pages/Summary';
import Library from './Pages/Library';
import Settings from './Pages/Account/Settings';
import Profile from "./Pages/Account/Profile";
import Subscription from "./Pages/Account/Subscription";
import Membership from './Pages/Account/Membership';
import PaymentMethod from './Pages/Account/PaymentMethod';
import Support from './Pages/Account/Support';
import Pricing from './Pages/Pricing';
import Purchase from './Pages/Purchase';
import PurchaseSuccess from './Pages/PurchaseSuccess';
import ResetPassword from "./Pages/ResetPassword";
import TermsConditions from "./Pages/TermsConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import CareersPage from "./Pages/CareersPage";
import Tutorial from "./Pages/Tutorial";
import AboutUs from "./Pages/AboutUs";
import Login from './Pages/Login';
import Pages from './Layout/Pages';
import OuterPages from "./Layout/OuterPages";
import NotFound from './NotFound';
function App() {

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route
            exact
            path={[
              '/search/:title',
              '/:language/search/:title',
              '/:language/:media/search/:title']}>
            <Pages Components={Search} footer={true} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/book/:book_id/:item_id/:title',
              '/:language/book/:book_id/:item_id/:title',
              '/:language/:media/book/:book_id/:item_id/:title'
            ]}>
            <Pages Components={Book} footer={true} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/tutorial/:book_id/:item_id/:title',
              '/:language/tutorial/:book_id/:item_id/:title',
              '/:language/:media/tutorial/:book_id/:item_id/:title'
            ]}>
            <Pages Components={Tutorial} footer={true} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/summary/:book_id/:item_id/:title',
              '/:language/summary/:book_id/:item_id/:title',
              '/:language/:media/summary/:book_id/:item_id/:title'
            ]}>
            <Pages Components={Summary} header={true} summaryHeader={true} />
          </Route>

          <Route
            exact
            path={[
              '/membership',
              '/:language/membership',
              '/:language/:media/membership'
            ]}>
            <Pages Components={Pricing} footer={true} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/purchase',
              '/:language/purchase',
              '/:language/:media/purchase'
            ]}>
            <Pages Components={Purchase} header={true} redirectTo={true} />
          </Route>

          <Route
            exact
            path={[
              '/purchase/:success',
              '/:language/purchase/:success',
              '/:language/:media/purchase/:success'
            ]}>
            <Pages Components={PurchaseSuccess} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/library',
              '/:language/library',
              '/:language/:media/library'
            ]}>
            <Pages Components={Library} footer={true} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/reset-password/:token',
              '/:language/reset-password/:token',
              '/:language/:media/reset-password/:token'
            ]}>
            <Pages Components={ResetPassword} header={true} footer={true} />
          </Route>

          <Route
            exact
            path={[
              '/account/settings',
              '/:language/:media/account/settings'
            ]}>
            <Pages Components={Settings} account={true} footer={true} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/account/profile',
              '/:language/:media/account/profile'
            ]}>
            <Pages Components={Profile} account={true} footer={true} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/account/subscription',
              '/:language/:media/account/subscription'
            ]}>
            <Pages Components={Subscription} account={true} footer={true} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/account/membership',
              '/:language/:media/account/membership'
            ]}>
            <Pages Components={Membership} account={true} footer={true} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/account/payment-methods',
              '/:language/:media/account/payment-methods'
            ]}>
            <Pages Components={PaymentMethod} account={true} footer={true} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/account/support',
              '/:language/:media/account/support'
            ]}>
            <Pages Components={Support} account={true} footer={true} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/terms-and-conditions',
              '/:language/:media/terms-and-conditions'
            ]}>
            <OuterPages Components={TermsConditions} footer={true} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/privacy-policy',
              '/:language/:media/privacy-policy'
            ]}>
            <OuterPages Components={PrivacyPolicy} footer={true} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/careers',
              '/:language/:media/careers'
            ]}>
            <Pages Components={CareersPage} footer={true} header={true} />
          </Route>

          <Route
            exact
            path={[
              '/about-us',
              '/:language/:media/about-us'
            ]}>
            <Pages Components={AboutUs} footer={true} header={true} />
          </Route>

          <Route
            exact
            path="/login">
            <Pages Components={Login} />
          </Route>

          <Route
            exact
            path={[
              "/",
              '/:language',
              '/:language/:media'
            ]}>
            <Pages Components={Home} footer={true} header={true} />
          </Route>

          <Route>
            <Pages Components={NotFound} footer={true} header={true} />
          </Route>

        </Switch>

      </Router>


    </div>
  );
}

export default App;
