import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./Checkout";
import { useSelector, useDispatch } from "react-redux";
import { useGetUserDataQuery } from '../../services/login';
import LoginForm from '../../Layout/Pages/LoginForm';
import { useSigninModal } from '../../Components/useModal';
import CheckIcon from '@mui/icons-material/Check';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);


function Purchase() {
  const { userId, userToken, userEmail, userName, isMember, languageCode, countryCode, subscription, mediaLang } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch();

  const { isSigninModal, signinModal } = useSigninModal();
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken }, { count: 1 }, { refetchOnMountOrArgChange: true })

  function checkTrial() {
    if (subscription?.trial) {
      if (userData?.user?.trial === 1) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  function isTrial() {
    if (userData?.user?.trial === 1) {
      return true
    } else {
      if (subscription?.trial) {
        return true
      } else {
        return false
      }
    }
  }

  useEffect(() => {
    if (userData?.user?.membership) {
      if (userData?.user?.membership?.plan === subscription?.code) {
        history.push(`/${languageCode}/${mediaLang}/account/profile`)
      }
    }
    if (paymentCompleted) {
      history.push(`/${languageCode}/${mediaLang}/account/subscription`)
    }

    if (!subscription?.code) {
      history.push(`/${languageCode}/${mediaLang}/membership`)
    }

  }, [userData, subscription, paymentCompleted])

  function getPriceCalculated(getPrice) {
    if (subscription?.currency === 'BHD') {
      return getPrice / 1000
    } else if (subscription?.currency === 'JOD') {
      return getPrice / 1000
    } else if (subscription?.currency === 'KWD') {
      return getPrice / 1000
    } else if (subscription?.currency === 'OMR') {
      return getPrice / 1000
    } else if (subscription?.currency === 'TND') {
      return getPrice / 1000
    } else {
      return getPrice / 100
    }
  }

  return (
    <div className="Purchase-page">
      {/* <br /><br /> */}
      <div className="container purchase-container" style={{ padding: 0 }}>
        <div className="row purchase-section">
          <>
            {/* <div className="col-md-5">
              <div className="purchase-info-card bb">
                <div className="purchase-info-sub-card">
                  <div>
                    <div><h4>{t('subcription_plan')}</h4></div>
                    <div className="purchase-infodiv">
                      <h4><strong>&nbsp;</strong></h4>
                      <div style={{ textAlign: 'right' }}>
                        <h5 style={{ margin: 0, fontFamily: 'sans-serif' }}><small> {subscription?.currency} </small>
                          <span style={{ fontSize: '22px' }}><strong>
                            {checkTrial() ?
                              <>{'00'}</>
                              :
                              <>{subscription?.offer ? getPriceCalculated(subscription?.offer) : getPriceCalculated(subscription?.price)}</>
                            }
                          </strong></span>
                        </h5>
                        <div style={{ textAlign: 'right' }}><small>{t('month_full_access_1')}</small></div>
                      </div>
                    </div>
                    <div className="pricing-trial-eligible" style={{ textAlign: 'start', paddingBottom: '10px' }}>
                      <h5><strong>{t('membership_benefits_heading')}</strong></h5>
                    </div>
                    <div className="pricing-trial-eligible" style={{ textAlign: languageCode === 'ar' ? 'right' : 'left' }}>
                      <div className="purchase-card-list"><CheckIcon style={{ color: '#717171' }} />
                        <span style={{ fontSize: '12px' }}>{t('membership_benefits.a')}</span>
                      </div>
                      <div className="purchase-card-list"><CheckIcon style={{ color: '#717171' }} />
                        <span style={{ fontSize: '12px' }}>{t('membership_benefits.b')}</span>
                      </div>
                      <div className="purchase-card-list"><CheckIcon style={{ color: '#717171' }} />
                        <span style={{ fontSize: '12px' }}>{t('membership_benefits.c')}</span>
                      </div>
                    </div>
                    <br />
                    <div className="purchase-infodiv" style={{ borderBottom: '1px solid #eeee', marginBottom: '20px' }}>
                      <h4><strong>{t('total')}:</strong></h4>
                      <h5 style={{ fontFamily: 'sans-serif' }}><small> {subscription?.currency} </small>
                        <span style={{ fontSize: '22px' }}><strong>
                          {checkTrial() ?
                            <>{'00'}</> :
                            <>{subscription?.offer ? getPriceCalculated(subscription?.offer) : getPriceCalculated(subscription?.price)}</>
                          }
                        </strong></span>
                      </h5>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div> */}

            <div className="col-md-12 p0">
              <div className="purchase-form-card">
                {!userEmail && !userId ?
                  <LoginForm signinModal={signinModal} />
                  :
                  <>
                    <>
                      <h4 className="padding-card-form" style={{ textAlign: 'center' }}><strong>{t('payment_details')}</strong></h4>
                      <React.Fragment>
                        <div className="col-md-12 order-md-1">
                          <Elements stripe={stripePromise}>
                            <CheckoutForm
                              customerNumber={userData?.user?.customer}
                              setPaymentCompleted={setPaymentCompleted}
                              subscription_code={subscription?.code}
                              subscription_price={subscription?.offer ? subscription?.offer : subscription?.price}
                              currency={subscription?.currency}
                              subscription_trial={checkTrial()}
                              language={languageCode}
                              mediaLang={mediaLang}
                              userData={userData}
                              isTrial={isTrial()}
                            />
                          </Elements>
                        </div>
                      </React.Fragment>
                    </>
                  </>
                }
              </div>
            </div>
          </>
        </div>
      </div>
      <br /><br />
    </div>
  );
}

export default Purchase;
