import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import './index.scss';
import { useGetBookByIdQuery } from '../../services/book';
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import { Dropdown, Menu } from 'antd';
import Skeleton from '@mui/material/Skeleton';
import LanguageIcon from '@mui/icons-material/Language';
import { Authentications } from '../../Helpers/Authentications';
import AudioPlyr from './AudioPlyr';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import { Helmet } from "react-helmet";
import isMediaLanguageCode from '../../Components/isMediaLanguageCode';

const drawerWidth = 240;
const _ = require('lodash');

function Summary(props) {
  const { isUser, isMember } = Authentications();
  const { userId, userToken, userEmail, userName, languageCode, mediaLang } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'ud') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  const { book_id, item_id, title } = useParams();
  const bookInfo = useGetBookByIdQuery({
    id: book_id,
    languageCode: isMediaLanguageCode(mediaLang),
    userId: userId,
    userToken: userToken
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const book = bookInfo?.data?.book;

  const bookItems = book?.items?.filter(items => items.language === isMediaLanguageCode(mediaLang));
  const summariesss = bookItems?.[0]?.summaries;
  const summaries = _.orderBy(summariesss, ["id"], ["asc"]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    if (!bookInfo?.isLoading) {
      if (bookItems?.[0]?.category !== 'books') {
        history.push('/')
      }
    }
  }, [bookInfo])

  const [trackIndex, setTrackIndex] = useState(0);
  const handleClickPrevious = () => {
    setTrackIndex((currentTrack) =>
      currentTrack === 0 ? summaries?.length - 1 : currentTrack - 1
    );
  };

  const handleClickNext = () => {
    setTrackIndex((currentTrack) =>
      currentTrack < summaries?.length - 1 ? currentTrack + 1 : 0
    );
  };

  if (!bookInfo?.isLoading) {
    if (!isMember) {
      history.push('/')
    }
  }

  if ((!userEmail && !userId)) {
    history.push('/')
  }

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const drawer = (
    <div>
      <List>
        {bookInfo?.isLoading ?
          <div style={{ padding: '20px' }}>
            <Skeleton style={{ width: '100%', height: '15px' }} />
            <Skeleton style={{ width: '100%', height: '15px' }} />
            <Skeleton style={{ width: '100%', height: '15px' }} />
            <Skeleton style={{ width: '100%', height: '15px' }} />
          </div>
          :
          <>
            {summaries?.map((items, i) =>
              <Button
                className='summary-lit-list-card'
                disabled={trackIndex === i ? true : items.audio ? false : true}
                style={fontFamily}
                onClick={(() => setTrackIndex(i))}>
                <img
                  style={{ width: '45px' }}
                  src={`https://dj70nht8amdum.cloudfront.net/${bookItems?.[0]?.book_id}/${isMediaLanguageCode(mediaLang)}/${bookItems?.[0]?.id}/${bookItems?.[0]?.image}`}
                />
                <div className='summary-lit-list-title' style={{ color: trackIndex === i ? '#ababab' : '#363535' }}>{items.title}</div>
              </Button>
            )}
          </>
        }
      </List>
    </div>
  );


  let fontClass;
  if (languageCode === 'ar') {
    fontClass = 'font-class-ar'
  } else if (languageCode === 'urdu') {
    fontClass = 'font-class-urdu'
  } else {
    fontClass = 'font-class-default'
  }


  return (
    <div className="Summary-page">

      <Helmet>
        <title>{summaries?.[trackIndex]?.title || ''} | LiT: Big Ideas in Little Time</title>
      </Helmet>

      <div className="container">
        <div className="Book-summary-details-section">

          <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-md-10">
              <div className="row Book-summary-details-card" style={{ justifyContent: 'center' }}>
                <div className="col-md-8 summary-section-card" style={{ position: "relative" }}>
                  <div className="summary-text-style">
                    <div className="summary-page-subHeader" style={{ direction: 'ltr' }}>
                      <Button
                        style={{ position: 'absolute', zIndex: 1, left: 0, display: 'flex', justifyContent: 'left', minWidth: '40px', maxWidth: '40px', height: '100%' }}
                        className='custom-button-arrows'
                        onClick={handleClickPrevious}
                      >
                        <ArrowBackIosIcon style={{ width: '1.5rem', height: '1.5rem', color: '#12121247' }} />
                      </Button>
                      <Button
                        style={{ position: 'absolute', zIndex: 1, right: 0, display: 'flex', justifyContent: 'center', minWidth: '40px', maxWidth: '40px', height: '100%' }}
                        className='custom-button-arrows'
                        onClick={handleClickNext}
                      >
                        <ArrowForwardIosIcon style={{ width: '1.5rem', height: '1.5rem', color: '#12121247' }} />
                      </Button>
                      <div className="summary-page-key">
                        <Button
                          style={{ marginRight: '10px' }}
                          id="composition-button"
                          aria-haspopup="true"
                          onClick={() => history.goBack()}
                        >
                          {/* <img src='https://cdn.ebtida.com/1690477357294-lit-logo-black.png' style={{ width: '60px' }} /> */}
                          Back
                        </Button>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span className='mobile-hide-summary-page'>{t('key_idea')}</span>
                          <div className="margin">{trackIndex + 1}</div>
                          <div className="margin">of</div>
                          <div className="margin">{summaries?.length}</div>
                        </div>
                      </div>
                      <div className="summary-page-language-selector" style={{ display: 'flex', alignItems: 'center' }}>

                        <Toolbar className='summary-page-mobile-menu-icon'>
                          <IconButton
                            style={{ display: 'flex', alignItems: 'center', margin: 0 }}
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                          >
                            <QueueMusicIcon />
                          </IconButton>
                        </Toolbar>

                      </div>
                    </div>

                    {bookInfo?.isLoading ?
                      <div style={{ padding: '20px' }}>
                        <Skeleton style={{ width: '100%', height: '15px' }} />
                        <Skeleton style={{ width: '100%', height: '15px' }} />
                        <Skeleton style={{ width: '100%', height: '15px' }} />
                        <Skeleton style={{ width: '100%', height: '15px' }} />
                      </div>
                      :
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <>
                          <img
                            style={{ width: '110px', height: '110px', objectFit: 'contain' }}
                            src={`https://dj70nht8amdum.cloudfront.net/${bookItems?.[0]?.book_id}/${isMediaLanguageCode(mediaLang)}/${bookItems?.[0]?.id}/${bookItems?.[0]?.image}`}
                          />
                          <h1 className="book-title" style={{ fontWeight: 400, fontSize: '1.1rem' }}>
                            {summaries?.[trackIndex]?.title}
                          </h1>
                        </>
                      </div>
                    }
                    <div className={`HomePage_static_description ${languageCode === 'ar' || languageCode === 'ud' ? 'ProductDescrition_rtl' : 'ProductDescrition_ltr'} ${fontClass}`}
                      dangerouslySetInnerHTML={{
                        __html: summaries?.[trackIndex]?.summary?.replace('<span', '<div').replace('<h1', '<div').replace('<p', '<div').replace('<em', '<div').replace('&nbsp;', '').replace(/&nbsp;/g, '')
                      }}
                    >
                    </div>
                    <br />

                    <AudioPlyr
                      isLoading={bookInfo?.isLoading}
                      isFetching={bookInfo?.isFetching}
                      handleClickPrevious={handleClickPrevious}
                      book_id={book_id}
                      language={summaries?.[trackIndex]?.language}
                      book_item_id={summaries?.[trackIndex]?.book_item_id}
                      id={summaries?.[trackIndex]?.id}
                      media={summaries?.[trackIndex]?.audio}
                      handleClickNext={handleClickNext}
                    />


                  </div>
                </div>
                {/* <div className="col-md-4" style={{ position: "relative" }}>
                  <div className="summary-lit-page">
                  </div>
                </div> */}

                <Box
                  className='summary-side-menu'
                  component="nav"
                  sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                  aria-label="mailbox folders"
                >
                  {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                  <Drawer
                    className='summary-lit-list-m'
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                      display: { xs: 'block', sm: 'none' },
                      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                  >
                    {drawer}
                  </Drawer>
                  {/* <Drawer
                        variant="permanent"
                        sx={{
                          display: { xs: 'none', sm: 'block' },
                          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                      >
                        {drawer}
                      </Drawer> */}
                </Box>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
