import React, { useState } from 'react';
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import { addToLanguage } from "../../Reducers/authReducer";
import { useGetSubscriptionsQuery } from '../../services/subscriptions';
import { useGetCategoriesQuery } from '../../services/categories';
import isMediaLanguageCode from '../isMediaLanguageCode';

function Footer() {
  const { userId, userToken, userEmail, mediaLang, countryCode, languageCode } = useSelector((state) => state.auth);
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: infoData, error, isError, isFetching, isLoading, isSuccess } = useGetSubscriptionsQuery(
    { country_code: countryCode, language_code: isMediaLanguageCode(mediaLang) },
    { count: 1 },
    { refetchOnMountOrArgChange: true }
    
  )


  const categoriesInfo = useGetCategoriesQuery({
    languageCode: isMediaLanguageCode(mediaLang),
    userId: userId
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const categoryData = categoriesInfo?.data && categoriesInfo?.data?.categories;



  let subscriptionData;

  if (infoData?.defaultCountry) {
    subscriptionData = infoData?.defaultCountry
  } else if (infoData?.country) {
    subscriptionData = infoData?.country
  } else {
    subscriptionData = ''
  }

  function changeCountry() {
    dispatch(addToLanguage({ languageCode: '', countryCode: '' }));
    history.push('/login');
  }

  return (
    <div className="Footer-page" style={{ position: 'absolute', bottom: '0px', width: '100%', height: '0rem' }}>
      <div className="Footer-menu-card1">
        <div className="container">

          <div className="Footer-menu-card" style={{ paddingBottom: '40px' }}>
            <div className="Footer-menu-item">
              <h4>{t('footer_title_1')}</h4>
              <h5>{t('footer_title_2')}</h5>
              <br />
              <div className='footer-app-icons' style={{ display: 'flex' }}>
                <div className='footer-app-icons' style={{ display: 'flex', padding: '0px 0px 0px 0px', justifyContent: 'center' }}>
                  <div style={{ background: '#000', display: 'flex', padding: '6px', borderRadius: '4px' }}>
                    <a href="https://apps.apple.com/us/app/lit-lost-in-translation/id6451271720" target='_blank'>
                      <span>
                        <img src={`/assets/svgs/apple-icon.svg`} alt="Lit App" />
                      </span>
                    </a>
                  </div>
                  <div style={{ background: '#000', display: 'flex', padding: '6px', margin: '0 10px', borderRadius: '4px' }}>
                    <a href="https://play.google.com/store/apps/details?id=com.lit.ebtida" target='_blank'>
                      <span>
                        <img src={`/assets/svgs/android-icon.svg`} alt="Lit App" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="Footer-menu-item">
              <h5 style={{ padding: "5px" }}>{t('popular')}</h5>
              {categoryData?.slice(6, 11)?.map((item, i) =>
                <div key={i} className="Footer-menu-SubItem">
                  <Link to={`/${languageCode}/${mediaLang}/search/${item.title}`}>{item.title}</Link>
                </div>
              )}
            </div>
            <div className="Footer-menu-item">
              <h5 style={{ padding: "5px" }}>{t('footer_2.useful_links')}</h5>
              {categoryData?.slice(0, 5)?.map((item, i) =>
                <div key={i} className="Footer-menu-SubItem">
                  <Link to={`/${languageCode}/${mediaLang}/search/${item.title}`}>{item.title}</Link>
                </div>
              )}
              
            </div>
            <div className="Footer-menu-item">
              <h5 style={{ padding: "5px" }}>{t('company')}</h5>
              <div className="Footer-menu-SubItem"><Link to={`/${languageCode}/${mediaLang}/membership`}>{t('subscription')}</Link></div>
              <div className="Footer-menu-SubItem"><Link to="/about-us">{t('about')}</Link></div>
              <div className="Footer-menu-SubItem"><Link to="/careers">{t('careers')}</Link></div>

            </div>
          </div>
          <div className="Footer-menu-card" style={{ borderTop: '1px solid #eee' }}>
            <div className="Footer-menu-item Footer-menu-bottom" style={{ justifyContent: 'space-between' }}>
              <div className="Footer-menu-SubItem">
                © {new Date().getFullYear()} {t('Ebtida Marketing & Media')} | {t('all_right_reserved')}
              </div>

              <div className='Footer-menu-subcard'>
                <div className="Footer-menu-SubItem">
                  <div
                    onClick={() => changeCountry()}
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <img
                      style={{ margin: "0 4px", padding: '0px' }}
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    <div style={{ margin: '0 5px' }}>{subscriptionData?.currency}</div>
                  </div>
                </div>
                <div className="Footer-menu-SubItem"><Link to="/privacy-policy">{t('privacy_policy')}</Link></div>
                <div className="Footer-menu-SubItem"><Link to="/terms-and-conditions">{t('terms_and_conditions')}</Link></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
