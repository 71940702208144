import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import 'react-loading-skeleton/dist/skeleton.css'
import Pagination from "../Pagination";
import { useSelector } from "react-redux";
import { useResetPasswordMutation } from '../../services/login';
import Button from '@mui/material/Button';
const md5 = require('md5');

function ResetPassword() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const { token } = useParams();
  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [])


  const [resetPassword, resetPasswordRsult] = useResetPasswordMutation()

  const [getError, setError] = useState(false);
  const [getSuccess, setSuccess] = useState(false);

  useEffect(() => {
    try {
      if (resetPasswordRsult?.error) {
        setError(true)
      }
      if (resetPasswordRsult?.data) {
        setSuccess(true)
      }
    } catch (e) {

    }
  }, [resetPasswordRsult]);


  async function resetPasswordForm(data) {
    await resetPassword({
      email: data.email,
      password: md5(data.password),
      token: data.token,
    })
  }



  const successMessage = () => {
    return (
      <div className="success-msg">
        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
        </svg>
        <div className="title1">{resetPasswordRsult?.data?.success}</div>
      </div>
    )
  }

  return (
    <div className="Search-page" style={{ paddingTop: '4rem' }}>
      <br /><br /><br />
      <div className="container">
        <div className="row" style={{ justifyContent: 'center' }}>
          <div className="col-md-6 Banner-text">

            {getSuccess ?
              <div style={{ padding: '30px', background: '#fff' }}>
                {successMessage()}
              </div>
              :
              <form onSubmit={handleSubmit(resetPasswordForm)} style={{ padding: '30px', background: '#fff', borderRadius: '7px', border: '1px solid #eee' }}>

                {getError &&
                  <div style={{ textAlign: 'center', color: '#ea8087' }}>
                    {resetPasswordRsult?.error?.data?.error}
                  </div>
                }

                <div className="row">
                  <div className="col-md-12">
                    <div className="Header-singin-header">
                      <h5>{t('reset_password')} </h5>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>{t('email')} </label>

                      <input
                        type="email"
                        className="form-control dlrdflInput"
                        id="email"
                        aria-invalid={errors.email ? "true" : "false"}
                        {...register('email', { required: true })} />
                    </div>
                  </div>
                  <input
                    type="hidden"
                    className="form-control dlrdflInput"
                    defaultValue={token}
                    id="token"
                    aria-invalid={errors.token ? "true" : "false"}
                    {...register('token', { required: true })} />
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>{t('new_password')} </label>
                      <input
                        type="password"
                        className="form-control dlrdflInput"
                        id="password"
                        aria-invalid={errors.password ? "true" : "false"}
                        {...register('password', { required: true, minLength: 8, maxLength: 16 })} />
                      {errors.password && errors.password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
                      {errors.password && errors.password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be maximum 16 digits</span>}
                      {errors.password && errors.password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be minimum 8 digits</span>}
                    </div>
                  </div>
                  <div className="col-md-12 Header-signin-button">
                    <div className="form-group">

                      <Button
                        style={{ textTransform: 'capitalize', width: '100%', background: '#446275', fontWeight: 400 }}
                        disabled={resetPasswordRsult?.isLoading ? true : false}
                        color="info"
                        variant="contained"
                        size="medium"
                        type="submit"
                      >
                        {resetPasswordRsult?.isLoading ? t('loading') : t('submit')}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            }

          </div>
        </div>
      </div>

      <br /><br /><br />

    </div>
  );
}

export default ResetPassword;

