import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import SearchSkeleton from "../SearchSkeleton";
import { useTranslation } from 'react-i18next'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from "react-redux";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import 'react-lazy-load-image-component/src/effects/blur.css';
import isMediaLanguageCode from '../isMediaLanguageCode';
function SearchBooks({ data, length, loading, removeToLibrary, addToLibrary, rmToLibResult, addToLibResult, languageCode, mediaLang }) {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod } = useSelector((state) => state.auth);
  const { t } = useTranslation()

  function checkthiClick(media, bookId, itemId, title, category) {
    if (category === 'courses') {
      return `/${languageCode}/${mediaLang}/tutorial/${bookId}/${itemId}/${title?.replace(/\s+/g, '-').toLowerCase()}`
    } else if (category === 'documentary') {
      return `/${languageCode}/${mediaLang}/tutorial/${bookId}/${itemId}/${title?.replace(/\s+/g, '-').toLowerCase()}`
    } else {
      return `/${languageCode}/${mediaLang}/book/${bookId}/${itemId}/${title?.replace(/\s+/g, '-').toLowerCase()}`
    }
  }

  function checkRation(category) {
    if (category === 'courses') {
      return true
    } else if (category === 'documentary') {
      return true
    } else {
      return false
    }
  }

  function getCategoryIcon(item) {
    if (item === 'documentary') {
      return `/documentaryIcon.png`
    } else if (item === 'courses') {
      return `/TutorialsIcon.png`
    } else {
      return `/BooksIcon.png`
    }
  }

  function checkDirection() {
    if (mediaLang === 'arabic') {
      return true
    } else if (mediaLang === 'urdu') {
      return true
    } else {
      return false
    }
  }


  const fontFamily_Text = {
    textAlign: checkDirection() ? 'right' : 'left',
  }

  return (
    <>
      {loading ? <SearchSkeleton /> :
        <>
          <>
            {length === 0 &&
              <div className="category-view-more">
                <div className="Category-search-not-found">
                  <h5>{t('no_data_found')}</h5>
                </div>
              </div>
            }
          </>
          {data?.map((item, i) =>
            <div className="Category-item" key={i}>
              <Link
                className="list-card"
                disabled={!item?.book ? true : false}
                to={checkthiClick(item.media_type, item.book_id, item.id, item.title, item.category)}>
                <div className="img-list-card">
                  <img
                    className='img-list-img'
                    alt={'lit.ebtida.com'}
                    src={`https://dj70nht8amdum.cloudfront.net/${item.book_id}/${isMediaLanguageCode(mediaLang)}/${item.id}/${item.image}`}
                    style={{ transition: "all 0.5s", width: '100%' }}
                  />
                </div>
                <div className="Category-text" style={fontFamily_Text}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h4>{item.title}</h4>
                    {item.lib?.book_item_id === item.id &&
                      <div className="book-library-icon">
                        <BookmarkOutlinedIcon />
                      </div>
                    }
                  </div>
                  <div className="subtitle">{item.subtitle}</div>
                  {item.author && <div className='authors'>{item.author}</div>}
                </div>
              </Link>
            </div>
          )}
        </>
      }
    </>
  );
}

export default SearchBooks;

