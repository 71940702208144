import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from "react-player/lazy";
import IconButton from '@mui/material/IconButton';
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import screenfull from "screenfull";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMute from "@material-ui/icons/VolumeOff";
import { VideoSeekSlider } from "react-video-seek-slider";
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { useParallax } from "react-scroll-parallax";
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    "&::-webkit-scrollbar": {
      display: "none"
    }, /* Chrome */
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));


function Banner({ data, loading, length, languageCode, checkDirection }) {
  const { t } = useTranslation()
  const [maxTimeaa, setMaxTimeaa] = useState(0);
  const [getOnReady, setOnReady] = useState(false);
  const [currentTimeaa, setCurrentTimeaa] = useState(0);
  const [getLoading, setLoading] = useState(true);
  const [buffering, setBuffering] = useState(false);
  const [IsShown, setIsShown] = useState(false);

  const [state, setState] = useState({ pip: false, playing: false, controls: false, light: true, muted: false, played: 0, loaded: 0, duration: 0, playbackRate: 1.0, volume: 1, loop: false, seeking: false, playedSeconds: 0 });

  const interval = useRef(null);
  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const controlsRef = useRef(null);
  const canvasRef = useRef(null);
  const { playing, controls, light, muted, loop, playbackRate, pip, played, loaded, seeking, volume, playedSeconds } = state;

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
    clearInterval(interval.current);
  };

  const onClickPreview = () => {
    setState({ ...state, playing: !state.playing });
    clearInterval(interval.current);
  }

  const handleDoubleClick = (data) => {
    if (data.event.detail === 2) {
      if (screenfull.isEnabled) {
        screenfull.toggle(playerContainerRef.current);
      }
    }
  };

  const hanldeMute = () => {
    setState({ ...state, muted: !state.muted });
  };

  const handleSeekChangenew = (time, newValue) => {
    playerRef.current.currentTime = newValue / 1000;
    setCurrentTimeaa(newValue);
    setState({ ...state, seeking: false });
    playerRef.current?.seekTo(newValue / 1000);
  };

  useEffect(() => {
    if (getOnReady) {
      setMaxTimeaa((playerRef.current?.getDuration() || 0) * 1000);
    }
  }, [getOnReady, currentTimeaa])

  const setOnPlay = () => {
    interval.current = setInterval(() => {
      setCurrentTimeaa((playerRef.current?.getCurrentTime() || 0) * 1000);
    }, 100);
  };

  const endBufferingFunction = (data) => {
    if (data.type === "playing") {
      setLoading(false);
      setBuffering(false);
    }
  };

  const onBuffering = () => {
    setBuffering(true);
  };

  function checkDirectionlng() {
    if (languageCode === 'ar') {
      return true
    } else if (languageCode === 'urdu') {
      return true
    } else {
      return false
    }
  }

  const classes = useStyles();

  function overlayText() {
    return (
      <div
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        className="banner-content-overv"
        style={{ padding: '20px 0px 20px 0px', opacity: IsShown ? 1 : 1 }}>
        <div className='container'>
          <div className="row" style={{ justifyContent: 'start' }}>
            <div className={`col-md-6`}>
              <div style={{ textAlign: 'start', whiteSpace: 'normal' }}>
                <h3 className='banner-text-heading' style={{ color: '#ddd', fontSize: '2.5rem' }}>
                  {t('homepage_banner.title')}
                </h3>
                <p className='banner-text' style={{ color: '#ddd', fontSize: '1.2rem' }}>{t('homepage_banner.text')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


  const parallax = useParallax({
    // rotate: [0, 0],
    // translateY: ['-500px', '500px'],
    translateY: [0, 50],
    opacity: [1, 0],
    scale: [1.0, 1, 'easeOutCubic'],
    shouldAlwaysCompleteAnimation: true,
  });


  return (
    <div className={`col-md-12 hp-lvideo`} style={{ padding: 0, position: 'relative' }}>
      <div ref={parallax.ref}>
        <div
          ref={playerContainerRef}
          style={{ position: 'relative' }}>
          {data?.summaries[0]?.audio &&
            <ReactPlayer
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
              previewTabIndex={1}
              onClick={((event) => handleDoubleClick({ event: event }))}
              className='react-player'
              scrollableAncestor='window'
              playsinline={true}
              style={{ display: 'flex', width: '100%', height: 'auto', backgroundColor: '#000', borderTopRightRadius: '10px', borderTopLeftRadius: '10px', }}
              ref={playerRef}
              width="100%"
              height="100%"
              url={`https://m.ebtida.com/v/${data?.book_id}/${data?.language}/${data?.summaries[0]?.book_item_id}/${data?.summaries[0]?.id}/${data?.summaries[0]?.audio}/video_${data?.summaries[0]?.id}_master.m3u8`}
              playing={playing}
              controls={false}
              light={<img src={data?.summaries[0]?.image} style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '10px' }} />}
              loop={true}
              volume={volume}
              muted={muted}
              onPlay={setOnPlay}
              onClickPreview={handlePlayPause}
              playIcon={false}
              onBuffer={onBuffering}
              onBufferEnd={endBufferingFunction}
              onReady={(() => setOnReady(true))}
              config={{
                file: {
                  forceVideo: true,
                  hlsOptions: {
                    autoStartLoad: true,
                    startLevel: 2,
                    forceHLS: true,
                  }
                },
              }}
            />
          }
          {!playing &&
            <div style={{ position: 'absolute', top: '0px', right: '0px', left: 0, display: 'flex', justifyContent: 'start', alignItems: 'center', height: '100%', borderTopRightRadius: '10px', borderTopLeftRadius: '10px', background: '#00000029' }}>
              {overlayText()}
            </div>
          }

          {data?.summaries[0]?.audio &&
            <div className='banner-pro-slider' style={{ position: 'absolute', right: 0, left: 0, bottom: 0, height: '11px' }}>
              <VideoSeekSlider
                className="banner-pro-slider"
                max={maxTimeaa}
                currentTime={currentTimeaa}
                bufferTime={playerRef.current?.getSecondsLoaded() * 1000}
                limitTimeTooltipBySides={true}
                hideThumbTooltip={false}
                onChange={handleSeekChangenew}
                secondsPrefix="00:"
                minutesPrefix="0:"
              />
            </div>
          }

          {data?.summaries[0]?.audio &&
            <>
              {/* {!getOnReady &&
                <div style={{ position: 'absolute', top: 0, right: 0, left: 0, display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center', zIndex: 11 }}>
                  <CircularProgress style={{ color: '#ddd', width: '35px', height: '35px' }} />
                </div>
              } */}
              {getOnReady &&
                <>
                  <IconButton
                    style={{ background: '#fff', position: 'absolute', top: '15px', margin: '0 10px', left: 0, zIndex: 1 }}
                    onClick={handlePlayPause}
                    className={`${`controller-bottom-icons-center`} ${classes.controlIcons}`}
                    aria-label="play"
                  >
                    {playing ? (
                      <PauseIcon style={{ width: '1.5rem', height: '1.5rem', color: '#000' }} />
                    ) : (
                      <PlayArrowIcon style={{ width: '1.5rem', height: '1.5rem', color: '#000' }} />
                    )}
                  </IconButton>
                  <IconButton
                    style={{ background: '#eeeeee1c', position: 'absolute', top: '15px', margin: '0 10px', right: 0 }}
                    onClick={hanldeMute}
                  >
                    {muted ?
                      <VolumeMute style={{ width: '1.5rem', height: '1.5rem', color: '#fff' }} />
                      : volume > 0.5 ?
                        <VolumeUp style={{ width: '1.5rem', height: '1.5rem', color: '#fff' }} />
                        :
                        <VolumeDown style={{ width: '1.5rem', height: '1.5rem', color: '#fff' }} />
                    }
                  </IconButton>
                </>
              }
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default Banner;
