import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
function AboutUs() {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="statisc-page privacy-page">
      <br /> <br />
      <div className="container static-container">
        <div className="row static-row" style={{ justifyContent: 'center', width: '100%' }}>
          <div className="col-md-10">

            <div className="static-container-card" style={{ textAlign: 'left', background: '#fcfcfc', padding: '20px', border: '1px solid #eee', borderRadius: '7px' }}>
              <h5 style={{ padding: '20px 0px 20px 0px', fontSize: '1.4rem', fontWeight: 300 }}>
                {t('about')}
              </h5>
              <p className="terms-text">Welcome to LiT, your ultimate destination for audiobook summaries, documentaries, and educational tutorials in multiple languages. At LiT, we believe that knowledge should be accessible to everyone, regardless of their background or location. That's why we have curated a vast library of high-quality content, designed to enrich your mind and broaden your horizons.</p>
              <p className="terms-text">With our monthly affordable membership, you will have unlimited access to an extensive collection of audiobook summaries that condense the key insights of popular books into concise and engaging narratives. Whether you're a busy professional looking to make the most of your limited reading time or a lifelong learner seeking new ideas and perspectives, our summaries will provide you with the essence of each book, empowering you to stay informed and inspired. Additionally, our platform offers a diverse range of documentaries and educational tutorials, covering various subjects and disciplines. Join LiT today and embark on an enlightening journey of knowledge and personal growth.</p>
              <p className="terms-text">Get the app on the Apple Store and Google Play.</p>

            </div>

          </div>
        </div>
      </div>
      <br /> <br />
    </div>
  );
}

export default AboutUs;

