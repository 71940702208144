import React, { useState, useEffect, Fragment } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import En from './En';
import Ar from './Ar';

function Privacy() {
  const { t } = useTranslation()
  const [getLanguage, setLanguage] = React.useState('');

  function getData() {
    if (getLanguage === 'en') {
      return 'en'
    } else if (getLanguage === 'ar') {
      return 'ar'
    } else {
      return 'en'
    }
  }

  let fontFamilyForAll
  if (getData()  === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (getData()  === 'ud') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  return (
    <div style={{padding: '20px', background: '#fcfcfc', border: '1px solid #eee', borderRadius: '7px'}}>
      <div>
        <Button
          color="warning"
          variant="outlined"
          size="medium"
          onClick={(() => setLanguage(getData() === 'en' ? 'ar' : 'en'))}
        >
          {getData() === 'en' ? 'Arabic' : 'English'}
        </Button>
      </div>
      <div className="static-container-card" style={{ textAlign: getData() === 'en' ? 'left' : 'right', direction: getData() === 'en' ? 'ltr' : 'rtl' }}>
        <h1 style={{ fontFamily: fontFamilyForAll, padding: '20px 0px 20px 0px', fontSize: '1.4rem', fontWeight: 300 }}>
          {getData() === 'en' ? 'Privacy Policy' : 'سياسة الخصوصية'}
          <br />
        </h1>
        {getData() === 'en' && <En language={getData()} />}
        {getData() === 'ar' && <Ar language={getData()} />}
      </div>
    </div>
  );
}

export default Privacy;

