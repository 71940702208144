import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, useLocation, Link, useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddressForm from './AddressForm';
import { useSelector, useDispatch } from "react-redux";
import { addToLanguage } from "../../Reducers/authReducer";
import './index.scss';
const { countries, zones } = require("moment-timezone/data/meta/latest.json");
import i18next from 'i18next'
import LinearProgress from '@mui/material/LinearProgress';
import { useGetSubscriptionsQuery } from '../../services/subscriptions';
import { useGetHomepageAdsQuery } from '../../services/homepage';
import isMediaLanguageCode from '../../Components/isMediaLanguageCode';

const moment = require('moment-timezone');
const steps = ['', '', ''];
const defaultTheme = createTheme();

export default function Login() {
  const location = useLocation();
  const history = useHistory();
  const { userId, userToken, userEmail, userName, languageCode, countryCode, mediaLang } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [getLanguage, setLanguage] = React.useState('');
  const [getLanguageName, setLanguageName] = React.useState('');
  const [getCountry, setCountry] = React.useState([]);


  const { isLoading: subsLoading } = useGetSubscriptionsQuery({ country_code: countryCode, language_code: isMediaLanguageCode(mediaLang) }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const { isLoading: adsLoading } = useGetHomepageAdsQuery({ languageCode: isMediaLanguageCode(mediaLang) }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const timeZoneToCountry = {};
  Object.keys(zones).forEach(z => {
    const cityArr = z.split("/");
    const city = cityArr[cityArr.length - 1];
    timeZoneToCountry[city] = countries[zones[z].countries[0]].abbr;
  });
  let userCountryCode;
  if (Intl) {
    // const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userTimeZone = moment.tz.guess() ? moment.tz.guess() : 'US'
    const tzArr = userTimeZone?.split("/");
    const userCity = tzArr[tzArr.length - 1];
    userCountryCode = timeZoneToCountry[userCity];
  }

  const [activeStep, setActiveStep] = React.useState(0);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const fromApp = new URLSearchParams(location.search).get('fromApp') || false
  const tcFromApp = new URLSearchParams(location.search).get('tcFromApp') || false
  const ppFromApp = new URLSearchParams(location.search).get('ppFromApp') || false

  function addCountryCode(data) {
    setCountry(data)
    dispatch(addToLanguage({ countryCode: data?.code ? data?.code : '', languageCode: languageCode, mediaLang: mediaLang }));
  }

  async function addLanguageCode(data) {

    function thsiCode(thisLang) {
      if (thisLang === 'urdu') {
        return 'ud'
      } else if (thisLang === 'desi') {
        return 'ds'
      } if (thisLang === 'arabic') {
        return 'ar'
      } else {
        return thisLang
      }
    }


    dispatch(addToLanguage({ mediaLang: data, countryCode: countryCode, languageCode: thsiCode(data)  }));
    await i18next.changeLanguage(data)
    setLanguageName(data)
    setActiveStep(1);

  }

  function addUILang(data) {
    dispatch(addToLanguage({ languageCode: data, countryCode: countryCode, mediaLang: mediaLang }));
    function redirectFromApp() {
      if (fromApp) {
        return `/${data}/${mediaLang}/membership`
      } else if (tcFromApp) {
        return `/${data}/${mediaLang}/terms-and-conditions`
      } else if (ppFromApp) {
        return `/${data}/${mediaLang}/privacy-policy`
      } else {
        return `/${data}/${mediaLang}`
      }
    }
    window.open(redirectFromApp(), '_self')
  }

  function redirectFromAppbb() {
    if (fromApp) {
      return `/${languageCode}/${mediaLang}/membership`
    } else if (tcFromApp) {
      return `/${languageCode}/${mediaLang}/terms-and-conditions`
    } else if (ppFromApp) {
      return `/${languageCode}/${mediaLang}/privacy-policy`
    } else {
      return `/${languageCode}/${mediaLang}`
    }
  }

  useEffect(() => {
    dispatch(addToLanguage({ countryCode: countryCode ? countryCode : userCountryCode, languageCode: languageCode ? languageCode : i18next.language, mediaLang: mediaLang }));
  }, [languageCode])

  function languageFunc() {
    if (getLanguageName === 'desi') {
      return (<>{'Desi / '} <small style={{ fontFamily: 'GessTwoMedium, sans-serif' }}> (Romanised Urdu/Hindi)</small></>)
    } else if (getLanguageName === 'arabic') {
      return (<>{'Arabic / '} <small style={{ fontFamily: 'GessTwoMedium, sans-serif' }}> {'العربية'}</small></>)
    } else if (getLanguageName === 'urdu') {
      return (<>{'Urdu / '} <small style={{ fontFamily: 'GessTwoMedium, sans-serif' }}> {'اردو'}</small></>)
    } else {
      return ``
    }
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* <img src="https://cdn.ebtida.com/1688243590371-ebtida-lit-start.png" style={{ width: '100%', height: '100vh', objectFit: 'cover', position: 'fixed', display: 'block' }} /> */}
      <div style={{ position: 'fixed', background: '#f7f7f7', top: 0, height: '100%', right: 0, left: 0, }}></div>
      <Container style={{
        position: 'absolute',
        top: 0,
        height: '100%',
        right: 0,
        left: 0,
        width: '100%',
        justifyContent: 'center',
        zIndex: 999,
        direction: 'ltr'
      }} component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper style={{ borderRadius: 10, position: 'relative', borderTopRightRadius: 0, borderTopLeftRadius: 0 }} variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>

          {/* <LinearProgress style={{ position: 'absolute', top: 0, right: 0, left: 0, borderRadius: 0 }} /> */}

          <div style={{ letterSpacing: '2px', marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
            <img src='https://cdn.ebtida.com/1690477357294-lit-logo-black.png' style={{ width: '85px' }} />
          </div>
          <Box sx={{ width: '100%' }}>
            <br />
            <div>

              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                  <AddressForm
                    userCountryCode={userCountryCode}
                    countryCode={countryCode}
                    addCountryCode={addCountryCode}
                    getCountry={getCountry}
                  />
                  <>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>

                      <h5 style={{ textAlign: 'left', fontSize: '1rem', margin: 0, fontWeight: 500, marginBottom: 0, paddingLeft: 8 }}>Select <strong>Media</strong> Language</h5>
                    </div>
                    <div>
                      <div>
                        <Button
                          className='lng-list-login'
                          onClick={() => addLanguageCode('desi')}
                          style={{
                            color: getLanguageName === 'desi' ? '#000000c9' : '#000000c9'
                          }}
                        >
                          <input
                            type="radio"
                            checked={getLanguageName === 'desi'}
                            style={{ cursor: 'pointer', width: '18px', height: '18px', lineHeight: '18px' }} />
                          <div style={{ margin: '0 10px', fontSize: '1rem' }}>
                            {'Desi / '} <small style={{ fontFamily: 'GessTwoMedium, sans-serif' }}> (Romanised Urdu/Hindi)</small>
                          </div>
                        </Button>
                      </div>
                      <div>
                        <Button
                          className='lng-list-login'
                          onClick={() => addLanguageCode('urdu')}
                          style={{
                            color: getLanguageName === 'urdu' ? '#000000c9' : '#000000c9'
                          }}
                        >
                          <input
                            type="radio"
                            checked={getLanguageName === 'urdu'}
                            style={{ cursor: 'pointer', width: '18px', height: '18px', lineHeight: '18px' }} />
                          <div style={{ margin: '0 10px', fontSize: '1rem' }}>
                            {'Urdu / '} <small style={{ fontFamily: 'GessTwoMedium, sans-serif' }}> {'اردو'}</small>
                          </div>
                        </Button>
                      </div>
                      <div>
                        <Button
                          className='lng-list-login'
                          onClick={() => addLanguageCode('arabic')}
                          style={{
                            color: getLanguageName === 'arabic' ? '#000000c9' : '#000000c9'
                          }}
                        >
                          <input
                            type="radio"
                            checked={getLanguageName === 'arabic'}
                            style={{ cursor: 'pointer', width: '18px', height: '18px', lineHeight: '18px' }} />
                          <div style={{ margin: '0 10px', fontSize: '1rem' }}>
                            {'Arabic / '} <small style={{ fontFamily: 'GessTwoMedium, sans-serif' }}> {'العربية'}</small>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </>

                  <>
                    <div style={{ marginTop: '20px', opacity: activeStep === 0 ? 0.3 : 1 }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <h5 style={{ textAlign: 'left', fontSize: '1rem', margin: 0, fontWeight: 500, marginBottom: 0, paddingLeft: 8 }}>Select <strong>Website UI</strong> Language</h5>
                      </div>
                      {getLanguageName &&
                        <Button
                          disabled={activeStep === 0}
                          onClick={() => addUILang('en')}
                          className='lng-list-login'
                          style={{ color: '#000000c9' }}
                        >
                          <input
                            type="radio"
                            style={{ cursor: 'pointer', width: '18px', height: '18px', lineHeight: '18px' }}
                          />
                          <div style={{ margin: '0 10px', fontSize: '1rem' }}>
                            {'English'}
                          </div>
                        </Button>
                      }
                      {getLanguageName &&
                        <Button
                          disabled={activeStep === 0}
                          onClick={() => addUILang(getLanguageName === 'urdu' ? 'ud' : getLanguageName === 'desi' ? 'ds' : getLanguageName === 'arabic' ? 'ar' : getLanguageName)}
                          className='lng-list-login'
                          style={{ color: '#000000c9' }}
                        >
                          <input
                            type="radio"
                            checked={getLanguageName}
                            style={{ cursor: 'pointer', width: '18px', height: '18px', lineHeight: '18px' }} />
                          <div style={{ margin: '0 10px', fontSize: '1rem' }}>
                            {languageFunc()}
                          </div>
                        </Button>
                      }
                    </div>
                  </>
                </Typography>

                <div style={{ justifyContent: 'center', display: 'flex', padding: '10px 0px 10px 0px' }}>
                  <Button
                    size='medium'
                    style={{ background: '#446275', fontWeight: 400, color: '#f7f7f7', opacity: activeStep === 0 ? 0.5 : 1 }}
                    disabled={activeStep === 0}
                    onClick={(() => window.open(redirectFromAppbb(), '_self'))} sx={{ mr: 1 }} >
                    Start
                  </Button>
                </div>

              </React.Fragment>
            </div>
          </Box>

          <br /><br />
          <div className='footer-app-icons' style={{ display: 'flex', padding: '20px 0px 10px 0px', justifyContent: 'center' }}>
            <div style={{ background: '#000', display: 'flex', padding: '6px', borderRadius: '4px' }}>
              <a href="https://apps.apple.com/us/app/lit-lost-in-translation/id6451271720" target='_blank'>
                <span>
                  <img src={`assets/svgs/apple-icon.svg`} alt="" />
                </span>
              </a>
            </div>
            <div style={{ background: '#000', display: 'flex', padding: '6px', margin: '0 10px', borderRadius: '4px' }}>
              <a href="https://play.google.com/store/apps/details?id=com.lit.ebtida" target='_blank'>
                <span>
                  <img src={`assets/svgs/android-icon.svg`} alt="" />
                </span>
              </a>
            </div>
          </div>

        </Paper>
        <div className='footer-app-icons' style={{ display: 'flex', padding: '10px 0px 0px 0px', justifyContent: 'center', flexWrap: 'wrap' }}>
          <div className="Footer-menu-SubItem" style={{ fontSize: '12px', fontFamily: 'Poppins, sans-serif', textAlign: 'center' }}>© {new Date().getFullYear()} {'Ebtida Marketing & Media'} | {'all right reserved'}</div>
        </div>
        <div className='footer-app-icons' style={{ display: 'flex', padding: '0px 0px 0px 0px', justifyContent: 'center', flexWrap: 'wrap' }}>
          <Link style={{ fontSize: '12px', fontFamily: 'Poppins, sans-serif', color: '#363535' }} to="/privacy-policy">Privacy policy</Link>
          <div style={{ fontFamily: 'Poppins, sans-serif' }}>&nbsp;&nbsp;&nbsp;</div>
          <Link style={{ fontSize: '12px', fontFamily: 'Poppins, sans-serif', color: '#363535' }} to="/terms-and-conditions">Terms and Conditions</Link>
        </div>
      </Container>
    </ThemeProvider >
  );
}