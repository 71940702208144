import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';

import { booksApi } from '../services/books';
import { bookApi } from '../services/book';
import { categoriesApi } from '../services/categories';
import { libraryApi } from '../services/library';
import { searchApi } from '../services/search';
import { roundBooksApi } from '../services/homepage';
import { loginApi } from '../services/login';
import { paymentsApi } from '../services/payments';
import { membershipApi } from '../services/membership';
import { subscriptionApi } from '../services/subscriptions';
import authReducer from "../Reducers/authReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [booksApi.reducerPath]: booksApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [libraryApi.reducerPath]: libraryApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [roundBooksApi.reducerPath]: roundBooksApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [membershipApi.reducerPath]: membershipApi.reducer,
    [bookApi.reducerPath]: bookApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(booksApi.middleware).concat(categoriesApi.middleware).concat(libraryApi.middleware).concat(loginApi.middleware).concat(roundBooksApi.middleware).concat(searchApi.middleware).concat(paymentsApi.middleware).concat(membershipApi.middleware).concat(bookApi.middleware).concat(subscriptionApi.middleware)
})

setupListeners(store.dispatch)
