import React, { useState, useEffect, useRef, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import 'react-loading-skeleton/dist/skeleton.css'
import { useGetCategoriesQuery } from '../../services/categories';
import { useDispatch, useSelector } from "react-redux";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { removeToCart, removeUserToState, addSelectedSevenDayaPass, addToLanguage } from "../../Reducers/authReducer";
import Languages from '../../Layout/Pages/Languages';
import Button from '@mui/material/Button';
import LoginModal from '../../Layout/Pages/LoginModal';
import { useSigninModal } from '../useModal';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Authentications } from '../../Helpers/Authentications';
import LanguageIcon from '@mui/icons-material/Language';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import Categories from './Categories';
import SearchForm from './SearchForm';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import isMediaLanguageCode from '../isMediaLanguageCode';
import { useMediaQuery } from 'react-responsive'

function Header(props) {
  const { isUser, isMember, isDataLoading, isDataFetching } = Authentications();
  const history = useHistory();
  const { t } = useTranslation()
  const { userId, userToken, userEmail, userName, languageCode, countryCode, mediaLang } = useSelector((state) => state.auth);
  const isLangaugeCode = languageCode ? languageCode : 'ds'
  const dispatch = useDispatch();
  const { isSigninModal, signinModal } = useSigninModal();
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })




  const categoriesInfo = useGetCategoriesQuery({
    languageCode: isMediaLanguageCode(mediaLang),
    userId: userId
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const categoryData = categoriesInfo?.data && categoriesInfo?.data;

  function userLogout() {
    dispatch(removeToCart());
    dispatch(removeUserToState());
    dispatch(addSelectedSevenDayaPass({ selectedPass: false }));
    history.push("/");
  }

  const getLanguage = i18next?.language ? i18next?.language : isLangaugeCode
  const currentLanguage = Languages?.find((l) => l.code === i18next?.language)

  function thisMediaFromDefault(thisLangDeafult) {
    if (thisLangDeafult === 'en') {
      return 'desi'
    } else {
      return thisLangDeafult
    }
  }


  function onClickLang(data) {
    const findLanguage = Languages?.find((l) => l.code === data)
    document.body.dir = findLanguage?.dir || 'ltr'
    // document.documentElement.lang = findLanguage?.lang || languageCode
    i18next.changeLanguage(data)
    let mediaLanguage;
    let uiLanguage

    if (data === 'en') {
      mediaLanguage = 'desi';
      uiLanguage = 'en'
    } else {
      mediaLanguage = data === 'ds' ? 'desi' : data === 'ud' ? 'urdu' : data === 'ar' ? 'arabic' : data;
      uiLanguage = data
    }

    dispatch(addToLanguage({ languageCode: uiLanguage, countryCode: countryCode, mediaLang: mediaLanguage }));
    window.open(`/${uiLanguage}/${mediaLanguage}`, '_self');
  }

  const currentLanguageName = Languages?.find((l) => l.code === isLangaugeCode)

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [openL, setOpenL] = React.useState(false);
  const anchorRefL = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleToggleL = () => {
    setOpenL((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCloseL = (event) => {
    if (anchorRefL.current && anchorRefL.current?.contains(event.target)) {
      return;
    }

    setOpenL(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  function handleListKeyDownL(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenL(false);
    } else if (event.key === 'Escape') {
      setOpenL(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  const prevOpenL = React.useRef(openL);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    if (prevOpenL.current === true && openL === false) {
      anchorRefL.current?.focus();
    }

    prevOpen.current = open;
    prevOpenL.current = openL;
  }, [open, openL]);


  let fontFamilyForAll

  if (isLangaugeCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (isLangaugeCode === 'ud') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }


  function checkHeaderPath() {
    if (window.location.pathname === '/') {
      return true
    } else if (window.location.pathname === `/${isLangaugeCode}`) {
      return true
    } else {
      return false
    }
  }


  const [getScrolling, setScrolling] = useState(true);
  const listenScrollEvent = () => {
    window.scrollY > 100 ? setScrolling(true) : setScrolling(true);
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);



  function changeCountry() {
    dispatch(addToLanguage({ languageCode: isLangaugeCode, countryCode: '' }));
    history.push('/login');
  }


  function fontFamilyFunc(code) {
    let fonts
    if (code === 'ar') {
      fonts = 'GessTwoMedium, sans-serif'
    } else if (code === 'ud') {
      fonts = 'AlQalam Shakir'
    } else {
      fonts = 'Poppins, sans-serif'
    }
    return {
      fontFamily: fonts
    }
  }

  return (
    <>
      <div className="Header-page">
        <div className="Header-menu">
          <div className="container">
            <div className={`Header-menu-list2 Header-menu-list`}>
              <div className='header-md'>
                <Link className={`Header-menu-logoName ${(isLangaugeCode === 'ar') || (isLangaugeCode === 'ud') ? 'header-logo-ar' : 'header-logo-en'}`} to={`/${isLangaugeCode}/${mediaLang}`}>
                  <img src='https://cdn.ebtida.com/1690477357294-lit-logo-black.png' />
                </Link>
                <div style={{ width: '100%' }} className={`Header-sub-nav-list ${'nav-list-h'}`}>
                  <Categories categories={categoryData?.categories} />
                </div>
              </div>

              <div className="Header-waller-connect1 mobile-hide" style={{ width: '100%', padding: '0px 10px 0px 10px' }}>
                {checkHeaderPath() ? getScrolling &&
                  <>
                    {!isMobcreen &&
                      <SearchForm />
                    }
                  </> :
                  <>
                    {!isMobcreen &&
                      <SearchForm />
                    }
                  </>
                }
              </div>

              <div className="Header-menu-account">
                <div className="Header-waller-connect1">
                  <Button
                    style={{ borderRadius: '7px', fontSize: '1rem', textTransform: 'capitalize', fontWeight: 400 }}
                    size='small'
                    ref={anchorRefL}
                    id="composition-button"
                    aria-controls={openL ? 'composition-menu' : undefined}
                    aria-expanded={openL ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggleL}
                    className="Header-button-connect"
                  >
                    <LanguageIcon style={{ margin: "0 3px", fontSize: '20px', color: '#2d2d2d' }} />
                    <div className='mobile-hide' style={fontFamily}>{currentLanguageName?.menu_name}</div>
                  </Button>
                  <Popper
                    open={openL}
                    anchorEl={anchorRefL.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleCloseL}>
                            <MenuList
                              autoFocusItem={openL}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDownL}
                            >
                              {Languages?.map((item, i) =>
                                <MenuItem
                                  onClick={() => onClickLang(item.code)}
                                  disabled={isLangaugeCode === item.code ? true : false}
                                  style={fontFamily}
                                >
                                  <div style={fontFamilyFunc(item.code)}>{item.name}</div>
                                </MenuItem>
                              )}

                              <MenuItem onClick={() => changeCountry()} style={fontFamily}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <img
                                    style={{ margin: "0 4px" }}
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                  <div style={{ margin: '0 5px' }}>Change Country</div>
                                </div>

                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>



                <Button
                  style={{ borderRadius: '7px', fontSize: '1rem', textTransform: 'capitalize', fontWeight: 400 }}
                  size='small'
                  className="Header-button-connect"
                  component={Link}
                  to={`/${isLangaugeCode}/${mediaLang}/library`}
                >
                  <LibraryBooksIcon style={{ margin: "0 3px", fontSize: '20px', color: '#484949bf' }} />
                  <div style={fontFamily} className="mobile-hide">
                    {t('my_list')}
                  </div>
                </Button>
                {(isDataFetching) && (isDataLoading) ? '' :
                  <>
                    {!isMember &&
                      <Button
                        className="Header-button-connect button-member"
                        size="small"
                        style={{ borderRadius: '7px', fontSize: '1rem', textTransform: 'capitalize', fontWeight: 400 }}
                        component={Link}
                        to={`/${isLangaugeCode}/${mediaLang}/membership`}
                      >
                        <LoyaltyIcon style={{ margin: "0 3px", fontSize: '20px', color: '#484949bf' }} />
                        <div style={fontFamily} className="mobile-hide">
                          {t('start_membership')}
                        </div>
                      </Button>
                    }
                  </>
                }

                {userEmail && userToken ?
                  <div className="Header-waller-connect1">
                    <Button
                      style={{ borderRadius: '7px', fontSize: '1rem', textTransform: 'capitalize', fontWeight: 400 }}
                      size='small'
                      ref={anchorRef}
                      id="composition-button"
                      aria-controls={open ? 'composition-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                      className="Header-button-connect"
                    >
                      <PersonOutlineOutlinedIcon style={{ margin: "0 3px", fontSize: '20px', color: '#2d2d2d' }} />
                      <div className='mobile-hide' style={fontFamily}>{t('account')}</div>
                      <ExpandMoreIcon className="expand-style" style={{ margin: 0 }} />
                    </Button>
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom-start' ? 'left top' : 'left bottom',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                              >
                                <MenuItem
                                  style={fontFamily}
                                  onClick={handleClose}
                                  component={Link}
                                  to={`/${isLangaugeCode}/${mediaLang}/account/profile`}
                                >
                                  {t('profile')}
                                </MenuItem>
                                <MenuItem
                                  style={fontFamily}
                                  onClick={handleClose}
                                  component={Link}
                                  to={`/${isLangaugeCode}/${mediaLang}/account/subscription`}
                                >
                                  {t('subscription')}
                                </MenuItem>
                                <MenuItem
                                  style={fontFamily}
                                  onClick={handleClose}
                                  component={Link}
                                  to={`/${isLangaugeCode}/${mediaLang}/account/membership`}
                                >
                                  {t('membership')}
                                </MenuItem>
                                <MenuItem
                                  style={fontFamily}
                                  onClick={handleClose}
                                  component={Link}
                                  to={`/${isLangaugeCode}/${mediaLang}/account/payment-methods`}
                                >
                                  {t('payment_methods')}
                                </MenuItem>
                                <MenuItem
                                  style={fontFamily}
                                  onClick={handleClose}
                                  component={Link}
                                  to={`/${isLangaugeCode}/${mediaLang}/account/settings`}
                                >
                                  {t('settings')}
                                </MenuItem>
                                <MenuItem
                                  style={fontFamily}
                                  onClick={handleClose}
                                  component={Link}
                                  to={`/${isLangaugeCode}/${mediaLang}/account/support`}
                                >
                                  {t('support')}
                                </MenuItem>
                                <MenuItem
                                  style={fontFamily}
                                  onClick={userLogout}
                                > {t('signout')}
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                  :
                  <div className="Header-waller-connect1">
                    <Button
                      style={{ borderRadius: '7px', fontSize: '1rem', textTransform: 'capitalize', fontWeight: 400 }}
                      size='small'
                      aria-haspopup="true"
                      onClick={(() => signinModal(true))}
                      className="Header-button-connect"
                    >
                      <PersonOutlineOutlinedIcon style={{ margin: "0 3px", fontSize: '20px', color: '#2d2d2d' }} />
                      <div className="mobile-hide" style={fontFamily}>{t('login')}</div>
                    </Button>
                  </div>
                }
                <LoginModal isSigninModal={isSigninModal} signinModal={signinModal} />
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="header-search-media">
        <div className="desktop-hide">
          {checkHeaderPath() ? getScrolling &&
            <>
              {isMobcreen &&
                <SearchForm />
              }
            </> :
            <>
              {isMobcreen &&
                <SearchForm />
              }
            </>
          }
        </div>
      </div>

    </>
  );
}

export default Header;
