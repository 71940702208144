import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import './index.scss';
import Hls from "hls.js";
import plyr from "plyr";
import "plyr/dist/plyr.css";
import IconButton from '@mui/material/IconButton';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Forward10Icon from '@mui/icons-material/Forward10';
import Replay10Icon from '@mui/icons-material/Replay10';

const controls = `
<div class="plyr__controls voice-controls summaries-lit-player" style="display: block;">
  <div>
    <div class="plyr__progress">
      <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
        <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
        <span role="tooltip" class="plyr__tooltip">00:00</span>
    </div>

    <div style="display: flex; align-items: center; color: #000; justify-content: space-between; padding: 3px 10px 0px 10px;">
    <div class="plyr__time plyr__time_voice plyr__time--current" aria-label="Current time">00:00</div>
    <div class="plyr__time plyr__time_voice plyr__time--duration" aria-label="Duration">00:00</div>
  </div>

  </div>
  <div style="display: flex; align-items: center; justify-content: flex-end; padding: 0px 10px 10px 10px;     position: absolute;
  right: 0;
  bottom: 0;
  z-index: 9999;">
    
    <div className="custom-volume-section" style="display: flex; align-items: center;">
    <button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
        <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-muted"></use></svg>
        <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-volume"></use></svg>
        <span class="label--pressed plyr__tooltip" role="tooltip">Unmute</span>
        <span class="label--not-pressed plyr__tooltip" role="tooltip">Mute</span>
      </button>
      <div class="plyr__volume">
        <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
      </div>
    </div>
  </div>
</div>
`;


function AudioPlyr({ book_id, language, book_item_id, id, media, handleClickNext, isLoading, handleClickPrevious }) {
  // const src = `https://m.ebtida.com/a/${book_id}/${language}/${book_item_id}/${id}/${media}/index.m3u8`;
  const src = `https://d1ozu9ddqfby9k.cloudfront.net/a/${book_id}/${language}/${book_item_id}/${id}/${media}/audio_${id}_master.m3u8`
  const video = document.getElementById("player");

  const [getOnReady, setOnReady] = useState(false);
  video?.addEventListener('ready', (event) => {
    setOnReady(event.detail?.plyr?.isAudio)
  });

  video?.addEventListener('ended', (handleClickNext));

  React.useMemo(() => {
    const defaultOptions = {
      controls: controls,
      seekTime: 10
    };
    const hls = new Hls();
    hls.loadSource(src);
    hls.attachMedia(video);

    hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
      const availableQualities = hls.levels.map((l) => l.height);
      window.hls.currentLevel = -1
      availableQualities.unshift(0);
      defaultOptions.quality = {
        default: 0, //Default - AUTO
        options: availableQualities,
        forced: true,
        onChange: (e) => updateQuality(e)
      };
      hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
        var span = document.querySelector(
          ".plyr__menu__container [data-plyr='quality'][value='0'] span"
        );
        if (hls.autoLevelEnabled) {
          span.innerHTML = `Auto (${hls.levels[data.level].height}p)`;
        } else {
          span.innerHTML = `Auto`;
        }
      });
      new plyr(video, defaultOptions);
    });
    hls.attachMedia(video);
    window.hls = hls;

  }, [src, video]);
  function updateQuality(newQuality) {
    if (newQuality === 0) {
      window.hls.currentLevel = -1; //Enable AUTO quality if option.value = 0
    } else {
      window.hls.levels.forEach((level, levelIndex) => {
        if (level.height === newQuality) {
          window.hls.currentLevel = levelIndex;
        }
      });
    }
  }
  const playerRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(true);
  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  useEffect(() => {
   if (getOnReady) {
      if (isPlaying) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    }
  }, [isPlaying, playerRef, getOnReady]);


  const seekToTenSec = () => {
    playerRef.current.currentTime = playerRef.current.currentTime + 10
  };

  const seekToTenSecMinus = () => {
    playerRef.current.currentTime = playerRef.current.currentTime - 10
  };

  return (
    <>
      <div className='summary-lit-player-sticky'>
        {!isLoading &&
          <>
            <div className="rhap_footer-bottom" style={{ direction: 'ltr' }}>
              {getOnReady &&
                <IconButton
                  className='custom-button-arrows'
                  id="composition-button"
                  aria-haspopup="true"
                  onClick={handleClickPrevious}
                >
                  <SkipPreviousIcon />
                </IconButton>
              }
              <IconButton
                className='seek-i'
                id="composition-button"
                aria-haspopup="true"
                onClick={seekToTenSecMinus}
              >
                <Replay10Icon />
              </IconButton>

              <IconButton
                className='custom-button-arrows center-i'
                id="composition-button"
                aria-haspopup="true"
                onClick={togglePlayPause}
              >
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>
              <IconButton
                className='seek-i'
                id="composition-button"
                aria-haspopup="true"
                onClick={seekToTenSec}
              >
                <Forward10Icon />
              </IconButton>

              {getOnReady &&
                <IconButton
                  className='custom-button-arrows'
                  id="composition-button"
                  aria-haspopup="true"
                  onClick={handleClickNext}
                >
                  <SkipNextIcon />
                </IconButton>
              }
            </div>
          </>
        }
        <audio className="audio-plyr" ref={playerRef} id="player" autoPlay={true}>
        </audio>
      </div>
    </>
  );
}

export default AudioPlyr;
