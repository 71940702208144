import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function SearchSkeleton() {
  return (
    <>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
      <div className="col-md-3 Category-item sfsd">
        <Skeleton variant="rect" height={200} />
        <Skeleton variant="rect" height={15} />
        <Skeleton variant="rect" height={15} />
      </div>
    </>
  )
}

export default SearchSkeleton;