import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button';
import isMediaLanguageCode from '../../isMediaLanguageCode';
function Categories({ data, title, subtitle, languageCode, loginForm, getLanguage, mediaLang }) {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, isCustomer } = useSelector((state) => state.auth);
  const { t } = useTranslation()

  const [getCatLength, setCatLength] = React.useState(7);

  function catList(item, i) {
    return (
      <div className="Dash_categories_card" key={i}>
        <img src={`https://dj70nht8amdum.cloudfront.net/media/${isMediaLanguageCode(mediaLang)}/${item.id}`} style={{ height: 0, display: "none" }} />
        <Link to={`/${getLanguage}/search/${item.title}`}>
          <div className="dashboard_categories" style={{display: 'flex', alignItems: 'center'}}>
            <img src={item.icon_url} style={{ width: '20px' }} />
            <span style={{margin: '0 7px'}}>{item.title && item.title}</span>
          </div>
        </Link>
      </div>
    )
  }

  return (
    <div className="dashboard-category-section">
      <div className="container">
        <div className="dashboard-category-content">
          <h2 className='list-title'>{title}</h2>
          <div>{subtitle}</div>
        </div>
        <div className="Dashboard-view-more">
          {window.innerWidth > 992 &&
            <>
              {data?.map((item, i) =>
                catList(item, i)
              )}
            </>
          }
          {window.innerWidth < 992 &&
            <>
              {data?.slice(0, getCatLength)?.map((item, i) =>
                catList(item, i)
              )}
            </>
          }
        </div>
        {window.innerWidth < 992 &&
          <div style={{ textAlign: 'center' }}>
            <Button
              className="category-show-more"
              color="info"
              variant="contained"
              size="small"
              onClick={(() => setCatLength(getCatLength === 7 ? 50 : 7))}
            >
              {getCatLength === 7 ? t('show_more') : t('show_less')}
            </Button>
          </div>
        }
      </div>
      {/* <div className="dashboard-start-trial-card">
        {!isMember &&
          <Link to={`/${getLanguage}/membership`}>
            <Button
              className="Header-waller-connect1"
              color="info"
              variant="contained"
              size="medium"
            >
              {t('start_free_trial')}
            </Button>
          </Link>
        }
      </div> */}
    </div >
  );
}

export default Categories;
