import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import SearchSkeleton from "../SearchSkeleton";
import { useTranslation } from 'react-i18next'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from "react-redux";
import { Authentications } from '../../Helpers/Authentications';
import IconButton from '@mui/material/IconButton';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import isMediaLanguageCode from '../isMediaLanguageCode';
function LibraryBooks({ data, length, loading, rmToLibResult, removeToLibrary, getLanguage }) {
  const { isUser, isMember } = Authentications();
  const { userId, userToken, userEmail, userName, languageCode, mediaLang } = useSelector((state) => state.auth);
  const { t } = useTranslation()

  function checkthiClick(media, bookId, itemId, title, category) {
    if (category === 'courses') {
      return `/${languageCode}/${mediaLang}/tutorial/${bookId}/${itemId}/${title?.replace(/\s+/g, '-').toLowerCase()}`
    } else if (category === 'documentary') {
      return `/${languageCode}/${mediaLang}/tutorial/${bookId}/${itemId}/${title?.replace(/\s+/g, '-').toLowerCase()}`
    } else {
      return `/${languageCode}/${mediaLang}/book/${bookId}/${itemId}/${title?.replace(/\s+/g, '-').toLowerCase()}`
    }
  }

  return (
    <>
      {loading ? <SearchSkeleton /> :
        <>
          {data?.map((item, i) =>
            <div className="col-md-3" style={{padding: '10px'}} key={i}>
              <div className="Category-item list-card" style={{ position: 'relative' }}>
                <Link to={checkthiClick(item.book_item?.media_type, item.book_item?.book_id, item.book_item?.id, item.book_item?.title, item.book_item?.category)}>
                  <div className="img-list-card">
                    <img
                    className='img-list-img'
                    alt={'lit.ebtida.com'}
                    src={`https://dj70nht8amdum.cloudfront.net/${item.book_item?.book_id}/${item.book_item?.language}/${item.book_item?.id}/${item.book_item?.image}`}
                    style={{ transition: "all 0.5s", width: '100%' }}
                  />
                  </div>
                  <div className="Category-text">
                    <h4>{item.book_item?.title}</h4>
                    {item.book_item?.author &&
                      <div className='authors'>{item.book_item?.author}</div>
                    }
                    <div className="subtitle">{item.book_item?.subtitle}</div>
                  </div>

                </Link>
                <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 111 }}>
                  {
                    rmToLibResult.isLoading && rmToLibResult?.originalArgs?.book_id === item.book_item?.id ?
                      <>
                        <div className="bouncing-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </>
                      :
                      <IconButton
                        title={t('remove_from_list')}
                        aria-haspopup="true"
                        onClick={() => removeToLibrary({
                          languageCode: isMediaLanguageCode(mediaLang),
                          userId: userId,
                          userToken: userToken,
                          book_id: item.book_item?.id
                        })}
                      >
                        <PlaylistRemoveIcon style={{ color: '#212945' }} />
                      </IconButton>
                  }
                </div>
              </div>

            </div>
          )}
        </>
      }
    </>
  );
}

export default LibraryBooks;

