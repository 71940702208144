import React, { useState, useEffect, Fragment } from 'react';

function Ar({language}) {

  let fontFamilyForAll
  if (language === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (language === 'ud') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }


  return (
    <>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>آخر تحديث: 16 أكتوبر 2023 </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>تصف سياسة الخصوصية هذه سياساتنا وإجراءاتنا المتعلقة بجمع معلوماتك واستخدامها والإفصاح عنها عند استخدامك للخدمة وتخبرك بحقوق خصوصيتك وكيف يحميك القانون. </p>

      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>نستخدم بياناتك الشخصية لتوفير الخدمة وتحسينها.  باستخدام الخدمة، فإنك توافق على جمع واستخدام المعلومات وفقا لسياسة الخصوصية هذه. </p>

      <h4 style={{ fontFamily: fontFamilyForAll }}>التفسير والتعاريف </h4>
      <h5 style={{ fontFamily: fontFamilyForAll }}>التعريف</h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>المصطلحات الاتية لها معاني محددة في ظل الشروط التالية.  يجب أن يكون للتعاريف التالية نفس المعنى بغض النظر عما إذا كانت تظهر بصيغة المفرد أو بصيغة الجمع. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>حساب تم إنشاؤه لك للوصول إلى خدماتنا أو أجزاء من خدماتنا. </p>


      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>Affiliate الكيانات التي تسيطر أو تسيطر عليها أو تحت السيطرة المشتركة مع طرف، حيث "السيطرة" يعني ملكية 50٪ أو أكثر من الأسهم، أو حصة الأسهم أو الأوراق المالية الأخرى التي يحق لها التصويت لانتخاب أعضاء مجلس الإدارة أو السلطة الإدارية الأخرى. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>Application يشير إلى Lit - Lost in Translation، التطبيق البرمجي الذي نقدمه. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>الشركة  (المشار إليها إما باسم "الشركة" أو "نحن" أو "نحن" أو "لنا" في هذه الاتفاقية) تشير إلى شركة ابتدا للإعلام والتسويق، الإمارات العربية المتحدة. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>ملفات تعريف الارتباط هي ملفات صغيرة يتم وضعها على جهاز الكمبيوتر الخاص بك أو الجهاز المحمول أو أي جهاز آخر بواسطة موقع ويب، تحتوي على تفاصيل سجل التصفح الخاص بك على هذا الموقع وهي من بين استخداماته العديدة. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>البلد يشير إلى: الإمارات العربية المتحدة </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>جهاز هو أي جهاز يمكنه الوصول إلى الخدمة مثل جهاز كمبيوتر أو هاتف محمول أو جهاز لوحي رقمي. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>البيانات الشخصية هي أي معلومات تتعلق بفرد محدد الهوية. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>الخدمات تعني التطبيق أو الموقع أو كليهما. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>مزود الخدمة يعني أي شخص يقوم بمعالجة البيانات نيابة عن الشركة.  وهي تشير إلى شركات الطرف الثالث أو الأفراد الذين توظفهم الشركة لتسهيل الخدمة، أو لتقديم الخدمة نيابة عن الشركة، أو لأداء الخدمات المتعلقة بالخدمة أو لمساعدة الشركة في تحليل كيفية استخدام الخدمة. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>بيانات الاستخدام  تشير الى البيانات التي يتم جمعها تلقائيا، إما الناتجة عن استخدام الخدمة أو من البنية التحتية للخدمة نفسها (على سبيل المثال، مدة زيارة الصفحة). </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>المواقع الالكتروني يشير إلى Lit - Lost in Translation، يمكن الوصول إليه عبر الرابط https://lit.ebtida.com/ </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>أنت تعني الفرد الذي يصل إلى الخدمة أو يستخدمها، أو الشركة، أو أي كيان قانوني آخر نيابة عنه يصل هذا الفرد إلى الخدمة أو يستخدمها، حسب الاقتضاء. </p>


      <h4 style={{ fontFamily: fontFamilyForAll }}>جمع واستخدام بياناتك الشخصية </h4>
      <h5 style={{ fontFamily: fontFamilyForAll }}>أنواع البيانات التي تم جمعها </h5>
      <h5 style={{ fontFamily: fontFamilyForAll }}>البيانات الشخصية </h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>أثناء استخدام خدماتنا، قد نطلب منك تزويدنا بمعلومات شخصية معينة يمكن استخدامها للاتصال بك أو التعرف عليك.  قد تتضمن معلومات التعريف الشخصية، على سبيل المثال لا الحصر: </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>عنوان البريد الإلكتروني </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>الاسم الأول والاسم الأخير </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>رقم الهاتف </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>بيانات الاستخدام</p>

      <h5 style={{ fontFamily: fontFamilyForAll }}>بيانات الاستخدام</h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>يتم جمع بيانات الاستخدام تلقائيا عند استخدام الخدمة. </p>

      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>قد تتضمن بيانات الاستخدام معلومات مثل عنوان بروتوكول الإنترنت لجهازك (مثل عنوان IP)، نوع المتصفح، إصدار المتصفح، صفحات خدمتنا التي تزورها، وقت وتاريخ زيارتك، الوقت الذي تقضيه في تلك الصفحات، معرفات الأجهزة الفريدة وغيرها من البيانات التشخيصية. </p>

      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>عند الوصول إلى الخدمة بواسطة أو من خلال جهاز محمول، قد نقوم بجمع معلومات معينة تلقائيا، بما في ذلك، ولكن ليس على سبيل الحصر، نوع الجهاز المحمول الذي تستخدمه، المعرف الفريد لجهازك المحمول، عنوان IP لجهازك المحمول، نظام تشغيل الهاتف المحمول، نوع متصفح الإنترنت المحمول الذي تستخدمه، معرفات الجهاز الفريدة وغيرها من البيانات التشخيصية. </p>

      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>قد نقوم أيضا بجمع المعلومات التي يرسلها متصفحك كلما قمت بزيارة خدماتنا أو عند الوصول إلى الخدمة بواسطة أو من خلال جهاز محمول. </p>

      <h5 style={{ fontFamily: fontFamilyForAll }}>تقنيات التتبع والكوكيز </h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>نحن نستخدم ملفات تعريف الارتباط وتقنيات التتبع المماثلة لتتبع النشاط على خدمتنا وتخزين معلومات معينة.  تقنيات التتبع المستخدمة هي إشارات وعلامات وبرامج نصية لجمع المعلومات وتتبعها وتحسين خدماتنا وتحليلها.  قد تشمل التقنيات التي نستخدمها: </p>

      <ul style={{ fontFamily: fontFamilyForAll }}>
        <li>كوكيز أو كوكيز المتصفح.  ملف تعريف الارتباط هو ملف صغير يوضع على جهازك.  يمكنك توجيه متصفحك لرفض جميع ملفات تعريف الارتباط أو الإشارة إلى وقت إرسال ملف تعريف الارتباط.  ومع ذلك، إذا كنت لا تقبل ملفات تعريف الارتباط، فقد لا تتمكن من استخدام بعض أجزاء خدمتنا.  ما لم تقم بتعديل إعداد المتصفح الخاص بك بحيث يرفض الكوكيز، قد تستخدم خدمتنا من الكوكيز. </li>
        <li>إشارات الويب (Web beacon).  قد تحتوي أقسام معينة من خدماتنا ورسائل البريد الإلكتروني الخاصة بنا على ملفات إلكترونية صغيرة تعرف باسم إشارات الويب (يشار إليها أيضا باسم gifs, pixel tags, and single-pixel gifs) التي تسمح للشركة، على سبيل المثال، بتعداد المستخدمين الذين زاروا تلك الصفحات أو فتحوا بريدا إلكترونيا وإحصائيات موقع الويب الأخرى ذات الصلة (على سبيل المثال، تسجيل شعبية قسم معين والتحقق من سلامة النظام والخادم). </li>
      </ul>

      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>الكوكيز يمكن أن تكون "دائمة" أو " مؤقتة" الكوكيز. تبقى ملفات تعريف الارتباط الدائمة على جهاز الكمبيوتر الشخصي أو الجهاز المحمول الخاص بك عند عدم الاتصال بالإنترنت، بينما يتم حذف ملفات تعريف الارتباط المؤقتة بمجرد إغلاق متصفح الانترنت. </p>

      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>نحن نستخدم ملفات تعريف الارتباط الدائمة والمؤقتة للأغراض التالية: </p>
      <h5 style={{ fontFamily: fontFamilyForAll }}>الكوكيز الأساسية/الضرورية </h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>النوع: كوكيز الجلسة (المؤقتة)</p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>تدار من قبل: نحن </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>الغرض: ملفات تعريف الارتباط هذه ضرورية لتزويدك بالخدمات المتاحة من خلال الموقع الإلكتروني ولتمكينك من استخدام بعض ميزاته.  فهي تساعد على مصادقة المستخدمين ومنع الاستخدام الاحتيالي لحسابات المستخدمين.  بدون ملفات تعريف الارتباط هذه، لا يمكن تقديم الخدمات التي طلبتها، ونحن نستخدم ملفات تعريف الارتباط هذه فقط لتزويدك بتلك الخدمات. </p>
      <h5>سياسة ملفات التعريف/إشعار قبول الكوكيز </h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>النوع: الكوكيز الدائمة </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>تدار من قبل: نحن </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>الغرض: تحدد ملفات تعريف الارتباط هذه ما إذا كان المستخدمون قد قبلوا استخدام ملفات تعريف الارتباط على الموقع. </p>
      <h5 style={{ fontFamily: fontFamilyForAll }}>ملفات التعريف الوظيفية </h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>النوع: الكوكيز الدائمة </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>تدار من قبل: نحن </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>الغرض: تسمح لنا ملفات تعريف الارتباط هذه بتذكر الخيارات التي تقوم بها عند استخدامك للموقع، مثل تذكر تفاصيل تسجيل الدخول أو اللغة المختارة.  الغرض من ملفات تعريف الارتباط هذه هو تزويدك بتجربة شخصية أكثر وتجنب الاضطرار إلى إعادة إدخال تفضيلاتك في كل مرة تستخدم فيها الموقع. </p>

      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>لمزيد من المعلومات حول ملفات تعريف الارتباط التي نستخدمها والخيارات الخاصة بك فيما يتعلق بملفات تعريف الارتباط، يرجى زيارة قسم ملفات تعريف الارتباط في سياسة الخصوصية الخاصة بنا. </p>

      <h5 style={{ fontFamily: fontFamilyForAll }}>استخدام بياناتك الشخصية </h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>يجوز للشركة استخدام البيانات الشخصية للأغراض التالية: </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>لتوفير وصيانة خدماتنا، بما في ذلك مراقبة استخدام خدماتنا. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>لإدارة حسابك: لإدارة تسجيلك كمستخدم للخدمة.  يمكن أن تمنحك البيانات الشخصية التي تقدمها إمكانية الوصول إلى الوظائف المختلفة للخدمة المتاحة لك كمستخدم مسجل. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>لتنفيذ العقد: تطوير والامتثال والتعهد بعقد الشراء للمنتجات أو العناصر أو الخدمات التي قمت بشرائها أو أي عقد آخر معنا من عبر الخدمة. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>للاتصال بك: للاتصال بك عن طريق البريد الإلكتروني، المكالمات الهاتفية، الرسائل القصيرة أو أشكال أخرى مماثلة من الاتصالات الإلكترونية، مثل الإخطارات دفع تطبيق الهاتف المحمول فيما يتعلق بالتحديثات أو الاتصالات الإعلامية المتعلقة بالوظائف أو المنتجات أو الخدمات المتعاقد عليها، بما في ذلك التحديثات الأمنية، عند الضرورة أو معقولة التنفيذ. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>لتزويدك بالأخبار والعروض الخاصة والمعلومات العامة حول السلع والخدمات والأحداث الأخرى التي نقدمها والتي تشبه تلك التي اشتريتها مسبقا أو استفسرت عنها ما لم تكن قد اخترت عدم تلقي هذه المعلومات. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>لإدارة طلباتك: لمتابعة وإدارة طلباتك معنا. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>لنقل الأعمال: يجوز لنا استخدام معلوماتك لتقييم أو إجراء عملية دمج أو تصفية أو إعادة هيكلة أو إعادة تنظيم أو حل أو عمليات بيع أو نقل أخرى لبعض أو كل أصولنا، سواء كانت منشأة مستمرة أو كجزء من الإفلاس أو التصفية أو إجراءات مماثلة، حيث تكون البيانات الشخصية التي نحتفظ بها عن مستخدمي خدماتنا من بين الأصول المنقولة. .</p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>لأغراض أخرى: قد نستخدم معلوماتك لأغراض أخرى، مثل تحليل البيانات، تحديد اتجاهات الاستخدام، تحديد فعالية حملاتنا الترويجية، تقييم وتحسين خدماتنا، منتجاتنا، تسويقنا وتجربتك. </p>

      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>قد نشارك معلوماتك الشخصية في الحالات التالية: </p>
      <ul style={{ fontFamily: fontFamilyForAll }}>
        <li>مع مقدمي الخدمات: قد نشارك معلوماتك الشخصية مع مزودي الخدمة لمراقبة وتحليل استخدام خدماتنا او للاتصال بك. </li>
        <li>لنقل الأعمال: يجوز لنا مشاركة أو نقل معلوماتك الشخصية فيما يتعلق أو أثناء التفاوض على أي دمج، بيع أصول الشركة، التمويل أو الاستحواذ على كل أو جزء من أعمالنا إلى شركة أخرى. </li>
        <li>مع الشركات التابعة: قد نشارك معلوماتك مع الشركات التابعة لنا، وفي هذه الحالة سنطلب من تلك الشركات التابعة احترام سياسة الخصوصية هذه. وتشمل الشركات التابعة لنا الشركة الأم وأي شركات تابعة أخرى، شركاء مشاريع مشتركة أو شركات أخرى نتحكم فيها أو تخضع لسيطرة مشتركة معنا. </li>
        <li>مع شركاء الأعمال: قد نشارك معلوماتك مع شركائنا التجاريين لنقدم لك بعض المنتجات أو الخدمات أو العروض الترويجية. </li>
        <li>مع مستخدمين آخرين: عندما تشارك معلومات شخصية أو تتفاعل في الصفحات العامة مع مستخدمين آخرين، قد يتم عرض هذه المعلومات من قبل جميع المستخدمين ويمكن توزيعها بشكل عام. </li>
        <li>بموافقتك: قد نكشف عن معلوماتك الشخصية لأي غرض آخر بموافقتك. </li>
      </ul>

      <h5 style={{ fontFamily: fontFamilyForAll }}>الاحتفاظ بمعلوماتك الشخصية </h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>ستحتفظ الشركة ببياناتك الشخصية فقط طالما كان ذلك ضروريا للأغراض المنصوص عليها في سياسة الخصوصية هذه.  سنحتفظ ببياناتك الشخصية ونستخدمها إلى الحد اللازم للامتثال لالتزاماتنا القانونية (على سبيل المثال، إذا طلب منا الاحتفاظ ببياناتك للامتثال للقوانين المعمول بها)، حل النزاعات، وتنفيذ اتفاقياتنا وسياساتنا القانونية. </p>

      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>كما ستحتفظ الشركة ببيانات الاستخدام لأغراض التحليل الداخلي.  يتم الاحتفاظ ببيانات الاستخدام بشكل عام لفترة زمنية أقصر، إلا عندما يتم استخدام هذه البيانات لتعزيز الأمان أو لتحسين وظائف خدماتنا، أو عندما نكون ملزمين قانونا بالاحتفاظ بهذه البيانات لفترات زمنية أطول. </p>

      <h5 style={{ fontFamily: fontFamilyForAll }}>نقل بياناتك الشخصية </h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>تتم معالجة معلوماتك، بما في ذلك البيانات الشخصية، في مكاتب تشغيل الشركة وفي أي أماكن أخرى توجد فيها الأطراف المشاركة في المعالجة.  وهذا يعني أنه قد يتم نقل والحفاظ على هذه المعلومات إلى أجهزة الكمبيوتر موجودة خارج ولايتك، مقاطعتك، بلدك أو أي ولاية قضائية حكومية أخرى حيث قد تختلف قوانين حماية البيانات عن تلك الموجودة في ولايتك القضائية. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>إن موافقتك على سياسة الخصوصية هذه متبوعة بتقديمك لهذه المعلومات تمثل موافقتك على هذا النقل. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>ستتخذ الشركة جميع الخطوات اللازمة بشكل معقول لضمان التعامل مع بياناتك بشكل آمن ووفقا لسياسة الخصوصية هذه ولن يتم نقل بياناتك الشخصية إلى منظمة أو بلد ما لم تكن هناك ضوابط كافية بما في ذلك أمان بياناتك ومعلوماتك الشخصية الأخرى. </p>

      <h5 style={{ fontFamily: fontFamilyForAll }}>حذف بياناتك الشخصية </h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>لديك الحق في حذف أو طلب المساعدة في حذف البيانات الشخصية التي جمعناها عنك. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>قد تمنحك خدمتنا القدرة على حذف بعض المعلومات عنك من داخل الخدمة. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>يمكنك تحديث معلوماتك أو تعديلها أو حذفها في أي وقت عن طريق تسجيل الدخول إلى حسابك، إذا كان لديك حساب، وزيارة قسم إعدادات الحساب الذي يسمح لك بإدارة معلوماتك الشخصية.  يمكنك أيضا الاتصال بنا لطلب الوصول إلى أي معلومات شخصية قدمتها لنا أو تصحيحها أو حذفها. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>ومع ذلك، يرجى ملاحظة أننا قد نحتاج إلى الاحتفاظ بمعلومات معينة عندما يكون لدينا التزام قانوني أو أساس قانوني للقيام بذلك. </p>

      <h4 style={{ fontFamily: fontFamilyForAll }}>الكشف عن بياناتك الشخصية </h4>
      <h5 style={{ fontFamily: fontFamilyForAll }}>المعاملات التجارية </h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>إذا كانت الشركة منخرطة في عملية دمج أو شراء أو بيع أصول، فقد يتم نقل بياناتك الشخصية.  سنقدم إشعارا قبل نقل بياناتك الشخصية وتصبح خاضعة لسياسة خصوصية مختلفة. </p>

      <h5 style={{ fontFamily: fontFamilyForAll }}>الجهات القانونية</h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>في ظل ظروف معينة، قد يطلب من الشركة الكشف عن بياناتك الشخصية إذا كان ذلك مطلوبا بموجب القانون أو استجابة لطلبات من قبل السلطات العامة (على سبيل المثال محكمة أو وكالة حكومية). </p>
      <h5 style={{ fontFamily: fontFamilyForAll }}>المتطلبات القانونية الأخرى </h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>يجوز للشركة الكشف عن بياناتك الشخصية اعتقادا راسخا بأن مثل هذا الإجراء ضروري لـ: </p>

      <ul style={{ fontFamily: fontFamilyForAll }}>
        <li>الامتثال لالتزام قانوني </li>
        <li>حماية والدفاع عن حقوق أو ممتلكات الشركة </li>
        <li>منع أو التحقيق في أي مخالفات محتملة فيما يتعلق بالخدمة </li>
        <li>حماية السلامة الشخصية لمستخدمي الخدمة</li>
        <li>الحماية من المسؤولية القانونية </li>
      </ul>

      <h5 style={{ fontFamily: fontFamilyForAll }}>أمن بياناتك الشخصية </h5>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>إن أمن بياناتك الشخصية مهم بالنسبة لنا، ولكن تذكر أنه لا توجد طريقة نقل عبر الإنترنت أو طريقة تخزين إلكترونية آمنة بنسبة 100٪.  بينما نسعى جاهدين لاستخدام وسائل مقبولة تجاريا لحماية بياناتك الشخصية، لا يمكننا ضمان أمنها المطلق. </p>

      <h4 style={{ fontFamily: fontFamilyForAll }}>خصوصية الأطفال </h4>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>لا يتم بيع خدمتنا مع أي شخص يقل عمره عن 13 عاما. نحن لا نجمع معلومات شخصية عن قصد من أي شخص دون سن 13. إذا كنت أحد الوالدين أو الوصي وكنت على علم بأن طفلك قد زودنا ببيانات شخصية، يرجى الاتصال بنا. إذا علمنا أننا جمعنا بيانات شخصية من أي شخص دون سن 13 عاما دون التحقق من موافقة الوالدين، فإننا نتخذ خطوات لإزالة هذه المعلومات من خوادمنا. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>إذا كنا بحاجة إلى الاعتماد على الموافقة كأساس قانوني لمعالجة معلوماتك ويتطلب بلدك موافقة أحد الوالدين، فقد نطلب موافقة والديك قبل أن نجمع هذه المعلومات ونستخدمها. </p>

      <h4 style={{ fontFamily: fontFamilyForAll }}>روابط لمواقع أخرى </h4>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>قد تحتوي خدماتنا على روابط لمواقع أخرى لا يتم تشغيلها من قبلنا. إذا نقرت على رابط طرف ثالث، فسيتم توجيهك إلى موقع هذا الطرف الثالث.  ننصحك بشدة بمراجعة سياسة الخصوصية لكل موقع تزوره. </p>

      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>لا نتحكم ولا نتحمل أي مسؤولية عن المحتوى، سياسات الخصوصية أو ممارسات أي مواقع أو خدمات تابعة لطرف ثالث. </p>

      <h4 style={{ fontFamily: fontFamilyForAll }}>التغييرات على سياسة الخصوصية هذه </h4>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر.  سنقوم بإعلامك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>سنخبرك عبر البريد الإلكتروني و/ أو إشعار على خدمتنا، قبل أن يصبح التغيير ساري المفعول وتحديث تاريخ "آخر تحديث" في الجزء العلوي من سياسة الخصوصية هذه. </p>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>ننصحك بمراجعة سياسة الخصوصية هذه بشكل دوري لأي تغييرات.  تكون التغييرات التي تطرأ على سياسة الخصوصية هذه فعالة عند نشرها على هذه الصفحة. </p>

      <h4 style={{ fontFamily: fontFamilyForAll }}>تواصل معنا </h4>
      <p className="terms-text" style={{ fontFamily: fontFamilyForAll }}>إذا كانت لديك أي أسئلة حول سياسة الخصوصية هذه، يمكنك الاتصال بنا: </p>
      <ul>
        <li style={{ fontFamily: fontFamilyForAll }}>البريد الإلكتروني: help@ebtida.com </li>
      </ul>
    </>
  );
}

export default Ar;

