import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import "antd/dist/antd.min.css";
import { useTranslation } from 'react-i18next'
import { useForgetPasswordMutation } from '../../services/login';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from "react-redux";
function ForgetPasswordForm({ signinModalOpen }) {
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { t } = useTranslation()
  const [forgetPassword, fpResult] = useForgetPasswordMutation()
  const { languageCode, countryCode } = useSelector((state) => state.auth);


  let fontFamilyForAll

  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'ud') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }



  const fontFamily_Text = {
    textAlign: languageCode === 'ar' || languageCode === 'ud' ? 'right' : 'left',
    fontFamily: fontFamilyForAll,
  }

  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }


  return (
    <>
      {fpResult?.data?.success &&
        <div style={{ textAlign: 'center', color: '#7fc687', padding: '20px' }}>
          {fpResult?.data?.success}
        </div>
      }
      {fpResult?.error?.data?.error &&
        <div style={{ textAlign: 'center', color: '#ea8087', padding: '20px' }}>
          {fpResult?.error?.data?.error}
        </div>
      }
      <form onSubmit={handleSubmit(forgetPassword)}>
        <div className="row">
          <div className="col-md-12">
            <div className="Header-singin-header" style={{ padding: '20px', textAlign: 'center' }}>
              <h5 style={fontFamily}> {t('forget_password')} </h5>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group" style={fontFamily_Text}>
              <label style={fontFamily_Text}>{t('email')} </label>
              <input
                type="email"
                className="form-control dlrdflInput"
                id="email"
                aria-invalid={errors.email ? "true" : "false"}
                {...register('email', { required: true })} />
            </div>
          </div>
          <div className="col-md-12 Header-signin-button">
            <div className="form-group">
              <Button
                disabled={fpResult.isLoading ? true : false}
                color="info"
                variant="contained"
                size="large"
                type="submit"
                style={{ width: '100%', background: '#446275', color: '#f7f7f7' }}
              >
                {fpResult.isLoading ? 'Loading...' : t('submit')}
              </Button>
            </div>
          </div>
          <div className="col-md-12 Header-signin-button">
            <div className="form-group" style={{ textAlign: 'center' }}>
              <Link style={fontFamily} to="#" onClick={(() => signinModalOpen(true))}>{t('sign_in')}</Link>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default ForgetPasswordForm;
