import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import Careers from '../../Components/Careers';
function CareersPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="statisc-page privacy-page">
      <br /> <br />
      <div className="container static-container">
        <div className="row static-row" style={{ justifyContent: 'center', width: '100%' }}>
          <div className="col-md-10">
            <Careers />
          </div>
        </div>
      </div>
      <br /> <br />
    </div>
  );
}

export default CareersPage;

