import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useGetBookByIdQuery } from '../../services/book';
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import { useSigninModal } from '../../Components/useModal';
import LoginModal from '../../Layout/Pages/LoginModal';
import { Authentications } from '../../Helpers/Authentications';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Button from '@mui/material/Button';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import VideoPlyr from './VideoPlyr';
import { Helmet } from "react-helmet";
import isMediaLanguageCode from '../../Components/isMediaLanguageCode';
const _ = require('lodash');

function Tutorial(props) {

  const { isUser, isMember, isDataLoading } = Authentications();
  const { userId, userToken, userEmail, userName, languageCode, mediaLang } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'ud') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
    width: '100%'
  }

  const fontFamilyC = {
    fontFamily: fontFamilyForAll,
    width: '100%',
    textAlign: 'start',
    fontSize: '13px'
  }

  const { isSigninModal, signinModal } = useSigninModal();
  const summaryId = new URLSearchParams(location.search).get('id') || ''
  const mediaName = new URLSearchParams(location.search).get('name') || ''
  const getIndex = new URLSearchParams(location.search).get('i') || 0
  const { book_id, item_id, title } = useParams();

  const bookSummary = useGetBookByIdQuery({
    id: book_id,
    languageCode: isMediaLanguageCode(mediaLang),
    userId: userId,
    userToken: userToken
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const bookSum = bookSummary?.data?.book;

  const bookItemsSum = bookSum?.items?.filter(items => items.language === isMediaLanguageCode(mediaLang));
  const summariesss = bookItemsSum?.[0]?.summaries;
  const summaries = _.orderBy(summariesss, ["id"], ["asc"]);

  const titlesss = bookItemsSum?.[0]?.titles;
  const titles = _.orderBy(titlesss, ["id"], ["asc"]);

  const [trackIndex, setTrackIndex] = useState(0);
  const handleClickPrevious = () => {
    setTrackIndex((currentTrack) =>
      currentTrack === 0 ? summaries?.length - 1 : currentTrack - 1
    );
  };

  const handleClickNext = () => {
    setTrackIndex((currentTrack) =>
      currentTrack < summaries?.length - 1 ? currentTrack + 1 : 0
    );
    history.push(`${window.location.pathname}${`?id=${summaries[parseInt(getIndex) < summaries?.length - 1 ? parseInt(getIndex) + 1 : 0]?.id}`}${`&i=${parseInt(getIndex) < summaries?.length - 1 ? parseInt(getIndex) + 1 : 0}`}${`&name=${summaries[parseInt(getIndex) < summaries?.length - 1 ? parseInt(getIndex) + 1 : 0]?.audio}`}`)
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [summaryId, mediaName, trackIndex])

  const finteredSummary = summaries?.filter(itemsss => parseInt(itemsss.id) === parseInt(summaryId || summaries?.[trackIndex]?.id));
  const getListedData = isMember ? summaries : titles
  const finalSummaryList = getListedData?.filter(items => items.id !== finteredSummary?.[0]?.id);


  function litSkeleton() {
    return (
      <>
        <div className='Book-keys-list' style={{ alignItems: 'flex-start', borderBottom: 'none' }}>
          <Skeleton className='tutorail-s-c' animation="wave" />
          <div style={{ width: '100%', margin: '0 10px' }}>
            <Skeleton animation="wave" style={{ width: '100%', height: '12px' }} />
            <Skeleton animation="wave" style={{ width: '100%', height: '12px' }} />
          </div>
        </div>
        <div className='Book-keys-list' style={{ alignItems: 'flex-start', borderBottom: 'none' }}>
          <Skeleton className='tutorail-s-c' animation="wave" />
          <div style={{ width: '100%', margin: '0 10px' }}>
            <Skeleton animation="wave" style={{ width: '100%', height: '12px' }} />
            <Skeleton animation="wave" style={{ width: '100%', height: '12px' }} />
          </div>
        </div>
        <div className='Book-keys-list' style={{ alignItems: 'flex-start', borderBottom: 'none' }}>
          <Skeleton className='tutorail-s-c' animation="wave" />
          <div style={{ width: '100%', margin: '0 10px' }}>
            <Skeleton animation="wave" style={{ width: '100%', height: '12px' }} />
            <Skeleton animation="wave" style={{ width: '100%', height: '12px' }} />
          </div>
        </div>
        <div className='Book-keys-list' style={{ alignItems: 'flex-start', borderBottom: 'none' }}>
          <Skeleton className='tutorail-s-c' animation="wave" />
          <div style={{ width: '100%', margin: '0 10px' }}>
            <Skeleton animation="wave" style={{ width: '100%', height: '12px' }} />
            <Skeleton animation="wave" style={{ width: '100%', height: '12px' }} />
          </div>
        </div>
        <div className='Book-keys-list' style={{ alignItems: 'flex-start', borderBottom: 'none' }}>
          <Skeleton className='tutorail-s-c' animation="wave" />
          <div style={{ width: '100%', margin: '0 10px' }}>
            <Skeleton animation="wave" style={{ width: '100%', height: '12px' }} />
            <Skeleton animation="wave" style={{ width: '100%', height: '12px' }} />
          </div>
        </div>
      </>
    )
  }


  function isVideoAvailable(id, audio) {
    if (parseInt(summaryId) === parseInt(id)) {
      return true
    } else if (!audio) {
      return true
    } else if (!isMember) {
      return true
    } else {
      return false
    }
  }


  return (
    <div className="Tutorial-page">
      <Helmet>
        <title>{title?.replace(/-/g, ' ')} | LiT: Big Ideas in Little Time</title>
      </Helmet>

      <LoginModal isSigninModal={isSigninModal} signinModal={signinModal} />
      <div className="container">
        <div className="book-details-page-card" style={{ padding: '10px 0px 0px 0px', background: '#fff0' }}>
          <div className="row tutorials-player-row">
            <div className="col-md-8 tutorials-player-card">
              <div className="tutorials-player-section">
                <VideoPlyr
                  isMember={isMember}
                  title={finteredSummary?.[0]?.title}
                  book_id={bookSum?.id}
                  language={summaries[trackIndex]?.language}
                  book_item_id={summaries[trackIndex]?.book_item_id}
                  summaryId={summaryId || summaries[trackIndex]?.id}
                  mediaName={mediaName || summaries[trackIndex]?.audio}
                  handleClickNext={handleClickNext}
                  isloading={bookSummary?.isLoading}
                />

              </div>
              <div className="tutorial-body">
                <h4>
                  {bookSummary.isLoading ? <Skeleton style={{ height: '12px' }} animation="wave" /> : finteredSummary?.[0]?.title}
                </h4>
                <div className='search-page-nav' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <p>{bookItemsSum?.[0]?.details}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 Book-card-item tutorials-player-card">
              <div className="summaries-tutorials-section">
                {!isDataLoading &&
                  <>
                    {!isMember &&
                      <Button
                        color="warning"
                        component={Link}
                        to={`/${languageCode}/${mediaLang}/membership`}
                        style={fontFamily}
                        variant={"outlined"}
                        size="medium">{t('start_free_trial')}</Button>
                    }
                  </>
                }
                <div style={{ border: '1px solid #eee', borderRadius: '7px', background: '#e7e7e7', padding: '10px', marginBottom: '10px', marginTop: '10px' }}>
                  <h1 className="summaries-title-tutorials" style={{ margin: 0 }}> {title?.split('-').join(' ')}</h1>
                  <Button
                    className="Book-keys-list"
                    disabled={true}
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      color: '#000000bf',
                      justifyContent: 'left',
                      textTransform: 'capitalize',
                      filter: isMember ? 'blur(0px)' : 'blur(2px)',
                      border: 'none'
                    }}
                  >
                    <LazyLoadImage
                      className="img-list-img-t"
                      effect="black-and-white"
                      src={finteredSummary?.[0]?.image}
                      style={{
                        transition: "0s",
                        width: 'auto',
                        height: '70px',
                        margin: 0,
                      }}
                      visibleByDefault={<Skeleton className="img-list-img-t" variant="rect" />}
                    />
                    <div className='tutorial-llt' style={fontFamilyC}>
                      {finteredSummary?.[0]?.title}
                    </div>
                  </Button>
                </div>
                {bookSummary.isLoading ?
                  litSkeleton() :
                  <>
                    {finalSummaryList?.map((item, i) =>
                      <Button
                        component={Link}
                        key={i}
                        className="Book-keys-list"
                        disabled={isVideoAvailable(item.id, item.audio)}
                        to={isMember ? `${window.location.pathname}${`?id=${item.id}`}${`&i=${i}`}${`&name=${item.audio}`}` : `#`}
                        style={{
                          fontSize: '1rem',
                          lineHeight: 1.3,
                          display: 'flex',
                          cursor: 'pointer',
                          color: '#000000bf',
                          justifyContent: 'left',
                          textTransform: 'capitalize',
                          filter: isMember ? 'blur(0px)' : 'blur(1px)'
                        }}
                      >
                        <LazyLoadImage
                          className="img-list-img-t"
                          effect="black-and-white"
                          src={item.image ? item.image : `https://dj70nht8amdum.cloudfront.net/${book_id}/${isMediaLanguageCode(mediaLang)}/${item_id}/${bookItemsSum?.[0]?.image}`}
                          style={{
                            transition: "0s",
                            width: 'auto',
                            height: '100px',
                            margin: 0,
                          }}
                          visibleByDefault={<Skeleton className="img-list-img-t" variant="rect" />}
                        />
                        <div className='tutorial-llt' style={fontFamily}>
                          {item.title}
                        </div>
                      </Button>
                    )}
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Tutorial;
