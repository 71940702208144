import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookies from 'js-cookie'

export const booksApi = createApi({
  reducerPath: 'booksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['CategoryBook'],
  // keepUnusedDataFor: 1,
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({

    getBookSummaries: builder.query({
      query: (data) => ({
        url: `api/summaries/${data.book_id}`,
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'Content-Type': 'application/json',
        }
      }),
      // keepUnusedDataFor: 1,
      keepUnusedDataFor: 86400000,
      providesTags: ['CategoryBook'],
    }),

  

  }),

})

export const { useGetBookSummariesQuery } = booksApi