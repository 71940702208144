import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import { addCheckoutMethod } from "../../Reducers/authReducer";
import Button from '@mui/material/Button';

function PurchaseSuccess() {
  const { isPurchase, userToken, languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const { success } = useParams();
  const dispatch = useDispatch();

  const pass = new URLSearchParams(location.search).get('pass') || ''

  const goToccountPage = (language) => {
    dispatch(addCheckoutMethod({ isPurchase: '' }))
    history.push(`/${language}`)
  }

  useEffect(() => {
    try {
      if (!isPurchase) {
        history.push(`/`)
      } else {
        if (isPurchase !== userToken) {
          history.push(`/`)
        }
      }

    } catch (e) {
    }
  }, [isPurchase, userToken])



  return (
    <div className="Purchase-page-Success">
      <br /><br />
      <div className="container purchase-container">
        <div className="row purchase-section">
          {isPurchase &&
            <div className="row padding20" style={{ justifyContent: 'center', width: '100%' }}>
              <div className="col-md-6">
                <div style={{ padding: '20px', border: '1px solid #eee', textAlign: 'center', background: '#eee', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px', borderRadius: '10px' }}>

                  <div className="success-msg" style={{ wordBreak: 'break-all' }}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                    {!pass &&
                      <div className="title1">Your subscription has been started! <br />Thank you</div>
                    }
                    {pass === 'activated' &&
                      <div className="title1">Your trial has been started. <br />Thank you</div>
                    }
                    <br />
                    <Button
                      color="info"
                      variant="outlined"
                      size="lasrge"
                      onClick={(() => goToccountPage(languageCode))}
                    >{t('Search for books and stories')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <br /><br />
    </div>
  );
}

export default PurchaseSuccess;
