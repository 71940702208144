import React, { useState } from 'react';
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'

function SearchForm() {
  const { userId, userToken, userEmail, userName, languageCode, countryCode } = useSelector((state) => state.auth);
  const { register, handleSubmit, formState: { errors }, } = useForm();
  const history = useHistory();
  const { t } = useTranslation()

  function onSearch(data, e) {
    history.push(`/${languageCode}/search/${data.search_query}`);
    e.target.reset();
  }

  return (
    <form onSubmit={handleSubmit(onSearch)} className="table_search_card" style={{transition: "all 0.5s",}}>
      <input
        id="search_query"
        aria-invalid={errors.search_query ? "true" : "false"}
        {...register("search_query", { required: true })}
        className="form-control book_search"
        placeholder={t('header_search_paceholder')}
        style={{transition: "all 0.5s",}}
      />
      <IconButton
        aria-haspopup="true"
        type="submit"
        className={`${(languageCode === 'ar') || (languageCode === 'urdu') ? 'table_search_icon-ar' : 'table_search_icon'}`}>
        <SearchIcon />
      </IconButton>
    </form>
  )

}

export default SearchForm;