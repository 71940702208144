import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useGetLibrariesByIdQuery, useRemoveToLibraryMutation } from '../../services/library';
import { useSelector } from "react-redux";
import LibraryBooks from '../../Components/LibraryBooks';
import { Authentications } from '../../Helpers/Authentications';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { Helmet } from "react-helmet";
import Button from '@mui/material/Button';
import isMediaLanguageCode from '../../Components/isMediaLanguageCode';
function Library(props) {
  const { isUser, isMember, isDataLoading, isDataFetching } = Authentications();
  const { userId, userToken, userEmail, userName, languageCode, mediaLang } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 24
  const bookData = useGetLibrariesByIdQuery({
    page: page,
    size: limit,
    languageCode: isMediaLanguageCode(mediaLang),
    userId: userId,
    userToken: userToken
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const pagination = bookData.data && bookData.data?.pagination;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [page, limit])


  const [removeToLibrary, rmToLibResult] = useRemoveToLibraryMutation()
  return (
    <div className="Library-page">

      <Helmet>
        <title>{t('library_page_title')} | LiT: Big Ideas in Little Time</title>
      </Helmet>

      <div className="page-sec">
        <div className="container">
          <div className="page-sec-card">
            <div className="row" style={{ width: '100%', justifyContent: 'center' }}>
              <div className="col-md-8">
                <div className="Banner-text-style">
                  <h1 style={{ fontSize: '1.3rem' }}>{t('library_page_title')}</h1>
                  <p>{t('library_page_text')}</p>

                  <div className='footer-app-icons' style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='footer-app-icons' style={{ display: 'flex', padding: '0px 0px 0px 0px', justifyContent: 'center' }}>
                      <div style={{ background: '#000', display: 'flex', padding: '6px', margin: '0 5px', borderRadius: '4px' }}>
                        <a href="https://apps.apple.com/us/app/lit-lost-in-translation/id6451271720" target='_blank'>
                          <span>
                            <img src={`/assets/svgs/apple-icon.svg`} style={{ width: '75px' }} alt="Lit App" />
                          </span>
                        </a>
                      </div>
                      <div style={{ background: '#000', display: 'flex', padding: '6px', margin: '0 5px', borderRadius: '4px' }}>
                        <a href="https://play.google.com/store/apps/details?id=com.lit.ebtida" target='_blank'>
                          <span>
                            <img src={`/assets/svgs/android-icon.svg`} style={{ width: '75px' }} alt="Lit App" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="category-books-section">


          {(isDataFetching) && (isDataLoading) ? '' :
            <>
              {isMember ?
                <div>
                  {bookData?.data?.books?.length === 0 &&
                    <div className="row" style={{ width: '100%', justifyContent: 'center' }}>
                      <div className="col-md-12">
                        <h5 style={{ textAlign: 'center', fontSize: '1.2rem', fontWeight: 400 }}>{t('no_data_found')}</h5>
                      </div>
                    </div>
                  }
                  <div className="row" style={{ width: '100%' }}>
                    <LibraryBooks
                      data={bookData?.data?.books}
                      length={bookData?.data?.books?.length}
                      loading={bookData?.isLoading}
                      removeToLibrary={removeToLibrary}
                      rmToLibResult={rmToLibResult}
                      getLanguage={languageCode}
                      mediaLang={mediaLang}
                    />
                  </div>
                </div> :
                <div className="row" style={{ width: '100%', justifyContent: 'center' }}>
                  <div className="col-md-12" style={{ textAlign: 'center' }}>
                    <Button
                      className="Header-button-connect button-member"
                      color="warning"
                      variant="outlined"
                      size="large"
                      style={{ borderRadius: '7px', textTransform: 'capitalize', fontWeight: '1.2rem', fontWeight: 400 }}
                      component={Link}
                      to={`/${languageCode}/${mediaLang}/membership`}
                    >
                      <div style={{color: 'black'}}>
                        {t('start_membership')}
                      </div>
                    </Button>
                  </div>
                </div>
              }
            </>
          }

          {bookData?.data?.books?.length > 10 &&
            <div className="category-bottom-paginattion">
              <div className="pagination-card">
                <Pagination
                  dir='ltr'
                  style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px', direction: 'ltr' }}
                  page={parseInt(page)}
                  count={parseInt(pagination?.totalPages)}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                  disabled={bookData?.isLoading ? true : false}
                  renderItem={(item) => (
                    <PaginationItem
                      component={Link}
                      to={{
                        pathname: `${window.location.pathname}`,
                        search: `?page=${parseInt(item.page)}`
                      }}
                      {...item}
                    />
                  )}
                />
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Library;

