import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  userId: localStorage.getItem("userId") ? JSON.parse(localStorage.getItem("userId")) : '',
  userEmail: localStorage.getItem("userEmail") ? localStorage.getItem("userEmail") : '',
  userToken: localStorage.getItem("userToken") ? localStorage.getItem("userToken") : '',
  userName: localStorage.getItem("userName") ? localStorage.getItem("userName") : '',
  isMember: localStorage.getItem("isMember") ? localStorage.getItem("isMember") : false,
  isPaymentMethod: localStorage.getItem("isPaymentMethod") ? localStorage.getItem("isPaymentMethod") : false,
  isCustomer: localStorage.getItem("isCustomer") ? localStorage.getItem("isCustomer") : false,
  isPurchase: localStorage.getItem("isPurchase") ? localStorage.getItem("isPurchase") : '',
  languageCode: localStorage.getItem("languageCode") ? localStorage.getItem('languageCode') : '',
  countryCode: localStorage.getItem("countryCode") ? localStorage.getItem("countryCode") : '',
  mediaLang: localStorage.getItem("mediaLang") ? localStorage.getItem('mediaLang') : '',
  subscription: localStorage.getItem("subscription") ? JSON.parse(localStorage.getItem("subscription")) : '',
  selectedPass: localStorage.getItem("selectedPass") ? JSON.parse(localStorage.getItem("selectedPass")) : false,
  isUser: localStorage.getItem("isUser") ? localStorage.getItem("isUser") : '',
  isUserLoading: localStorage.getItem("isUserLoading") ? localStorage.getItem("isUserLoading") : '',
};

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addToCart(state, action) {
      state.userId = action.payload?.userId
      state.userEmail = action.payload?.userEmail
      state.userToken = action.payload?.userToken
      state.userName = action.payload?.userName
      state.isMember = action.payload?.isMember
      state.isPaymentMethod = action.payload?.isPaymentMethod
      state.isCustomer = action.payload?.isCustomer
      localStorage.setItem("userId", state.userId);
      localStorage.setItem("userToken", state.userToken);
      localStorage.setItem("userEmail", state.userEmail);
      localStorage.setItem("userName", state.userName);
      localStorage.setItem("isMember", state.isMember);
      localStorage.setItem("isPaymentMethod", state.isPaymentMethod);
      localStorage.setItem("isCustomer", state.isCustomer);
    },
    removeToCart(state, action) {
      state.userId = ''
      state.userEmail = ''
      state.userToken = ''
      state.userName = ''
      state.isMember = false
      state.isPaymentMethod = false
      state.isCustomer = false
      state.isPurchase = ''
      localStorage.setItem("userId", state.userId);
      localStorage.setItem("userToken", state.userToken);
      localStorage.setItem("userEmail", state.userEmail);
      localStorage.setItem("userName", state.userName);
      localStorage.setItem("isMember", state.isMember);
      localStorage.setItem("isPaymentMethod", state.isPaymentMethod);
      localStorage.setItem("isCustomer", state.isCustomer);
      localStorage.setItem("isPurchase", state.isPurchase);
    },

    addToLanguage(state, action) {
      state.languageCode = action.payload?.languageCode
      state.countryCode = action.payload?.countryCode
      state.mediaLang = action.payload?.mediaLang
      localStorage.setItem("languageCode", state.languageCode)
      localStorage.setItem("countryCode", state.countryCode)
      localStorage.setItem("mediaLang", state.mediaLang)
    },
    removeToLanguage(state, action) {
      state.languageCode = ''
      localStorage.setItem("languageCode", state.languageCode)
    },

    addCheckoutMethod(state, action) {
      state.isMember = action.payload?.isMember
      state.isPaymentMethod = action.payload?.isPaymentMethod
      state.isCustomer = action.payload?.isCustomer
      state.isPurchase = action.payload?.isPurchase
      localStorage.setItem("isMember", state.isMember);
      localStorage.setItem("isPaymentMethod", state.isPaymentMethod);
      localStorage.setItem("isCustomer", state.isCustomer);
      localStorage.setItem("isPurchase", state.isPurchase);
    },

    addSubscription(state, action) {
      state.subscription = action.payload?.subscription
      localStorage.setItem("subscription", JSON.stringify(state.subscription));
    },

    addSelectedSevenDayaPass(state, action) {
      state.selectedPass = action.payload?.selectedPass
      localStorage.setItem("selectedPass", state.selectedPass);
    },

    addUserToState(state, action) {
      state.isUser = action.payload?.isUser
      state.isUserLoading = action.payload?.isUserLoading
      localStorage.setItem("isUser", state.isUser);
      localStorage.setItem("isUserLoading", state.isUserLoading);
    },
    removeUserToState(state, action) {
      state.isUser = ''
      state.isUserLoading = ''
      localStorage.setItem("isUser", state.isUser);
      localStorage.setItem("isUserLoading", state.isUserLoading);
    },

  },
});

export const { addToCart, removeToCart, addToLanguage, removeToLanguage, addCheckoutMethod, addSubscription, addSelectedSevenDayaPass, addUserToState, removeUserToState } = authReducer.actions;

export default authReducer.reducer;
