import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, useHistory, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useGetUserDataQuery } from '../../services/login';
import { useDispatch, useSelector } from "react-redux";
import { removeToCart, removeUserToState, addToLanguage } from "../../Reducers/authReducer";
import { useGetSubscriptionsQuery } from '../../services/subscriptions';
import { useGetHomepageAdsQuery } from '../../services/homepage';
import LogRocket from 'logrocket';
import Alert from '@mui/material/Alert';
import languages from './Languages';
import i18next from 'i18next'
import isMediaLanguageCode from '../../Components/isMediaLanguageCode';
const { countries, zones } = require("moment-timezone/data/meta/latest.json");
const moment = require('moment-timezone');


function Pages(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId, userToken, userName, userEmail, languageCode, countryCode, isUser, mediaLang } = useSelector((state) => state.auth);
  const Components = props.Components;
  const checkuser = useGetUserDataQuery({ userId: userId, userToken: userToken }, { count: 1 }, { refetchOnMountOrArgChange: true })
  useGetSubscriptionsQuery({ country_code: countryCode, language_code: isMediaLanguageCode(mediaLang) }, { count: 1 }, { refetchOnMountOrArgChange: true })
  useGetHomepageAdsQuery({ languageCode: isMediaLanguageCode(mediaLang) }, { count: 1 }, { refetchOnMountOrArgChange: true })

  if (userId && userToken) {
    if (checkuser?.isSuccess) {
      if (checkuser?.data?.user) {
        if (checkuser?.data?.user?.user_token !== userToken) {
          dispatch(removeToCart());
          dispatch(removeUserToState());
        }
        if (checkuser?.data?.user?.status === 0) {
          dispatch(removeToCart());
          dispatch(removeUserToState());
        }
      }
    }
  }

  if (userId && userToken) {
    LogRocket.identify(userId, {
      name: userName,
      email: userEmail
    });
  }
  LogRocket.init('txpzuu/lit-ebtida');


  const currentLanguage = languages?.find((l) => l.code === i18next?.language)


  const { language, media } = useParams();

  const timeZoneToCountry = {};
  Object.keys(zones).forEach(z => {
    const cityArr = z.split("/");
    const city = cityArr[cityArr.length - 1];
    timeZoneToCountry[city] = countries[zones[z].countries[0]].abbr;
  });
  let userCountryCode;
  if (Intl) {
    // const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userTimeZone = moment.tz.guess() ? moment.tz.guess() : 'US'
    const tzArr = userTimeZone?.split("/");
    const userCity = tzArr[tzArr.length - 1];
    userCountryCode = timeZoneToCountry[userCity];
  }



  function getLangthis(thisL) {
    if (thisL === 'en') {
      return false
    } else if (thisL === 'ar') {
      return false
    } else if (thisL === 'ds') {
      return false
    } else if (thisL === 'ud') {
      return false
    } else if (!thisL) {
      return false
    } else {
      return true
    }
  }

  function getMediathis(thisM) {
    if (thisM === 'arabic') {
      return false
    } else if (thisM === 'desi') {
      return false
    } else if (thisM === 'urdu') {
      return false
    } else if (!thisM) {
      return false
    } else {
      return true
    }
  }



  useEffect(() => {
    try {
      dispatch(addToLanguage({
        languageCode: language ? language : languageCode,
        countryCode: countryCode ? countryCode : userCountryCode ? userCountryCode : 'US',
        mediaLang: media ? media : mediaLang,
      }));
      document.body.dir = currentLanguage?.dir

      if ((!language) || (!media)) {
        if ((!languageCode) | (languageCode === 'undefined')) {
          history.push('/login');
        } else if ((mediaLang === 'undefined') | !mediaLang) {
          history.push('/login');
        } else if ((!countryCode) | (countryCode === 'undefined')) {
          history.push('/login');
        }
      }

      if (getLangthis(language)) {
        return history.push('/login');
      }

      if (getMediathis(media)) {
        return history.push('/login');
      }

    } catch (e) {
      return;
    }

  }, [currentLanguage, language, media, countryCode, languageCode, mediaLang])



  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'ud') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  const fontFamily_Text = {
    textAlign: languageCode === 'ar' || languageCode === 'ud' ? 'right' : 'left',
    fontFamily: fontFamilyForAll,
    display: 'block',
    minHeight: '100vh',
    position: 'relative'
  }

  useEffect(() => {
    if (props.redirectTo) {
      history.push(`/${languageCode}/${mediaLang}/membership`)
    }
  }, [])

  return (
    <div style={fontFamily_Text}>

      {checkuser?.error?.originalStatus === 403 &&
        <div style={{ position: 'fixed', right: 0, laeft: 0, bottom: 0, zIndex: 999, display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Alert severity="error">Something went wrong. Please try after sometime.</Alert>
        </div>
      }

      {props.header && <Header />}
      {/* {props.account && <AccountHeader />} */}
      <Components />
      {props.footer && <Footer />}
    </div>
  );
}

export default Pages;
