import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const bookApi = createApi({
  reducerPath: 'bookApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),
  tagTypes: ['Book'],
  // keepUnusedDataFor: 1,
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({
    getBookById: builder.query({
      query: (data) => {
        return {
          url: `api/book/${data.id}`,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-Language': data.languageCode,
            'Content-Type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      keepUnusedDataFor: 86400000,
      providesTags: ['Book'],
    }),


    addToLibrary: builder.mutation({
      query: (data) => {
        return {
          url: `api/books/${data.languageCode}/${data.userId}/${data.book_item_id}`,
          method: 'POST',
          body: data,
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Book'],
    }),

    removeToLibrary: builder.mutation({
      query: (data) => {
        return {
          url: `api/library/${data.languageCode}/${data.userId}/${data.book_item_id}`,
          method: 'DELETE',
          body: data,
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Book'],
    }),



  }),

})

export const { useGetBookByIdQuery, useAddToLibraryMutation, useRemoveToLibraryMutation } = bookApi