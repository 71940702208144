import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookies from 'js-cookie'

export const libraryApi = createApi({
  reducerPath: 'libraryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Library'],
  // keepUnusedDataFor: 1,
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({

    getLibrariesById: builder.query({
      query: (data) => ({
        url: `api/library/${data.languageCode}/${data.userId}?page=${data.page}&size=${data.size}`,
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }),
      // keepUnusedDataFor: 1,
      keepUnusedDataFor: 86400000,
      providesTags: ['Library'],
    }),


    removeToLibrary: builder.mutation({
      query: (data) => {
        return {
          url: `api/library/${data.languageCode}/${data.userId}/${data.book_id}`,
          method: 'DELETE',
          body: data,
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Library'],
    }),


  }),

})

export const { useGetLibrariesByIdQuery, useRemoveToLibraryMutation } = libraryApi