import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import ReactPlayer from "react-player/lazy";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import screenfull from "screenfull";
import Controls from "./Controls";
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    width: "100%",
    height: '100%',
    position: "relative",
    // "&:hover": {
    //   "& $controlsWrapper": {
    //     visibility: "visible",
    //   },
    // },
  },
  controlsWrapper: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));


function ValueLabelComponent(props) {
  const { children, open, value } = props;
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const format = (seconds) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

let count = 0;

function VideoPlyr({ book_id, language, book_item_id, summaryId, summary_id, mediaName, media_name, handleClickNext, isloading, title, isMember }) {
  const pipSupported = document.pictureInPictureEnabled;
  const classes = useStyles();
  const [showControls, setShowControls] = useState(false);
  // const [count, setCount] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [timeDisplayFormat, setTimeDisplayFormat] = React.useState("normal");
  const [bookmarks, setBookmarks] = useState([]);
  const [getOnReady, setOnReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buffering, setBuffering] = useState(false);

  const [currentTimeaa, setCurrentTimeaa] = useState(0);
  const [progressaa, setProgressa] = useState(0);
  const [maxTimeaa, setMaxTimeaa] = useState(0);

  const [state, setState] = useState({ pip: false, playing: true, controls: false, light: false, muted: false, played: 0, loaded: 0, duration: 0, playbackRate: 1.0, volume: 1, loop: false, seeking: false, playedSeconds: 0 });

  const interval = useRef(null);
  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const controlsRef = useRef(null);
  const canvasRef = useRef(null);

  const { playing, controls, light, muted, loop, playbackRate, pip, played, loaded, seeking, volume, playedSeconds } = state;

  const [pipMode, setPipMode] = useState(false);
  const handleClickPip = (data) => {
    setPipMode(data);
  };

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
    clearInterval(interval.current);
  };

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  const handleProgress = (changeState) => {
    // if (count > 3) {
    //   controlsRef.current.style.visibility = "hidden";
    //   count = 0;
    // }
    // if (controlsRef.current.style.visibility == "visible") {
    //   count += 1;
    // }
    if (!state.seeking) {
      setState({ ...state, ...changeState });
    }
  };

  const handleSeekChange = (e, newValue) => {
    setState({ ...state, played: parseFloat(newValue / 100) });
  };

  // const handleSeekChangenew = (e, newValue) => {
  //   setState({ ...state, seeking: false });
  //   setState({ ...state, played: parseFloat(newValue / 100) });
  //   playerRef.current.seekTo((newValue / 100), "fraction");
  // };

  const handleSeekChangenew = (time, newValue) => {
    playerRef.current.currentTime = newValue / 1000;
    setCurrentTimeaa(newValue);
    setState({ ...state, seeking: false });
    playerRef.current?.seekTo(newValue / 1000);
  };

  useEffect(() => {
    if (getOnReady) {
      setMaxTimeaa((playerRef.current?.getDuration() || 0) * 1000);
    }
  }, [getOnReady, currentTimeaa])


  const setOnPlay = () => {
    interval.current = setInterval(() => {
      setCurrentTimeaa((playerRef.current?.getCurrentTime() || 0) * 1000);
    }, 100);
  };


  const handleSeekMouseDown = (e) => {
    setState({ ...state, seeking: true });
  };

  const handleSeekMouseUp = (e, newValue) => {

    setState({ ...state, seeking: false });
    playerRef.current.seekTo(newValue / 100, "fraction");
  };

  const handleDuration = (duration) => {
    setState({ ...state, duration });
  };

  const handleVolumeSeekDown = (e, newValue) => {
    setState({ ...state, seeking: false, volume: parseFloat(newValue / 100) });
  };
  const handleVolumeChange = (e, newValue) => {
    setState({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };

  const [getScreenMode, setScreenMode] = useState(false);

  const toggleFullScreen = (data) => {
    if (screenfull.isEnabled) {
      screenfull.toggle(playerContainerRef.current);
    }
    setScreenMode(data.status)
  };

  const handleDoubleClick = (data) => {
    if (data.event.detail === 2) {
      if (screenfull.isEnabled) {
        screenfull.toggle(playerContainerRef.current);
      }
      setScreenMode(data.status)
    }
    if (data.event.detail === 1) {
      setState({ ...state, playing: !state.playing });
    }
  };

  // const handleMouseMove = () => {
  //   controlsRef.current.style.visibility = "visible";
  //   count = 0;
  // };

  // const hanldeMouseLeave = () => {
  //   controlsRef.current.style.visibility = "hidden";
  //   count = 0;
  // };

  const handleDisplayFormat = () => {
    setTimeDisplayFormat(
      timeDisplayFormat == "normal" ? "remaining" : "normal"
    );
  };

  const handlePlaybackRate = (rate) => {
    setState({ ...state, playbackRate: rate });
  };

  const hanldeMute = () => {
    setState({ ...state, muted: !state.muted });
  };

  const addBookmark = () => {
    const canvas = canvasRef.current;
    canvas.width = 160;
    canvas.height = 90;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      playerRef.current.getInternalPlayer(),
      0,
      0,
      canvas.width,
      canvas.height
    );
    const dataUri = canvas.toDataURL();
    canvas.width = 0;
    canvas.height = 0;
    const bookmarksCopy = [...bookmarks];
    bookmarksCopy.push({
      time: playerRef.current.getCurrentTime(),
      display: format(playerRef.current.getCurrentTime()),
      image: dataUri,
    });
    setBookmarks(bookmarksCopy);
  };

  const currentTime = playerRef && playerRef.current ? playerRef.current.getCurrentTime() : "00:00";
  const duration = playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";
  const elapsedTime = timeDisplayFormat == "normal" ? format(currentTime) : `-${format(duration - currentTime)}`;

  const totalDuration = format(duration);

  const onChangeBitrate = (event) => {
    const internalPlayer = playerRef.current?.getInternalPlayer('hls');
    if (internalPlayer) {
      internalPlayer.currentLevel = event;
    }
  }

  const onSetDefaultBitrate = (event) => {
    const internalPlayer = playerRef.current?.getInternalPlayer('hls');
    internalPlayer.manualLevel = event;
  }

  const endBufferingFunction = (data) => {
    if (data.type === "playing") {
      setLoading(false);
      setBuffering(false);
    }
  };

  const onBuffering = () => {
    setBuffering(true);
  };

  function checkthisDuratio(dataaa) { }
  function setOnPause(data) { }


  return (
    <>
      <div className="player-section" style={{ border: '1px solid #eee', position: 'relative', zIndex: 111 }}>
        <div
          // onMouseMove={handleMouseMove}
          // onMouseLeave={hanldeMouseLeave}
          ref={playerContainerRef}
          className='player-wrapper'
        >

          <ReactPlayer
            className='react-player'
            scrollableAncestor='window'
            playsinline={true}
            style={{ display: 'flex' }}
            ref={playerRef}
            width="100%"
            height="100%"
            url={`https://m.ebtida.com/v/${book_id}/${language}/${book_item_id}/${summaryId}/${mediaName}/video_${summaryId}_master.m3u8`}
            // url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4"
            pip={pipMode}
            playing={playing}
            controls={false}
            light={light}
            loop={false}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            onPlay={setOnPlay}
            onPause={setOnPause}
            onDuration={checkthisDuratio}
            onProgress={handleProgress}
            onDisablePIP={(() => setPipMode(false))}
            // onEnablePIP={setEnablePIP}
            onBuffer={onBuffering}
            onBufferEnd={endBufferingFunction}
            onEnded={((e) => isMember && handleClickNext())}
            onReady={(() => setOnReady(true))}
            config={{
              file: {
                forceVideo: true,
                hlsOptions: {
                  autoStartLoad: true,
                  startLevel: 1,
                  forceHLS: true,
                }
              },
            }}
          />
          {/* {!getOnReady &&
            <div className="CircularProgress-tutorial">
              <CircularProgress style={{ color: '#ddd', width: '35px', height: '35px' }} />
            </div>
          } */}
          {(loading) && (!getOnReady) &&
            <div className="CircularProgress-tutorial">
              <CircularProgress style={{ color: '#ddd', width: '35px', height: '35px' }} />
            </div>
          }
          {(!loading) && (buffering) &&
            <div className="CircularProgress-tutorial">
              <img
                style={{ width: '48px' }}
                src="https://cdn.ebtida.com/1691780941667-buffering01.gif"
                alt=""
              />
            </div>
          }
          <div className='player-overlay-tutorial'></div>
          <Controls
            duration={duration}
            loading={loading}
            buffering={buffering}
            isMember={isMember}
            handleClickNext={handleClickNext}
            title={title}
            playerRef={playerRef}
            onChangeBitrate={onChangeBitrate}
            onSetDefaultBitrate={onSetDefaultBitrate}
            ref={controlsRef}
            onSeek={handleSeekChange}
            onSeekMouseDown={handleSeekMouseDown}
            onSeekMouseUp={handleSeekMouseUp}
            onDuration={handleDuration}
            onRewind={handleRewind}
            onPlayPause={handlePlayPause}
            onFastForward={handleFastForward}
            playing={playing}
            played={played}
            loaded={loaded}
            elapsedTime={elapsedTime}
            totalDuration={totalDuration}
            onMute={hanldeMute}
            muted={muted}
            onVolumeChange={handleVolumeChange}
            onVolumeSeekDown={handleVolumeSeekDown}
            onChangeDispayFormat={handleDisplayFormat}
            playbackRate={playbackRate}
            onPlaybackRateChange={handlePlaybackRate}
            onToggleFullScreen={toggleFullScreen}
            handleDoubleClick={handleDoubleClick}
            volume={volume}
            onBookmark={addBookmark}
            handleClickPip={handleClickPip}
            pipMode={pipMode}
            getScreenMode={getScreenMode}
            getOnReady={getOnReady}
            currentTime={currentTime}
            handleSeekChangenew={handleSeekChangenew}
            playedSeconds={playedSeconds}
            currentTimeaa={currentTimeaa}
            maxTimeaa={maxTimeaa}
          />
        </div>
        {/* <Grid container style={{ marginTop: 20 }} spacing={3}>
          {bookmarks.map((bookmark, index) => (
            <Grid key={index} item>
              <Paper
                onClick={() => {
                  playerRef.current.seekTo(bookmark.time);
                  controlsRef.current.style.visibility = "visible";

                  setTimeout(() => {
                    controlsRef.current.style.visibility = "hidden";
                  }, 1000);
                }}
                elevation={3}
              >
                <img crossOrigin="anonymous" src={bookmark.image} />
                <Typography variant="body2" align="center">
                  bookmark at {bookmark.display}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <canvas ref={canvasRef} /> */}
      </div>
    </>
  );
}

export default VideoPlyr;
