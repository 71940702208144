import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import './index.scss';
// import Skeleton from '@material-ui/lab/Skeleton';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";

function CarouselSkeleton({ background, padding}) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, isCustomer, languageCode } = useSelector((state) => state.auth);


  return (
    <Swiper
    dir='ltr'
      slidesPerView={window.innerWidth > 992 ? 5 : 2}
      spaceBetween={20}
      slidesPerGroup={1}
      loop={false}
      loopFillGroupWithBlank={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="mySwiper recentlySectionSwiper sfb"
      style={{background: background,  padding: padding}}
    >
      <SwiperSlide className="Dashboard-CI-CRD">
        <Skeleton variant="rect" className="Dashboard-CI" />
        <h6><Skeleton variant="rect" height={12}  /></h6>
        <h5><Skeleton variant="rect" height={12} width={'60%'}  /></h5>
      </SwiperSlide>
      <SwiperSlide className="Dashboard-CI-CRD">
        <Skeleton variant="rect" className="Dashboard-CI" />
        <h6><Skeleton variant="rect" height={12} /></h6>
        <h5><Skeleton variant="rect" height={12} width={'60%'} /></h5>
      </SwiperSlide>
      <SwiperSlide className="Dashboard-CI-CRD">
        <Skeleton variant="rect" className="Dashboard-CI" />
        <h6><Skeleton variant="rect" height={12} /></h6>
        <h5><Skeleton variant="rect" height={12} width={'60%'} /></h5>
      </SwiperSlide>
      <SwiperSlide className="Dashboard-CI-CRD">
        <Skeleton variant="rect" className="Dashboard-CI" />
        <h6><Skeleton variant="rect" height={12} /></h6>
        <h5><Skeleton variant="rect" height={12} width={'60%'} /></h5>
      </SwiperSlide>
      <SwiperSlide className="Dashboard-CI-CRD">
        <Skeleton variant="rect" className="Dashboard-CI" />
        <h6><Skeleton variant="rect" height={12} /></h6>
        <h5><Skeleton variant="rect" height={12} width={'60%'} /></h5>
      </SwiperSlide>
    </Swiper>
  );
}

export default CarouselSkeleton;
