import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import './AccountHeader/index.scss';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const drawerWidth = 240;


function SideMenu(props) {
  const { languageCode, mediaLang } = useSelector((state) => state.auth);
  const { t } = useTranslation()

  let fontFamilyForAll

  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'ud') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }


  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };



  const drawer = (
    <div>
      <List>
      <ListItem disablePadding>
          <Link className={`AccountHeader-menu-item ${window.location.pathname === `/${languageCode}/${mediaLang}/account/profile` && 'active-account-header'}`} to={`/${languageCode}/${mediaLang}/account/profile`}>
            <ListItemButton>
              <ListItemText primary={<div style={fontFamily}>{t('profile')}</div>} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link className={`AccountHeader-menu-item ${window.location.pathname === `/${languageCode}/${mediaLang}/account/subscription` && 'active-account-header'}`} to={`/${languageCode}/${mediaLang}/account/subscription`}>
            <ListItemButton>
              <ListItemText primary={<div style={fontFamily}>{t('subscription')}</div>} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link className={`AccountHeader-menu-item ${window.location.pathname === `/${languageCode}/${mediaLang}/account/membership` && 'active-account-header'}`} to={`/${languageCode}/${mediaLang}/account/membership`}>
            <ListItemButton>
              <ListItemText primary={<div style={fontFamily}>{t('membership_history')}</div>} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link className={`AccountHeader-menu-item ${window.location.pathname === `/${languageCode}/${mediaLang}/account/payment-methods` && 'active-account-header'}`} to={`/${languageCode}/${mediaLang}/account/payment-methods`}>
            <ListItemButton>
              <ListItemText primary={<div style={fontFamily}>{t('payment_methods')}</div>} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link className={`AccountHeader-menu-item ${window.location.pathname === `/${languageCode}/${mediaLang}/account/settings` && 'active-account-header'}`} to={`/${languageCode}/${mediaLang}/account/settings`}>
            <ListItemButton>
              <ListItemText primary={<div style={fontFamily}>{t('settings')}</div>} />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link className={`AccountHeader-menu-item ${window.location.pathname === `/${languageCode}/${mediaLang}/account/support` && 'active-account-header'}`} to={`/${languageCode}/${mediaLang}/account/support`}>
            <ListItemButton>
              <ListItemText primary={<div style={fontFamily}>{t('support')}</div>} />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </div>
  );





  return (
    <div className="AccountHeader-page">
      <Toolbar className='profile-page-mobile-menu-icon'>
        <IconButton
          style={{display: 'flex', alignItems: 'center'}}
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />  
        </IconButton>
      </Toolbar>
      <Box
        className='account-side-menu'
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
}



export default SideMenu;
