import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { Helmet } from "react-helmet";
import Box from '@mui/material/Box';
import SideMenu from '../SideMenu';
const drawerWidth = 240;

function Support() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, isCustomer, languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()

  if (!userEmail && !userToken) {
    history.push('/')
  }

  return (
    <div className="Account-page">
      <Helmet>
        <title>{t('support')} | LiT: Big Ideas in Little Time</title>
      </Helmet>
      <div className="container">
        <div className="profile-card">

          <div className="row">
            <div className="col-md-12">
              <h5 className='account-heading' style={{ paddingBottom: '15px' }}>{t('support')}</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 Category-item">
              <div className='Profile-page-section'>
                <SideMenu />
                <Box
                  className='account-page-content-section'
                  component="main"
                  sx={{ flexGrow: 1, p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                  <div className="section-card">


                    <br />
                    <div className="change-email" style={{ padding: '10px' }}>
                      <div><strong>{t('email')}: </strong>&nbsp; help@ebtida.com</div>
                    </div>
                  </div>
                </Box>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
    </div>
  );
}

export default Support;

