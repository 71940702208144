import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useGetBookByIdQuery, useAddToLibraryMutation, useRemoveToLibraryMutation } from '../../services/book';
import { useTranslation } from 'react-i18next'
import HeadphonesIcon from '@mui/icons-material/Headphones';
import HeadsetOffIcon from '@mui/icons-material/HeadsetOff';
import LyricsIcon from '@mui/icons-material/Lyrics';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { useSelector } from "react-redux";
import LanguageIcon from '@mui/icons-material/Language';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Skeleton from '@mui/material/Skeleton';
import { useSigninModal } from '../../Components/useModal';
import LoginModal from '../../Layout/Pages/LoginModal';
import { Authentications } from '../../Helpers/Authentications';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useGetHomepageBooksQuery } from '../../services/homepage';
import Books from '../../Components/Home/Books';
import Loader from '../../Helpers/Loader';
import Player from './Player';
import { Helmet } from "react-helmet";
import CircularProgress from '@mui/material/CircularProgress';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import isMediaLanguageCode from '../../Components/isMediaLanguageCode';
import 'react-lazy-load-image-component/src/effects/blur.css';

const _ = require('lodash');

function Book(props) {
  const { isUser, isMember, isDataFetching, isDataLoading } = Authentications();
  const { userId, userToken, userEmail, userName, languageCode, mediaLang } = useSelector((state) => state.auth);

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'ud') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  const fontFamily_Text = {
    fontFamily: fontFamilyForAll,
  }

  const books = useGetHomepageBooksQuery({ languageCode: isMediaLanguageCode(mediaLang) }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const { isSigninModal, signinModal } = useSigninModal();
  const { book_id, item_id, title } = useParams();

  const bookInfo = useGetBookByIdQuery({
    id: book_id,
    languageCode: isMediaLanguageCode(mediaLang),
    userId: userId,
    userToken: userToken
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const book = bookInfo?.data?.book;

  const bookItems = book?.items?.filter(items => items.language === isMediaLanguageCode(mediaLang));
  const bookItem = bookItems?.[0];

  const summaries = book?.items?.filter(items => items.language === isMediaLanguageCode(mediaLang));
  const summary = summaries?.[0]?.summaries?.[0];
  const titlesss = summaries?.[0]?.titles;

  const titles = _.orderBy(titlesss, ["id"], ["asc"]);

  const [addToLibrary, addToLibResult] = useAddToLibraryMutation()
  const [removeToLibrary, rmToLibResult] = useRemoveToLibraryMutation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });

    if (!bookInfo?.isLoading) {
      if (bookItems?.[0]?.category !== 'books') {
        history.push('/')
      }
    }
  }, [book_id, bookInfo])


  function bookPageButton() {
    if ((userToken && userId)) {
      if (isDataFetching && isDataLoading) {
        return (
          ''
          // <Skeleton style={{ width: 200 }} height={30} animation="wave" />
        )
      } else {
        if (isMember) {
          return (
            <Link disabled={titles?.length === 0 && true} aria-disabled className="w100"
              to={titles?.length > 0 && `/${languageCode}/${mediaLang}/summary/${book_id}/${item_id}/${title.replace(/\s+/g, '-').toLowerCase()}`}>
              <Button
                style={{ borderRadius: '100px' }}
                disabled={titles?.length === 0 && true}
                className="dFlex w100"
                color="info"
                variant="outlined"
                size="large"
              >
                <HeadphonesIcon style={{ margin: '0 4px' }} />
                <span>{t('read_now')}</span>
              </Button>
            </Link>
          )
        } else {
          return (
            <>
              <Link className="w100" to={`/${languageCode}/${mediaLang}/membership`}>
                <Button
                  style={{ whiteSpace: 'nowrap', margin: '0 5px', borderRadius: '100px' }}
                  className="dFlex w100"
                  color="warning"
                  variant="outlined"
                  size="large"
                >
                  <HeadphonesIcon style={{ margin: '0 4px' }} />
                  <span>{t('start_membership')}</span>
                </Button>
              </Link>
            </>
          )
        }
      }
    } else {
      return (
        <>
          <Button
            style={{ whiteSpace: 'nowrap', margin: '0 5px', borderRadius: '100px' }}
            className="dFlex w100"
            color="info"
            variant="outlined"
            size="large"
            onClick={(() => signinModal(true))}>
            <HeadphonesIcon style={{ margin: '0 4px' }} />
            <span>{t('signin_signup')}</span>
          </Button>
        </>
      )
    }
  }




  function bookAddToLibrary() {
    if (bookItem?.library?.length > 0) {
      return (
        <>
          {rmToLibResult.isLoading ?
            <IconButton>
              <CircularProgress color="inherit" style={{ width: '2rem', height: '2rem' }} />
            </IconButton>
            :
            <>
              <IconButton
                title={t('remove_from_list')}
                disabled={(titles?.length === 0) || (!isMember) && true}
                aria-haspopup="true"
                onClick={() => titles?.length > 0 && removeToLibrary({
                  languageCode: isMediaLanguageCode(mediaLang),
                  userId: userId,
                  userToken: userToken,
                  book_item_id: bookItem?.id,
                })}
              >
                <BookmarkIcon style={{ width: '2rem', height: '2rem' }} />
              </IconButton>
            </>
          }
        </>
      )
    }
    if (bookItem?.library?.length === 0) {
      return (
        <>
          {userId && userToken &&
            <>
              {addToLibResult.isLoading ?
                <IconButton>
                  <CircularProgress color="inherit" style={{ width: '2rem', height: '2rem' }} />
                </IconButton>
                :
                <>
                  <IconButton
                    title={t('add_to_list')}
                    disabled={(titles?.length === 0) || (!isMember) && true}
                    aria-haspopup="true"
                    onClick={() => titles?.length > 0 && addToLibrary({
                      languageCode: isMediaLanguageCode(mediaLang),
                      userId: userId,
                      userToken: userToken,
                      book_item_id: bookItem?.id,
                    })}
                  >
                    <BookmarkBorderIcon style={{ width: '2rem', height: '2rem' }} />
                  </IconButton>
                </>
              }
            </>
          }
        </>
      )
    }
  }


  let fontClass;
  if (languageCode === 'ar') {
    fontClass = 'font-class-ar'
  } else if (languageCode === 'ud') {
    fontClass = 'font-class-urdu'
  } else {
    fontClass = 'font-class-default'
  }


  return (
    <div className="Product-page Home-page">

      <Helmet>
        <title>{title?.replace(/-/g, ' ')} | LiT: Big Ideas in Little Time</title>
        <meta name="description" content={bookItem?.subtitle} />
      </Helmet>



      <LoginModal isSigninModal={isSigninModal} signinModal={signinModal} />
      <div className="container">
        <div className="book-details-page-card">
          <div className="row Product-row-reverse">
            <div className="col-md-8 Book-card-item1">
              <div className="Banner-text-style">
                <h1 className="page-title-heading">
                  {title?.split('-').join(' ')}
                </h1>
                <p>
                  {bookInfo.isLoading ? <Skeleton animation="wave" /> : bookItem?.authors}
                </p>
                <p className="book-subtitle">
                  {bookInfo.isLoading ? <Skeleton animation="wave" /> : bookItem?.subtitle}
                </p>
                <div className="features">
                  <div className="dFlex">
                    <div className={`${languageCode === 'ar' || languageCode === 'ud' ? 'keys-ar' : 'keys'}`}>
                      <TipsAndUpdatesIcon className="product-page-icon" /> &nbsp;{titles?.length}  {t('key_idea')}</div>
                    <div className="audio">{titles?.length > 0 ? <LyricsIcon className="product-page-icon" /> : <HeadsetOffIcon className="product-page-icon" />} &nbsp;{t('audio_and_text')}</div>
                  </div>
                </div>
                <div className="Book-key-list-button">
                  <div className="Book-key-list">
                    {bookInfo.isLoading ? <Skeleton animation="wave" width={'30%'} /> :
                      <div className="dFlex book-button-width">
                        {bookPageButton()}
                      </div>
                    }
                  </div>
                </div>
                <div className="Book-details">
                  <div className="page-title-heading-b"> {t('what_is_it_about')}</div>
                  <p>
                    {bookInfo.isLoading ?
                      <>
                        <Skeleton animation="wave" width={'40%'} />
                        <Skeleton animation="wave" />
                      </>
                      :
                      bookItem?.details
                    }
                  </p>
                </div>
                <div className={`Book-loginSignup ${languageCode === 'ar' || languageCode === 'ud' ? 'Book-loginSignup_rtl' : 'Book-loginSignup_ltr'}`}>
                  <div>
                    <div className='page-title-heading'>{summary?.title ? summary?.title : <Skeleton animation="wave" />}</div>
                    {summary?.summary ?
                      <div style={fontFamily_Text} className={`HomePage_static_description ${languageCode === 'ar' || languageCode === 'ud' ? 'ProductDescrition_rtl' : 'ProductDescrition_ltr'} ${fontClass}`}
                        dangerouslySetInnerHTML={{
                          __html: summary?.summary?.replace('<span', '<div').replace('<h1', '<div').replace('<p', '<div').replace('<em', '<div').replace('&nbsp;', '').replace(/&nbsp;/g, '')
                        }}>
                      </div>
                      :
                      <Skeleton animation="wave" />
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 Book-card-item">
              <div className="Banner-text-style bp-book-image" style={{ position: 'relative' }}>
                <div className="book-img-list-card book-img-plyr">
                  {bookItem?.image ?
                    <LazyLoadImage
                      className='book-img-list-img'
                      alt={'lit.ebtida.com'}
                      title={title}
                      effect="blur"
                      src={`https://dj70nht8amdum.cloudfront.net/${book_id}/${isMediaLanguageCode(mediaLang)}/${bookItem?.id}/${bookItem?.image}`}
                    />
                    :
                    <Skeleton className="book-img-list-img" style={{ width: '60%' }} animation="wave" />
                  }
                  <Player
                    book_id={bookItem?.book_id}
                    language={summary?.language}
                    book_item_id={summary?.book_item_id}
                    id={summary?.id}
                    media={summary?.audio}
                  />
                </div>
                <div className="book-library_button_card">
                  {bookAddToLibrary()}
                </div>
              </div>

              <div className="Banner-text-style">
                <div style={{ padding: "30px 0px 15px 0px" }}>
                  <h4 style={{ fontSize: "1rem", fontWeight: "600" }}>{t('key_ideas_title')}</h4>
                </div>
                <div>
                  {bookInfo?.isLoading && <Skeleton animation="wave" counts={titles?.length} />}
                  {bookInfo?.isLoading && <Skeleton animation="wave" counts={titles?.length} />}
                  <ol style={{ paddingLeft: '12px' }}>
                    {titles?.map((item, i) =>
                      <li key={i}>
                        <div className="Book-keys-list">
                          <span>{item.title}</span>
                        </div>
                      </li>
                    )}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <Books
        data={books?.data?.books}
        loading={books?.isLoading}
        length={books?.data?.books?.length}
        title={''}
        languageCode={languageCode}
        mediaLang={mediaLang}
        background={'#fcfcfc'}
        backgrounds={'#fff0'}
        padding={'30px'}
      />

    </div>
  );
}

export default Book;
