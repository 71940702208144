import React, { useState, useEffect, Fragment } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next'
function Careers() {
  const { t } = useTranslation()
  return (
    <>
      <div className="static-container-card" style={{ textAlign: 'left', background: '#fcfcfc', padding: '20px', border: '1px solid #eee', borderRadius: '7px' }}>
        <h5 style={{ padding: '20px 0px 20px 0px', fontSize: '1.4rem', fontWeight: 300 }}>
          {t('careers')}
        </h5>
        <h6 style={{ padding: '20px 0px 20px 0px' }}>
          {t('Join Us and Transform the Way People Consume Knowledge')}
        </h6>
        <p className="terms-text">Welcome to the Careers page of LiT Ebtida, the innovative platform where we bring the world of books to life through audio summaries. We are a multicultural, multilingual team that values diversity, creativity, and passion for learning. If you believe in the power of words, the impact of knowledge, and the accessibility of languages, then LiT Ebtida could be your next professional home.</p>
        <br />
      </div>

    </>
  );
}

export default Careers;

