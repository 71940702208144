import React, { useState, useEffect, Fragment } from 'react';
import "antd/dist/antd.min.css";
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useSelector } from "react-redux";
function OuterPages(props) {
  const { languageCode } = useSelector((state) => state.auth);
  const Components = props.Components;

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'ud') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  const fontFamily_Text = {
    textAlign: languageCode === 'ar' || languageCode === 'ud' ? 'right' : 'left',
    fontFamily: fontFamilyForAll,
    display: 'block',
    minHeight: '100vh',
    position: 'relative'
  }

  return (
    <div style={fontFamily_Text}>
      {props.header && <Header />}
      <Components />
      {props.footer && <Footer />}
    </div>
  );
}

export default OuterPages;
