import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import { SearchOutlined } from '@ant-design/icons';
import './index.scss';
import { useTranslation } from 'react-i18next'
// import Pagination from "../../Pagination";
import { useGetMembershipListQuery } from '../../../services/membership';
import TableSkeleton from "../TableSkeleton";
import { useSelector } from "react-redux";
import { message, Switch as Clickable, Tag } from "antd";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { Helmet } from "react-helmet";

import Box from '@mui/material/Box';
import SideMenu from '../SideMenu';
const drawerWidth = 240;

var moment = require('moment');
function Membership() {

  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, isCustomer, languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()


  if (!userEmail && !userToken) {
    history.push('/')
  }

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 24

  const membership = useGetMembershipListQuery({ page: page, size: limit, userId: userId, userToken: userToken }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const membershipData = membership?.data?.membership
  const pagination = membership?.data?.pagination



  return (
    <div className="Account-page tabel_cards">

      <Helmet>
        <title>{t('membership_history')} | LiT: Big Ideas in Little Time</title>
      </Helmet>

      <div className="container">
        <div className="profile-card">

          <div className="row">
            <div className="col-md-12">
              <h5 className='account-heading' style={{ paddingBottom: '15px' }}>{t('membership_history')}</h5>
            </div>
          </div>



          <div className="row cardList__margin">
            <div className="col-md-12">
              <div className='Profile-page-section'>
                <SideMenu />
                <Box
                  className='account-page-content-section'
                  component="main"
                  sx={{ flexGrow: 1, p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                  <div className="section-card">
                    <div className="jcsb ai df">
                      <div>&nbsp;</div>
                      <div className="table_search_card">
                        <div className="newFlexPagi" style={{ padding: '0px 0px 10px 0px' }}>
                          <Pagination
                            dir='ltr'
                            style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px', direction: 'ltr' }}
                            page={parseInt(page)}
                            count={parseInt(pagination?.totalPages)}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            disabled={membership?.isLoading ? true : false}
                            renderItem={(item) => (
                              <PaginationItem
                                component={Link}
                                to={{
                                  pathname: `${window.location.pathname}`,
                                  search: `?page=${parseInt(item.page)}`
                                }}
                                {...item}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tabelOverflow">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{t('type')}</th>
                          <th>{t('subscription')}</th>
                          <th>{t('payment')}</th>
                          <th>{t('start_date')}</th>
                          <th>{t('end_date')}</th>
                          <th className="sticky-column" style={{ textAlign: 'right' }}>
                            {t('status')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          {membership?.isLoading &&
                            <TableSkeleton />
                          }
                        </>
                        {membershipData?.map((item, i) =>
                          <tr key={i}>
                            <td>
                              <div>{item.state === 'trial' && 'Trial'}{item.state === 'membership' && 'Membership'}</div>
                            </td>
                            <td>
                              <div>{item.currency} {item.price / 100} - {item.plan?.split('_').join(' ')}</div>
                            </td>
                            <td>
                              <Tag color={item.checkout === 1 ? "#7fc687" : '#ea8087'}>
                                <small>{item.checkout === 1 ? 'Success' : 'Failed'}</small>
                              </Tag>
                            </td>
                            <td>
                              <div>{moment(item.start_date).format("MMM Do YYYY")}</div>
                            </td>
                            <td style={{ position: 'relative' }}>
                              <div>
                                <span>{moment(item.end_date).format("MMM Do YYYY")}</span>
                              </div>
                            </td>
                            <td className="sticky-column" style={{ textAlign: 'right' }}>
                              <div className="manage-buttons">
                                <Tag color={item.active === 1 ? "#7fc687" : '#ea8087'}>
                                  <small>{item.active === 1 ? 'Active' : 'Expired'}</small>
                                </Tag>
                                {item.status === 0 &&
                                  <Tag color={'#ea8087'}>
                                    <small>{'Cancelled'}</small>
                                  </Tag>
                                }
                              </div>
                            </td>
                          </tr>
                        )
                        }
                      </tbody>
                    </table>
                    {membershipData?.length === 0 &&
                      <div className="no-data-found"> No data found</div>
                    }
                  </div>

                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
    </div>
  );
}

export default Membership;