import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMute from "@material-ui/icons/VolumeOff";
import FullScreen from "@material-ui/icons/Fullscreen";
import Popover from "@material-ui/core/Popover";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Settings from "@material-ui/icons/Settings";
import Forward10Icon from '@mui/icons-material/Forward10';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { propTypes } from "react-bootstrap/esm/Image";
import InfoIcon from '@mui/icons-material/Info';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { VideoSeekSlider } from "react-video-seek-slider";
import "react-video-seek-slider/styles.css"
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import TuneIcon from '@mui/icons-material/Tune';
import { useSelector } from "react-redux";
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const useStyles = makeStyles((theme) => ({
  controlsWrapper: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    background: "#0000002b",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  button: {
    margin: theme.spacing(1),
  },
  controlIcons: {
    color: "#fcfcfc",
    padding: '5px',
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fcfcfc",
      transform: "scale(1)",
    },
  },

  bottomIcons: {
    color: "#fcfcfc",
    padding: '3px',
    "&:hover": {
      color: "#fcfcfc",
    },
  },

  volumeSlider: {
    width: 100,
  },
}));

const PrettoSlider = withStyles({
  root: {
    height: 5,
  },

  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 5,
    borderRadius: 2,
    color: '#fff'
  },
  rail: {
    height: 5,
    borderRadius: 2,
    color: '#eee'
  },
})(Slider);

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const Controls = forwardRef(
  (
    {
      onSeek,
      onSeekMouseDown,
      onSeekMouseUp,
      onDuration,
      onRewind,
      onPlayPause,
      onFastForward,
      playing,
      played,
      elapsedTime,
      totalDuration,
      onMute,
      muted,
      onVolumeSeekDown,
      onChangeDispayFormat,
      playbackRate,
      onPlaybackRateChange,
      onToggleFullScreen,
      handleDoubleClick,
      volume,
      onVolumeChange,
      onBookmark,
      playerRef,
      onChangeBitrate,
      title,
      handleClickNext,
      handleClickPip,
      pipMode,
      getScreenMode,
      onSetDefaultBitrate,
      getOnReady,
      isMember,
      buffering,
      loading,
      loaded,
      duration,
      currentTime,
      handleTimeChange,
      getcurrentTime,
      handleSeekChangenew,
      playedSeconds,
      currentTimeaa,
      maxTimeaa
    },
    ref
  ) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const { languageCode } = useSelector((state) => state.auth);

    let fontFamilyForAll
    if (languageCode === 'ar') {
      fontFamilyForAll = 'GessTwoMedium, sans-serif'
    } else if (languageCode === 'ud') {
      fontFamilyForAll = 'AlQalam Shakir'
    } else {
      fontFamilyForAll = 'Poppins, sans-serif'
    }
    const fontFamily = {
      fontFamily: fontFamilyForAll,
      fontSize: '13px'
    }

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [anchorElA, setAnchorElA] = React.useState(null);
    const handleClickA = (event) => {
      setAnchorElA(event.currentTarget);
    };

    const handleCloseA = () => {
      setAnchorElA(null);
    };

    const openA = Boolean(anchorElA);
    const idA = openA ? "simple-popover" : undefined;

    const getCurrentQuality = playerRef.current?.getInternalPlayer('hls')?.levels?.filter((level, id) => id === playerRef.current?.getInternalPlayer('hls')?.currentLevel);

    const [openaa, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleCloseaa = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(openaa);
    React.useEffect(() => {
      if (prevOpen.current === true && openaa === false) {
        anchorRef.current.focus();
      }

      prevOpen.current = openaa;
    }, [openaa]);


    const [getQualityCard, setQualityCard] = React.useState(false);
    const [getPlaybackCard, setPlaybackCard] = React.useState(false);

    function showQualityClick() {
      setQualityCard(true)
    }
    function hideQualityClick() {
      setQualityCard(false)
    }

    function showPlaybackClick() {
      setPlaybackCard(true)
    }
    function hidePlaybackClick() {
      setPlaybackCard(false)
    }


    const [opendr, setOpendr] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');

    const handleClickOpen = () => {
      setOpendr(true);
    };

    const handleClosedr = () => {
      setOpendr(false);
    };

    const handleMaxWidthChange = (event) => {
      setMaxWidth(
        // @ts-expect-error autofill of arbitrary value is not handled.
        event.target.value,
      );
    };

    const handleFullWidthChange = (event) => {
      setFullWidth(event.target.checked);
    };


    return (
      <div className="player-container-controller" ref={ref}>
        <div className="controller-dfsb-top">
          <div>
            <div variant="h5" style={{ color: "#fff" }}>
              {/* {title} */}&nbsp;
            </div>
          </div>
          <div>
            <IconButton className={`${'m-hide'} ${'controller-bottom-icons'}`}>
              <InfoIcon style={{ color: '#eee' }} />
            </IconButton>
            {window.innerWidth < 992 &&
              <IconButton className={`${'controller-bottom-icons'}`} onClick={handleClickOpen}>
                <Settings style={{ width: '1.8rem', height: '1.8rem', color: '#eee' }} />
              </IconButton>
            }
          </div>
        </div>
        {window.innerWidth < 992 &&
          <Dialog
            fullWidth={fullWidth}
            maxWidth={'xs'}
            open={opendr}
            onClose={handleClosedr}
          >
            <DialogContent style={{ padding: '40px' }}>
              <IconButton style={{ position: 'absolute', right: '0px', zIndex: 111, top: '0px' }} onClick={handleClosedr}>
                <CloseIcon />
              </IconButton>
              <Box
                noValidate
                component="form"
                sx={{ display: 'flex', flexDirection: 'column', m: 'auto', width: '100%' }}
              >
                <FormControl sx={{ mt: 2, minWidth: 120 }}>
                  <InputLabel htmlFor="max-width">
                    Quality
                  </InputLabel>
                  <Select
                    autoFocus
                    value={playerRef.current?.getInternalPlayer('hls')?.manualLevel}
                    onChange={((event) => onChangeBitrate(event.target.value))}
                    label={'Quality'}
                    inputProps={{ name: 'max-width', id: 'max-width' }}
                  >
                    <MenuItem value={-1}>
                      <Radio checked={playerRef.current?.getInternalPlayer('hls')?.manualLevel === -1 ? true : false} />
                      <span>
                        {playerRef.current?.getInternalPlayer('hls')?.manualLevel === -1 ? <span style={fontFamily}>Auto {getCurrentQuality?.[0]?.height}p</span> : <span style={fontFamily}>Auto</span>}
                      </span>
                    </MenuItem>
                    {playerRef.current?.getInternalPlayer('hls')?.levels.map(
                      (level, id) =>
                        <MenuItem style={fontFamily} value={id}>
                          <Radio checked={playerRef.current?.getInternalPlayer('hls')?.manualLevel !== -1 && getCurrentQuality?.[0]?.height === level.height ? true : false} />
                          <span>{`${level.height}p`}</span>
                        </MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl sx={{ mt: 2, minWidth: 120 }}>
                  <InputLabel htmlFor="max-width">Speed</InputLabel>
                  <Select
                    autoFocus
                    value={playbackRate}
                    onChange={((event) => onPlaybackRateChange(event.target.value))}
                    label={'Speed'}
                    inputProps={{ name: 'max-width', id: 'max-width' }}
                  >

                    {[
                      { value: 0.25, name: "0.25" },
                      { value: 0.5, name: "0.5" },
                      { value: 0.75, name: "0.75" },
                      { value: 1, name: "Normal" },
                      { value: 1.25, name: "1.25" },
                      { value: 1.5, name: "1.5" },
                      { value: 1.75, name: "1.75" },
                      { value: 2, name: "2" }].map((rate) => (
                        <MenuItem
                          className="controller-quality-list"
                          value={rate.value}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={fontFamily}>
                              {rate.value === playbackRate ? <CheckIcon style={{ paddingRight: '5px' }} /> : <CheckIcon style={{ paddingRight: '5px', opacity: 0 }} />}
                            </span>
                            <span style={fontFamily}>
                              {rate.name}
                            </span>
                          </div>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </DialogContent>
          </Dialog>
        }



        <div
          className="controller-dfc"
          onClick={((event) => getOnReady && handleDoubleClick({ event: event, status: getScreenMode ? false : true }))}
        >
          {(!buffering) && (!loading) &&
            <IconButton
              style={{ background: '#eeeeee1c' }}
              onClick={onPlayPause}
              className={`${`controller-bottom-icons-center`} ${classes.controlIcons}`}
              aria-label="play"
            >
              {playing ? (
                <PauseIcon />
              ) : (
                <PlayArrowIcon />
              )}
            </IconButton>
          }
        </div>

        <div className="controller-dfsb-bottom">
          <div className="mv-direction" style={{ width: '100%' }}>
            <VideoSeekSlider
              className="controller-pro-slider"
              style={{ padding: '30px' }}
              max={maxTimeaa}
              currentTime={currentTimeaa}
              bufferTime={playerRef.current?.getSecondsLoaded() * 1000}
              limitTimeTooltipBySides={true}
              hideThumbTooltip={false}
              onChange={handleSeekChangenew}
              secondsPrefix="00:"
              minutesPrefix="0:"
            />

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', whiteSpace: 'nowrap', overflow: 'auto', direction: 'ltr' }}>
              <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                <IconButton
                  disabled={getOnReady ? false : true}
                  style={{ marginRight: '15px' }}
                  onClick={onPlayPause}
                  className={`${'m-hide'} ${'controller-bottom-icons'} ${classes.bottomIcons}`}>
                  {playing ?
                    <PauseIcon /> :
                    <PlayArrowIcon />
                  }
                </IconButton>
                <IconButton
                  style={{ marginRight: '15px' }}
                  onClick={(() => isMember && handleClickNext())}
                  className={`${'controller-bottom-icons'} ${classes.bottomIcons}`}>
                  <SkipNextIcon />
                </IconButton>

                <IconButton disabled={loading ? true : false} style={{ marginRight: '15px' }} onClick={onFastForward} className={`${'m-hide'} ${'controller-bottom-icons'} ${classes.bottomIcons}`}>
                  <Forward10Icon />
                </IconButton>
                <div className="controller-volume-slider-section" style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                  <IconButton
                    style={{ marginRight: '5px' }}
                    // onClick={() => setState({ ...state, muted: !state.muted })}
                    onClick={onMute}
                    className={`${'m-hide'} ${'speaker-hover'} ${'controller-bottom-icons'} ${classes.bottomIcons} ${classes.volumeButton}`}
                  >
                    {muted ?
                      <VolumeMute />
                      : volume > 0.5 ?
                        <VolumeUp />
                        :
                        <VolumeDown />
                    }
                  </IconButton>
                  <Slider
                    style={{ marginRight: '10px' }}
                    min={0}
                    max={100}
                    value={muted ? 0 : volume * 100}
                    onChange={onVolumeChange}
                    aria-labelledby="input-slider"
                    // className={classes.volumeSlider}
                    className="controller-valume-slider"
                    onMouseDown={onSeekMouseDown}
                    onChangeCommitted={onVolumeSeekDown}
                  />
                </div>
                <div
                  style={{ marginRight: '10px' }}
                  variant="text"
                  onClick={
                    onChangeDispayFormat
                  }
                >
                  <Typography
                    variant="body1"
                    style={{ color: "#fff", marginLeft: 10 }}
                  >
                    {elapsedTime} / {totalDuration}
                  </Typography>
                </div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                <IconButton disabled={loading ? true : false} style={{ marginRight: '15px' }} onClick={(() => handleClickPip(pipMode ? false : true))} className={`${'m-hide'} ${'controller-bottom-icons'} ${classes.bottomIcons}`}>
                  {pipMode ? <PictureInPictureIcon /> : <PictureInPictureAltIcon />}
                </IconButton>
                <IconButton
                  disabled={getOnReady ? false : true}
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={openaa ? 'composition-menu' : undefined}
                  aria-expanded={openaa ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  style={{ marginRight: '15px' }}
                  className={`${'m-hide'} ${'controller-bottom-icons'} ${classes.bottomIcons}`}
                  variant="text"
                >
                  <Settings style={{ width: '1.8rem', height: '1.8rem' }} />
                </IconButton>
                {playerRef.current?.getInternalPlayer('hls') &&
                  <Popper
                    className="cq-dropdown"
                    open={openaa}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="top-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'top-start' ? 'left top' : 'left top',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleCloseaa}>
                            <MenuList
                              autoFocusItem={openaa}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              {!getPlaybackCard && !getQualityCard &&
                                <MenuItem className="controller-quality-list" onClick={() => showPlaybackClick()}>
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#fcfcfc' }}>
                                      <SlowMotionVideoIcon style={{ color: '#fcfcfc', marginRight: '7px', width: '1.5rem', height: '1.5rem' }} />
                                      <span style={fontFamily}>Playback Speed</span>
                                    </div>
                                    <NavigateNextIcon style={{ color: '#fcfcfc', width: '2rem', height: '2rem' }} />
                                  </div>
                                </MenuItem>
                              }
                              {!getQualityCard && !getPlaybackCard &&
                                <MenuItem className="controller-quality-list" onClick={() => showQualityClick()}>
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#fcfcfc' }}>
                                      <TuneIcon style={{ color: '#fcfcfc', marginRight: '7px', width: '1.5rem', height: '1.5rem' }} />
                                      <span style={fontFamily}>Quality</span>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#fcfcfc' }}>
                                      {playerRef.current?.getInternalPlayer('hls')?.manualLevel === -1 ? <span style={fontFamily}>Auto {getCurrentQuality?.[0]?.height || '00'}p</span> : <span style={fontFamily}>{getCurrentQuality?.[0]?.height}p</span>}
                                      <NavigateNextIcon style={{ color: '#fcfcfc', width: '2rem', height: '2rem' }} />
                                    </div>

                                  </div>
                                </MenuItem>
                              }
                              {getPlaybackCard &&
                                <div style={{ transition: '.3s' }}>
                                  <MenuItem style={{ borderBottom: '1px solid #fcfcfc47' }} className="controller-quality-list" onClick={() => hidePlaybackClick()}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', color: '#fcfcfc' }}>
                                      <NavigateBeforeIcon style={{ color: '#fcfcfc', width: '2rem', height: '2rem' }} />
                                      <span style={fontFamily}>Playback Speed</span>
                                    </div>
                                  </MenuItem>
                                  <div className="pq-list-card">
                                    {[
                                      { value: 0.25, name: "0.25" },
                                      { value: 0.5, name: "0.5" },
                                      { value: 0.75, name: "0.75" },
                                      { value: 1, name: "Normal" },
                                      { value: 1.25, name: "1.25" },
                                      { value: 1.5, name: "1.5" },
                                      { value: 1.75, name: "1.75" },
                                      { value: 2, name: "2" }].map((rate) => (
                                        <MenuItem
                                          className="controller-quality-list"
                                          onClick={() => onPlaybackRateChange(rate.value)}>
                                          <div style={{ display: 'flex', alignItems: 'center', color: '#fcfcfc' }}>
                                            <span style={fontFamily}>
                                              {rate.value === playbackRate ? <CheckIcon style={{ paddingRight: '5px' }} /> : <CheckIcon style={{ paddingRight: '5px', opacity: 0 }} />}
                                            </span>
                                            <span style={fontFamily}>
                                              {rate.name}
                                            </span>
                                          </div>
                                        </MenuItem>
                                      ))}
                                  </div>
                                </div>
                              }
                              {getQualityCard &&
                                <div style={{ transition: '.3s' }}>
                                  <MenuItem style={{ borderBottom: '1px solid #fcfcfc47' }} className="controller-quality-list" onClick={() => hideQualityClick()}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', color: '#fcfcfc' }}>
                                      <NavigateBeforeIcon style={{ color: '#fcfcfc', width: '2rem', height: '2rem' }} />
                                      <span style={fontFamily}>Quality</span>
                                    </div>
                                  </MenuItem>
                                  <div className="pq-list-card">
                                    <MenuItem className="controller-quality-list" onClick={() => onChangeBitrate(-1)}>
                                      <FormControlLabel
                                        value="other"
                                        control={<Radio checked={playerRef.current?.getInternalPlayer('hls')?.manualLevel === -1 ? true : false} />}
                                        label={playerRef.current?.getInternalPlayer('hls')?.manualLevel === -1 ? <span style={fontFamily}>Auto {getCurrentQuality?.[0]?.height}p</span> : <span style={fontFamily}>Auto</span>} />
                                    </MenuItem>
                                    {playerRef.current?.getInternalPlayer('hls')?.levels.map(
                                      (level, id) =>
                                        <MenuItem className="controller-quality-list" onClick={() => onChangeBitrate(id)}>
                                          <FormControlLabel
                                            style={fontFamily}
                                            value="other"
                                            control={<Radio checked={playerRef.current?.getInternalPlayer('hls')?.manualLevel !== -1 && getCurrentQuality?.[0]?.height === level.height ? true : false} />}
                                            label={`${level.height}p`} />
                                        </MenuItem>
                                    )}
                                  </div>
                                </div>
                              }
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                }
                <div>
                  <IconButton
                    disabled={getOnReady ? false : true}
                    onClick={((event) => onToggleFullScreen({ event: event, status: getScreenMode ? false : true }))}
                    className={`${'controller-bottom-icons'} ${classes.bottomIcons}`}
                  >
                    {getScreenMode ? <FullscreenExitIcon /> : <FullScreen />}
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

Controls.propTypes = {
  onSeek: PropTypes.func,
  onSeekMouseDown: PropTypes.func,
  onSeekMouseUp: PropTypes.func,
  onDuration: PropTypes.func,
  onRewind: PropTypes.func,
  onPlayPause: PropTypes.func,
  onFastForward: PropTypes.func,
  onVolumeSeekDown: PropTypes.func,
  onChangeDispayFormat: PropTypes.func,
  onPlaybackRateChange: PropTypes.func,
  onToggleFullScreen: PropTypes.func,
  handleDoubleClick: propTypes.func,
  onMute: PropTypes.func,
  playing: PropTypes.bool,
  played: PropTypes.number,
  loaded: PropTypes.number,
  elapsedTime: PropTypes.string,
  totalDuration: PropTypes.string,
  muted: PropTypes.bool,
  playbackRate: PropTypes.number,
  playerRef: PropTypes.func,
  onChangeBitrate: PropTypes.func,
  handleClickPip: PropTypes.func,
  onSetDefaultBitrate: PropTypes.func,
  handleTimeChange: PropTypes.func,
  handleSeekChangenew: PropTypes.func,
};
export default Controls;