import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import "antd/dist/antd.min.css";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import GoogleAuth from '../../Components/GoogleAuth';
import { useLoginUserMutation } from '../../services/login';
import CloseIcon from '@mui/icons-material/Close';
const md5 = require('md5');
import { addToCart } from "../../Reducers/authReducer";
import Button from '@mui/material/Button';
import ForgetPasswordForm from './ForgetPasswordForm';
import ReCAPTCHA from "react-google-recaptcha";
import './index.scss';
// import AppleLogin from 'react-apple-login'
import AppleSignin from 'react-apple-signin-auth';
import AppleIcon from '@mui/icons-material/Apple';

function LoginForm({ signinModal }) {
  const { languageCode, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const recaptchaRef = React.useRef();


  const [loginUser, loginresult] = useLoginUserMutation()

  async function loginUserForm(data) {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    await loginUser({
      email: data.email,
      password: md5(data.password),
      login: 'login',
      language: languageCode,
      country: countryCode,
      publicIp: ``,
      captcha_token: token
    })
  }

  async function googleAuthLogin(data) {
    await loginUser({
      token: data.credential,
      client_id: data.clientId,
      google_login: 'google_login',
      language: languageCode,
      country: countryCode,
      publicIp: ``
    })
  }

  useEffect(() => {
    try {
      if (loginresult?.data) {
        signinModal(false)
        dispatch(addToCart({
          userId: loginresult?.data?.id,
          userToken: loginresult?.data?.user_token,
          userEmail: loginresult?.data?.email,
          userName: loginresult?.data?.name,
          isMember: loginresult?.data?.member === 1 ? true : false,
          isPaymentMethod: loginresult?.data?.default_payment ? true : false,
          isCustomer: loginresult?.data?.customer ? true : false,
        }));
      }
      if (loginresult?.error) {
        setLoginError(true)
        setFPData(false)
        setFPError(false)
      }
    } catch (e) {
    }
  }, [loginresult]);

  const [getFPModal, setFPModal] = useState(false);
  function FPModalOpen() {
    setFPModal(true)
  }

  function signinModalOpen() {
    setFPModal(false)
  }

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'ud') {
    fontFamilyForAll = 'system-ui'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  const fontFamily_Text = {
    textAlign: languageCode === 'ar' || languageCode === 'ud' ? 'right' : 'left',
    fontFamily: fontFamilyForAll,
  }

  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  return (
    <>
      {getFPModal &&
        <ForgetPasswordForm signinModalOpen={signinModalOpen} />
      }
      {!getFPModal &&
        <>
          {loginresult?.error?.data?.error &&
            <div style={{ textAlign: 'center', color: '#ea8087' }}>
              {loginresult?.error?.data?.error}
            </div>
          }
          <form onSubmit={handleSubmit(loginUserForm)}>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey="6LfD62EnAAAAAJ83k0BznR8m_cXInxBFjtTokspL"
            />
            <div className="row">
              <div className="col-md-12">
                <div className="Header-singin-header" style={{ padding: '20px', textAlign: 'center' }}>
                  <h5 style={fontFamily}><span>{t('sign_in')}</span> {t('or')} <span>{t('sign_up')}</span> </h5>
                </div>
              </div>
              <div className="col-md-12" style={{display: 'flex', justifyContent: 'center'}}>
                <div className="form-group" style={{width: '400px', maxWidth: '100%'}}>
                  <label style={fontFamily_Text}>{t('email')} </label>
                  <input
                    style={fontFamily_Text}
                    type="email"
                    className="form-control dlrdflInput"
                    id="email"
                    aria-invalid={errors.email ? "true" : "false"}
                    {...register('email', { required: true })} />
                </div>
              </div>
              <div className="col-md-12" style={{display: 'flex', justifyContent: 'center'}}>
                <div className="form-group" style={{width: '400px', maxWidth: '100%'}}>
                  <label style={fontFamily_Text}>{t('password')} </label>
                  <input
                    style={fontFamily_Text}
                    type="password"
                    className="form-control dlrdflInput"
                    id="password"
                    aria-invalid={errors.password ? "true" : "false"}
                    {...register('password', { required: true, minLength: 8, maxLength: 16 })} />
                  {errors.password && errors.password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
                  {errors.password && errors.password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be maximum 16 digits</span>}
                  {errors.password && errors.password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be minimum 8 digits</span>}
                </div>
              </div>
            
              <div className="col-md-12 Header-signin-button" style={{ justifyContent: 'center' }}>
                <div className="form-group" style={{ textAlign: 'center' }}>
                  <Button
                    disabled={loginresult.isLoading ? true : false}
                    color="info"
                    variant="contained"
                    size="large"
                    type="submit"
                    style={{ width: '400px', maxWidth: '100%', background: '#446275', color: '#f7f7f7' }}
                  >
                    {loginresult.isLoading ? 'Loading...' : t('sign_in')}
                  </Button>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                  <div className="Login-card-separator-wrapper"><div className="Login-card-separator"></div><div className="Login-card-separator-or" style={{ textAlign: 'center' }}>{t('or')}</div><div className="Login-card-separator"></div></div>
                </div>
              </div>

              <div className="col-md-12 Header-signin-button" style={{ justifyContent: 'center' }}>
                <div className="form-group" style={{ textAlign: 'center' }}>

                  {/* <AppleLogin
                    clientId={"com.ebtida.litwebauthservice"}
                    redirectURI={"https://lit.ebtida.com"}
                  /> */}

                  <AppleSignin
                    authOptions={{
                      clientId: 'com.ebtida.litwebauthservice',
                      scope: 'email name',
                      redirectURI: 'https://lit.ebtida.com',
                      state: 'origin:web',
                      nonce: 'nonce',
                      usePopup: true,
                    }}
                    uiType="dark"
                    className="apple-auth-btn"
                    noDefaultStyle={false}
                    buttonExtraChildren="Continue with Apple"
                    onSuccess={(response) =>
                      loginUser({
                        id_token: response?.authorization?.id_token,
                        apple_login: 'apple_login',
                        language: languageCode,
                        country: countryCode,
                      })
                    }
                    onError={(error) => console.error('error')}
                    skipScript={false}
                    iconProp={{ style: { marginTop: '10px' } }}
                    render={(props) =>
                      <Button
                        {...props}
                        disabled={loginresult.isLoading ? true : false}
                        color="info"
                        variant="contained"
                        size="large"
                        type="submit"
                        style={{ width: '400px', maxWidth: '100%', background: '#000', color: '#f7f7f7' }}
                      >
                        <AppleIcon style={{ color: '#fff' }} />
                        <div style={{ margin: '0 10px', color: '#fff' }}>{'Apple Login'}</div>
                      </Button>
                    }
                  />

                </div>
              </div>

            <GoogleAuth googleAuthLogin={googleAuthLogin} />
            
            <div className="col-md-12">
                <div className="form-group" style={{ textAlign: 'center' }}>
                  <Link style={fontFamily_Text} to="#" onClick={(() => FPModalOpen(true))}>{t('forget_password')}</Link>
                </div>
              </div>

            </div>
          </form>
        </>
      }

    </>
  );
}

export default LoginForm;
