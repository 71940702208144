import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const membershipApi = createApi({
  reducerPath: 'membershipApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Membership'],
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({
    getMembershipList: builder.query({
      query: (data) => {
        return {
          url: `api/membership/${data.userId}?page=${data.page}&size=${data.size}`,
          method: 'GET',
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Membership'],
    }),

  }),

})

export const { useGetMembershipListQuery } = membershipApi