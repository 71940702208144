import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useGetHomepageBooksQuery, useGetHomepageDocumentoryQuery, useGetHomepageCoursesQuery, useGetHomepageNewsQuery, useGetHomepageCategoriesQuery, useGetHomepageHighlightsQuery, useGetHomepageAdsQuery } from '../../services/homepage';
import { useSelector } from "react-redux";
import HomePageCard from '../../Components/HomePageCard';
import { useSigninModal } from '../../Components/useModal';
import LoginModal from '../../Layout/Pages/LoginModal';
import { Authentications } from '../../Helpers/Authentications';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Books from '../../Components/Home/Books';
import Documentory from '../../Components/Home/Documentory';
import Courses from '../../Components/Home/Courses';
import Categories from '../../Components/Home/Categories';
import HighLightsSkeleton from './HighLightsSkeleton';
import Banner from '../../Components/Home/Banner';
import Button from '@mui/material/Button';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import { Helmet } from "react-helmet";
import { ParallaxProvider } from 'react-scroll-parallax';
import isMediaLanguageCode from '../../Components/isMediaLanguageCode';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    "&::-webkit-scrollbar": {
      display: "none"
    }, /* Chrome */
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));


function Home(props) {
  const { t } = useTranslation()
  const { isSigninModal, signinModal } = useSigninModal();
  const { isUser, isMember, isDataLoading } = Authentications();
  const { userId, userToken, userEmail, userName, languageCode, mediaLang } = useSelector((state) => state.auth);
  const books = useGetHomepageBooksQuery({ languageCode: isMediaLanguageCode(mediaLang) }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const documentory = useGetHomepageDocumentoryQuery({ languageCode: isMediaLanguageCode(mediaLang) }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const courses = useGetHomepageCoursesQuery({ languageCode: isMediaLanguageCode(mediaLang) }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const news = useGetHomepageNewsQuery({ languageCode: isMediaLanguageCode(mediaLang) }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const categories = useGetHomepageCategoriesQuery({ languageCode: isMediaLanguageCode(mediaLang) }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const highlights = useGetHomepageHighlightsQuery({ languageCode: isMediaLanguageCode(mediaLang) }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const HomePageAds = useGetHomepageAdsQuery({ languageCode: isMediaLanguageCode(mediaLang) }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const [getScrolling, setScrolling] = useState(false);
  const listenScrollEvent = () => {
    window.scrollY > 100 ? setScrolling(true) : setScrolling(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  function checkDirection() {
    if (mediaLang === 'arabic') {
      return true
    } else if (mediaLang === 'urdu') {
      return true
    } else {
      return false
    }
  }
  const fontFamily_Text = {
    textAlign: checkDirection() ? 'right' : 'left',
  }

  function checkDirectionlng() {
    if (languageCode === 'arabic') {
      return true
    } else if (languageCode === 'urdu') {
      return true
    } else {
      return false
    }
  }



  function overlayText() {
    return (
      <div className="banner-content-over" style={{ padding: '20px 0px 20px 0px' }}>
        <div className='container'>
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className={`col-md-8`}>
              <div style={{ textAlign: 'center', whiteSpace: 'normal' }}>
                <h3 className='banner-text-heading' style={{ color: '#f7f7f7', fontSize: '2.5rem' }}>
                  {t('homepage_banner.title')}
                </h3>
                <p className='banner-text' style={{ color: '#f7f7f7', fontSize: '1.2rem' }}>{t('homepage_banner.text')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }




  function bannerImgText() {
    return (
      <div className={`col-md-12 hp-ltext`} style={{ background: '#000', padding: 0, position: 'relative' }}>

        <img className='audio-img-ff' src='https://cdn.ebtida.com/1688631682674-lit=ebtida.png' style={{ width: '100%', height: '375px', objectFit: 'cover' }} />
        <div className="banner-overlay"></div>

        <div style={{ position: 'absolute', right: 0, left: 0, top: 0, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', zIndex: 11 }}>
          <div>
            {/* <div className='hp-title1' style={{ fontSize: '1.7rem', padding: '10px', color: '#f7f7f7' }}>{t('footer_title_1')}</div> */}
            {overlayText()}
            {!isDataLoading &&
              <>
                {isMember ?
                  <Button
                    languageCode={languageCode}
                    component={Link}
                    to={`/${languageCode}/${mediaLang}/search/all?list=${'books'}`}
                    style={{ whiteSpace: 'nowrap', borderRadius: '10px', color: '#f7f7f7', border: '0.5px solid #f7f7f7' }}
                    className="dFlex w100"
                    variant="outlined"
                    size="large"
                  >
                    <HeadphonesIcon style={{ margin: '0 4px' }} />
                    <span className='hp-title2'>{t('footer_title_2')}</span>
                  </Button>
                  :
                  <Link className="w100" to={`/${languageCode}/${mediaLang}/membership`}>
                    <Button
                      style={{ whiteSpace: 'nowrap', borderRadius: '10px', color: '#f7f7f7', border: '0.5px solid #f7f7f7' }}
                      className="dFlex w100"
                      variant="outlined"
                      size="large"
                    >
                      <HeadphonesIcon style={{ margin: '0 4px' }} />
                      <span>{t('start_subscription')}</span>
                    </Button>
                  </Link>

                }
              </>
            }
          </div>

        </div>
      </div>
    )
  }

  const classes = useStyles();

  return (
    <ParallaxProvider>
      <div className="Home-page">
        <Helmet>
          <title>LiT: Big Ideas in Little Time</title>
          <meta name="description" content={'LiT offers highly engaging yet concise Documentaries, Audiobooks and Courses all at one place in your favorite languages all at a cost of a cup of tea.'} />
        </Helmet>

        <LoginModal isSigninModal={isSigninModal} signinModal={signinModal} />

        <div className='container banner-container'>
          <div className="row" style={{ width: '100%', margin: 0 }}>
            {HomePageAds?.data?.ads &&
              <>
                {HomePageAds?.data?.ads?.summaries[0]?.audio &&
                  <Banner
                    languageCode={languageCode}
                    data={HomePageAds?.data?.ads}
                    loading={HomePageAds?.isLoading}
                    length={HomePageAds?.data?.ads?.length}
                  />
                }
                {!HomePageAds?.data?.ads?.summaries[0]?.audio &&
                  // bannerImgText()
                  ''
                }
              </>
            }
            {!HomePageAds?.data?.ads &&
              // bannerImgText()
              ''
            }
          </div>
        </div>


        <div style={{ background: '#fcfcfc', position: 'relative', padding: '20px 0px 30px 0px' }}>
          <div className='container' style={{ padding: '0px' }}>
            <div className='row'>
              <div className="col-md-12">
                {highlights?.data?.highlights &&
                  <div style={{ lineHeight: 1, paddingBottom: '15px' }}>
                    <div className='list-title' style={{ margin: 0, textTransform: 'capitalize', padding: '0px 5px 0px 5px' }}>{t('categories_title')}</div>
                    <h1 style={{ fontSize: '14px', color: '#687074', fontWeight: 400, padding: '0px 5px 0px 5px', margin: 0 }}>{t('categories_subTitle')}</h1>
                  </div>
                }
              </div>
            </div>

            <div className={classes.root}>
              {highlights?.isLoading ?
                <HighLightsSkeleton /> :
                <ImageList className={classes.imageList} cols={5}>
                  {highlights?.data?.highlights?.map((item, i) =>
                    <ImageListItem key={i} component={Link} to={`/${languageCode}/${mediaLang}/search/${item.title}`} className='cat-img-list-item' style={{ height: 'auto', padding: '10px' }}>
                      <div className="hover01 column img-list-card">
                        <div style={{ width: '100%' }}>
                          <figure style={{ margin: 0 }}>
                            <img
                              className='img-list-img'
                              alt={'lit.ebtida.com'}
                              src={`https://m.ebtida.com/i/${isMediaLanguageCode(mediaLang)}/${item.id}/${item.image}`}
                              style={{ transition: "all 0.5s", width: '100%', borderRadius: '7px', height: 'auto' }}
                            />
                          </figure>
                        </div>
                      </div>
                      <div style={fontFamily_Text}>
                        <h6 style={{fontSize: '1rem', fontWeight: 400}}>{item.title}</h6>
                      </div>
                    </ImageListItem>
                  )}
                </ImageList>
              }
            </div>
          </div>
        </div>


        <Books
          data={books?.data?.books}
          loading={books?.isLoading}
          length={books?.data?.books?.length}
          title={t('books_title')}
          subtitle={t('books_subTitle')}
          languageCode={languageCode}
          mediaLang={mediaLang}
          background={'#fcfcfc'}
          backgrounds={'#fcfcfc'}
          padding={'30px'}
        />

        <Courses
          data={courses?.data?.courses}
          loading={courses?.isLoading}
          length={courses?.data?.courses?.length}
          title={t('courses_title')}
          subtitle={t('courses_subTitle')}
          languageCode={languageCode}
          mediaLang={mediaLang}
          background={'#fcfcfc'}
          backgrounds={'#fcfcfc'}
          padding={'30px'}
        />

        <Documentory
          data={documentory?.data?.documentory}
          loading={documentory?.isLoading}
          length={documentory?.data?.documentory?.length}
          title={t('documentaries_title')}
          subtitle={t('documentaries_subTitle')}
          languageCode={languageCode}
          mediaLang={mediaLang}
          background={'#fcfcfc'}
          backgrounds={'#fcfcfc'}
          padding={'30px'}
        />

        <Categories
          data={categories?.data?.categories}
          title={t('categories_title')}
          subtitle={t('categories_subTitle')}
          languageCode={languageCode}
          loginForm={props.loginForm}
          getLanguage={languageCode}
          mediaLang={mediaLang}
        />
        <br />
        <HomePageCard getLanguage={languageCode} mediaLang={mediaLang} />
        <br />
      </div>
    </ParallaxProvider>
  );
}

export default Home;
