const languages = [
  {
    code: 'ds',
    name: 'Desi',
    dir: 'ltr',
    country_code: 'DESI',
    lang: 'ds',
    menu_name: 'DESI'
  },
  {
    code: 'en',
    name: 'English',
    dir: 'ltr',
    country_code: 'EN',
    lang: 'en',
    menu_name: 'EN'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'AR',
    lang: 'ar',
    menu_name: 'العربية'
  },
  {
    code: 'ud',
    name: 'اردو',
    dir: 'rtl',
    country_code: 'URDU',
    lang: 'ud',
    menu_name: 'اردو'
  },
]

export default languages