import React, { useState, useEffect, Fragment } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next'
function Terms() {
  const { t } = useTranslation()
  return (

    <div className="static-container-card" style={{ textAlign: 'left', padding: '20px', background: '#fcfcfc', border: '1px solid #eee', borderRadius: '7px' }}>
      <h1 style={{ padding: '20px 0px 20px 0px', fontSize: '1.4rem', fontWeight: 300 }}>
        {t('terms_and_conditions')}
        <br />
      </h1>
      <p className="terms-text">It is important that you read all the terms and conditions carefully. The following specifies the terms and conditions under which you may use the LiT Ebtida website, service, or product. By using, visiting, or browsing the LiT Ebtida website or using LiT Ebtida services or products, you accept and agree to be bound by these Terms of Use. If you do not agree to these Terms of Use, you should not use the LiT Ebtida website or service. LiT Ebtida may revise these terms at any time by updating the Terms of Use on our site. Continued use of the site after such modifications constitutes your acceptance of the revisions to the agreement. You should visit this page periodically to review the Terms.</p>

      <h4>LiT Ebtida Requirements</h4>
      <p className="terms-text">You must be at least 18 years old and have Internet access, a valid email address, and a valid credit card to use the LiT Ebtida website, service, or product. If you use a password to access this Site or any portion of it, then you are responsible for maintaining the confidentiality of the password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur on your account or with your password. If you are under 18, you may use LiT Ebtida only with involvement of a parent or guardian. You agree to ensure that all information you provide will be current, complete, and accurate and you agree to maintain and update the information on your “My Account” page as required to keep it current, complete, and accurate. You agree that we may store and use information you provide (including credit card information) for use in maintaining your accounts and billing LiT Ebtida fees to your credit card.</p>
     
      <h4>Privacy Policy</h4>
      <p className="terms-text">We do not sell, rent, share or disclose personal information to third parties without your prior consent, except in the limited circumstances where we may contract with others to perform tasks or services on our behalf. For example, we might retain a company to process credit/debit card payments. To read more, kindly visit our Privacy Policy page.</p>
      <h4>Subscription Terms</h4>
      <p className="terms-text">Monthly Subscription members agree to pay a recurring monthly fee by credit/debit card on your monthly renewal date, permitting us to charge membership dues and any applicable tax, until the account is cancelled. Prices of all LiT Ebtida content are subject to change at any time at LiT Ebtida’s sole discretion. All such pricing changes will be posted to the LiT Ebtida website. When a member changes their Membership Plan, the change will be implemented immediately and your credit/debit card will be charged. </p>

      <h4>Trial Offer</h4>
      <p className="terms-text">LiT Ebtida may, in its sole discretion, make available trial offers for a monthly Membership Plan (a "Trial Offer"). Trial Offers are generally for a short period of time (i.e. 30 days) (the "Trial Period"). The length and cost of the Trial Offer will be specified at the time of joining. Those who participate in a Trial Offer ("Trial Members") will have the same membership benefits as the related Membership Plans. Upon expiration of the Trial Period, you will automatically be joined into the related Membership Plan and your credit/debit card will be charged the applicable monthly fee. YOU MUST CANCEL YOUR SUBSCRIPTION PRIOR TO THE END OF THE TRIAL OFFER TO AVOID FURTHER CHARGES TO YOUR CARD. Trial Offers are limited to one per household per lifetime. Terms of trial offers may vary. Specific details of your trial not covered in this Policy will be explained on the web page you are on prior to beginning the Sign Up process or as part of the Sign Up process.</p>
   
      <h5>Electronic Communications</h5>
      <p className="terms-text">When you visit LiT Ebtida or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on this Site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. Your consent to electronic agreements is necessary for use of the LiT Ebtida service.</p>

      <h5>Copyright</h5>
      <p className="terms-text">Content from LiT Ebtida may be used and played for your personal use only and may not be used for any commercial purposes. You agree not to modify, reproduce, retransmit, transfer, distribute, sell, or broadcast to third parties or otherwise exploit for any commercial purpose without the express written consent of LiT Ebtida. All content included on the LiT Ebtida Site, such as text, graphics, logos, audio clips, digital downloads, data compilations, and software, is the property of LiT Ebtida or its suppliers and protected by copyright laws of the United Arab Emirates. The compilation of all content on this Site is the exclusive property of LiT Ebtida and protected by the UAE and international copyright laws. All software used on this Site is the property of LiT Ebtida or its software suppliers and protected by the UAE and international copyright laws.</p>

      <h5>Security</h5>
      <p className="terms-text">We use a variety of means to protect the security of this Site. You agree not to attempt to access data or materials not intended for you, log into a server or account that you are not authorized to access, test the vulnerability of our network and security systems or otherwise breach or circumvent our security in any way.</p>

      <h5>Reviews and Comments</h5>
      <p className="terms-text">We may permit visitors to post reviews and/or comments on the LiT Ebtida site. You agree that any content you contribute will not be illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam." We reserve the right to remove or edit such content.</p>

      <h5>Product Descriptions</h5>
      <p className="terms-text">We attempt to be as accurate as possible. However, LiT Ebtida does not warrant that product descriptions or content available through our service is accurate, complete, reliable, current, or error-free.</p>

      <h5>Cancellation Policy</h5>
      <p className="terms-text">You may cancel your Membership Plan at any time. Your credit/debit card will not be charged at the next billing cycle. To cancel your Membership Plan, you may do so from the “My Account” page within the LiT Ebtida website (after you are Signed In).</p>

      <h5>Refund Policy</h5>
      <p className="terms-text">We do not offer refunds on any subscription charges. We may offer a refund at our sole discretion, depending on the situation.</p>

      <h5>Termination</h5>
      <p className="terms-text">We reserve the right to terminate your use of LiT Ebtida, without notice, at any time if we determine, in our sole discretion, that you have violated these Terms of Use or any other policy posted on the Site.</p>

      <h5>Limitation of Liability</h5>
      <p className="terms-text">The LiT Ebtida service and content are provided on an “As Is” and “As Available” basis. We make no representations or warranties of any kind, express or implied, as to the operation of the LiT Ebtida service and content. Neither we nor any of our suppliers will be liable to you under any circumstances for any damages arising in connection with LiT Ebtida service and/or content.</p>

      <h5>Cancellation Policy</h5>
      <p className="terms-text">You may cancel your Membership Plan at any time. Your credit/debit card will not be charged at the next billing cycle. To cancel your Membership Plan, you may do so from the “My Account” page within the LiT Ebtida website (after you are Signed In).</p>

       

    </div>
  );
}

export default Terms;

