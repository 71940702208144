import React, { useState, useEffect, Fragment } from 'react';
import { useGetUserDataQuery } from '../../services/login';
import { useSelector } from "react-redux";

function Authentications() {
  const { userId, userToken, userEmail, userName, languageCode } = useSelector((state) => state.auth);

  const userData = useGetUserDataQuery({ userId: userId, userToken: userToken }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const isUser = userData?.data?.user;
  const isMember = userData?.data?.user?.membership;
  const isDataLoading = userData?.isLoading;
  const isDataFetching = userData?.isFetching;

  return {
    isUser,
    isMember,
    isDataLoading,
    isDataFetching
  }
};

export { Authentications }