import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useGetBooksBySearchQuery, useAddToLibraryMutation, useRemoveToLibraryMutation } from '../../services/search';
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from "react-redux";
import SearchBooks from '../../Components/SearchBooks';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { Authentications } from '../../Helpers/Authentications';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Loader from '../../Helpers/Loader';
import { Helmet } from "react-helmet";
import isMediaLanguageCode from '../../Components/isMediaLanguageCode';

function Search(props) {
  const { userId, userToken, userEmail, userName, isPaymentMethod, languageCode, mediaLang } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'ud') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
    borderRadius: '10px',
    margin: 7
  }

  const { isUser, isMember, isDataLoading } = Authentications();
  const [getSearchId, setSearchId] = useState("")
  const { title } = useParams();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 24
  const list = new URLSearchParams(location.search).get('list') || 'all'
  const searchData = useGetBooksBySearchQuery({
    page: page,
    size: limit,
    query: title,
    list: list,
    languageCode: isMediaLanguageCode(mediaLang),
    userId: userId,
    userToken: userToken
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const searchBooks = searchData.data && searchData.data?.books;
  const pagination = searchData.data && searchData.data?.pagination;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [page, getSearchId, limit, languageCode, title, list])


  const [addToLibrary, addToLibResult] = useAddToLibraryMutation()
  const [removeToLibrary, rmToLibResult] = useRemoveToLibraryMutation()

  function getLoader(data) {
    if (list === data && searchData?.isFetching) {
      return true
    } else {
      return false
    }
  }


const metaTitle = title !== 'all' ? title?.replace(/-/g, ' ') : list

  return (
    <div className="Search-page">

      <Helmet>
        <title>{metaTitle} | LiT: Big Ideas in Little Time</title>
        <meta name="description" content={'LiT offers highly engaging yet concise Documentaries, Audiobooks and Courses all at one place in your favorite languages all at a cost of a cup of tea.'} />
      </Helmet>

      <div className="container">
        <div className="category-books-section">
          <div className="row">
            <div className="col-md-12">
              <div className='search-page-nav' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box className='search-filter-cards' sx={{ '& button': { m: 1 } }}>
                  <Button
                    disabled={list === 'all' ? true : false}
                    component={Link}
                    to={{
                      pathname: `${window.location.pathname}`,
                      search: `?page=${1}&list=${'all'}`
                    }}
                    style={fontFamily}
                    variant={list === 'all' ? "contained" : "outlined"}
                    size="medium">{t('all')}&nbsp;{getLoader('all') && <Loader />}</Button>

                  <Button
                    disabled={list === 'books' ? true : false}
                    component={Link}
                    to={{
                      pathname: `${window.location.pathname}`,
                      search: `?page=${1}&list=${'books'}`
                    }}
                    style={fontFamily}
                    variant={list === 'books' ? "contained" : "outlined"}
                    size="medium"
                    color="info">{t('books')}&nbsp;{getLoader('books') && <Loader />}</Button>

                  <Button
                    disabled={list === 'documentary' ? true : false}
                    component={Link}
                    to={{
                      pathname: `${window.location.pathname}`,
                      search: `?page=${1}&list=${'documentary'}`
                    }}
                    style={fontFamily}
                    variant={list === 'documentary' ? "contained" : "outlined"}
                    size="medium">{t('documentary')}&nbsp;{getLoader('documentary') && <Loader />}</Button>

                  <Button
                    disabled={list === 'courses' ? true : false}
                    component={Link}
                    to={{
                      pathname: `${window.location.pathname}`,
                      search: `?page=${1}&list=${'courses'}`
                    }}
                    style={fontFamily}
                    variant={list === 'courses' ? "contained" : "outlined"}
                    size="medium">{t('courses')}&nbsp;{getLoader('courses') && <Loader />}</Button>

                  {/* <Button
                    disabled={list === t('news').toLowerCase() ? true : false}
                    component={Link}
                    to={{
                      pathname: `${window.location.pathname}`,
                      search: `?page=${1}&list=${t('news').toLowerCase()}`
                    }}
                    style={{ borderRadius: '100px', margin: 7 }}
                    variant={list === t('news').toLowerCase() ? "contained" : "outlined"}
                    size="medium">{t('news')}&nbsp;{getLoader(t('news').toLowerCase()) && <Loader />}</Button> */}

                </Box>
              </div>
              <br />
            </div>
            <div className="col-md-12 Banner-text">
              <div className="Banner-text-style" style={{ paddingBottom: '15px' }}>
                <h1 style={{ fontWeight: 400, fontSize: '1.2rem' }}>
                  {t('explore_books_on')} <strong>{title}</strong>
                </h1>
              </div>
            </div>
            {searchData &&
              <SearchBooks
                data={searchBooks}
                length={searchBooks?.length}
                loading={searchData?.isLoading}
                removeToLibrary={removeToLibrary}
                addToLibrary={addToLibrary}
                addToLibResult={addToLibResult}
                rmToLibResult={rmToLibResult}
                languageCode={languageCode}
                mediaLang={mediaLang}
              />
            }
          </div>
        </div>
      </div>
      {pagination?.totalPages > 1 &&
        <div className="category-bottom-paginattion">
          <div className="container">
            <div className="pagination-card">
              {searchBooks?.length > 0 &&
                <Pagination
                  dir='ltr'
                  size='large'
                  style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px', direction: 'ltr' }}
                  page={parseInt(page)}
                  count={parseInt(pagination?.totalPages)}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                  disabled={searchData?.isLoading ? true : false}
                  renderItem={(item) => (
                    <PaginationItem
                      component={Link}
                      to={{
                        pathname: `${window.location.pathname}`,
                        search: `?page=${parseInt(item.page)}&list=${list}`
                      }}
                      {...item}
                    />
                  )}
                />
              }
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Search;

