import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useGetUserDataQuery, useUpdateUserMutation, useUpdateUserDetailsMutation, useCancelMembershipMutation } from '../../../services/login';
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { message, Switch as Clickable, Tag } from "antd";
import Button from '@mui/material/Button';
import { Authentications } from '../../../Helpers/Authentications';
import { Helmet } from "react-helmet";

import Box from '@mui/material/Box';
import SideMenu from '../SideMenu';
const drawerWidth = 240;


var moment = require('moment');
function Settings(props) {
  const { isUser, isMember } = Authentications();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { userId, userToken, userEmail, userName, languageCode } = useSelector((state) => state.auth);

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()

  if (!userEmail && !userToken) {
    history.push('/')
  }

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth"
  //   });
  // }, [])


  const userData = useGetUserDataQuery({ userId: userId, userToken: userToken }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const [updateUser, updateUserResult] = useUpdateUserMutation()
  const [updateUserD, updateUserDResult] = useUpdateUserDetailsMutation()

  const [cancelmember, cancelresult] = useCancelMembershipMutation()

  function membershipType(state) {
    let membershipType;
    if (state === 'trial') {
      membershipType = t('trial')
    }
    else if (state === 'membership') {
      membershipType = t('membership')
    } else {
      membershipType = 'None'
    }
    return membershipType;
  }

  function activatedPasstext(plan) {
    return (
      <div><Tag style={{ color: '#f7ee23' }} color={"#252734"}>{t('active')}</Tag>
        {plan === '1_month_full_access' ?
          <span>{t('three_day_benefit1')}</span> :
          <span>{t('seven_day_benefit1')}</span>
        }
      </div>
    )
  }


  function membershipType1(state, plan) {
    let membershipType1;
    if (state === 'trial') {
      membershipType1 = activatedPasstext(plan)
    }
    else if (state === 'membership') {
      membershipType1 = t('membership')
    } else {
      membershipType1 = 'None'
    }
    return membershipType1;
  }

  const [getModal, setModal] = useState(false);
  const [getName, setName] = useState('');
  const [getPhone, setPhone] = useState('');
  function updateUserDetails(data) {
    setModal(data.status)
    setName(data.name)
    setPhone(data.phone)
  }

  function closeModal() {
    setModal(false)
  }

  const updateData = {
    name: getName,
    phone: getPhone,
    userId: userId,
    userToken: userToken,
  }

  const [getPasswordModal, setPasswordModal] = useState(false);
  const [getOldPassword, setOldPassword] = useState('');
  const [getNewPassword, setNewPassword] = useState('');
  function changePassword() {
    setPasswordModal(true)
  }
  function closePasswordModal() {
    setPasswordModal(false)
  }


  async function changePasswordForm(data) {
    await updateUserD({
      old_password: data.old_password,
      new_password: data.new_password,
      userId: userId,
      userToken: userToken
    })
  }




  useEffect(() => {
    if (updateUserDResult?.isSuccess) {
      setModal(false)
      setPasswordModal(false)
      alert('Successfully updated!')
    }
  }, [updateUserDResult]);


  useEffect(() => {
    if (cancelresult?.isSuccess) {
      alert('Your subscription has been cancelled successfully!')
    }
  }, [cancelresult]);



  function getPriceCalculated() {
    if (isMember?.currency === 'BHD') {
      return isMember?.price / 1000
    } else if (isMember?.currency === 'JOD') {
      return isMember?.price / 1000
    } else if (isMember?.currency === 'KWD') {
      return isMember?.price / 1000
    } else if (isMember?.currency === 'OMR') {
      return isMember?.price / 1000
    } else if (isMember?.currency === 'TND') {
      return isMember?.price / 1000
    } else {
      return isMember?.price / 100
    }
  }






  return (
    <div className="Account-page">

      <Helmet>
        <title>{t('settings')} | LiT: Big Ideas in Little Time</title>
      </Helmet>

      <div className="container">
        <div className="profile-card">

          <div className="row">
            <div className="col-md-12">
              <h5 className='account-heading' style={{ paddingBottom: '15px' }}>{t('settings')}</h5>
            </div>
          </div>


          <div className="row">
            <div className="col-md-12 Category-item">
              <div className='Profile-page-section'>
                <SideMenu />
                <Box
                  className="account-page-content-section"
                  component="main"
                  sx={{ flexGrow: 1, p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                  <div className="section-card">
                    {userData?.isLoading ?
                      <>
                        <Skeleton variant="rect" width={'100%'} />
                      </>
                      :
                      <>

                        <>
                          <div className="row-card">
                            <div>
                              <div
                                style={{ color: 'tomato', cursor: 'pointer' }}
                                onClick={() => {
                                  if (window.confirm(t('account_del_text')))
                                    !updateUserDResult?.isLoading && updateUserD({
                                      userId: userId,
                                      userToken: userToken,
                                      mid: isMember?.id,
                                      delete_account: true
                                    })
                                }}
                              >
                                {updateUserDResult?.isLoading ?
                                  <small>loading...</small> :
                                  <small>{t('account_settings.delete_account')}</small>
                                }
                              </div>
                            </div>
                          </div>
                        </>

                      </>
                    }
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
    </div>
  );
}




export default Settings;

