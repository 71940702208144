import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from 'react-bootstrap'
import './index.scss';
function Pagination(props) {
  const location = useLocation();

  return (
    <>
      <div className="newFlex">
        <div className="newFlex2">
          <div className="pagination">
            {props.pagination?.prevPage === null && <Link to="#" disabled>❮❮</Link>}
            {props.pagination?.prevPage !== null && <Link to={{ pathname: `${window.location.pathname}`, search: `?page=1&size=${props.limit}` }}>❮❮</Link>}
            
            {props.pagination?.prevPage === null && <Link to="#" disabled>❮</Link>}
            {props.pagination?.prevPage !== null && <Link to={{ pathname: `${window.location.pathname}`, search: `?page=${props.pagination?.prevPage}&size=${props.limit}` }}>❮</Link>}

            
            {props.pagination?.prevPage !== null && <Link to={{ pathname: `${window.location.pathname}`, search: `?page=${props.pagination?.prevPage}&size=${props.limit}` }}>{ props.pagination?.prevPage }</Link>}
            <Link to="#" className="active" disabled>{ props.pagination?.currentPage }</Link>
            {props.pagination?.nextPage !== null && <Link to={{ pathname: `${window.location.pathname}`, search: `?page=${props.pagination?.nextPage}&size=${props.limit}` }}>{ props.pagination?.nextPage }</Link>}

            
            {props.pagination?.nextPage === null && <Link to="#" disabled>❯</Link>}
            {props.pagination?.nextPage !== null && <Link to={{ pathname: `${window.location.pathname}`, search: `?page=${props.pagination?.nextPage}&size=${props.limit}` }}>❯</Link>}

            {props.pagination?.nextPage === null && <Link to="#" disabled>❯❯</Link>}
            {props.pagination?.nextPage !== null && <Link to={{ pathname: `${window.location.pathname}`, search: `?page=${props.pagination?.totalPages}&size=${props.limit}` }}>❯❯</Link>}
          </div>

        </div>
      </div>
    </>
  );
}

export default Pagination;
