import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    "&::-webkit-scrollbar": {
      display: "none"
    }, /* Chrome */
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));


function HighLightsSkeleton() {

  const classes = useStyles();

  return (
    <ImageList className={classes.imageList} cols={5} style={{width: '100%'}}>
      <ImageListItem className='cat-img-list-item' style={{ height: 'auto', padding: '10px' }}>
        <Skeleton className='m-h-m-size' style={{ width: '100%', borderRadius: '4px' }} />
        <div style={{ width: '100%', padding: '10px 0px 0px 0px' }}>
          <h6><Skeleton style={{height: '12px', width: '65%'}} /></h6>
        </div>
      </ImageListItem>
      <ImageListItem className='cat-img-list-item' style={{ height: 'auto', padding: '10px' }}>
        <Skeleton className='m-h-m-size' style={{ width: '100%', borderRadius: '4px' }} />
        <div style={{ width: '100%', padding: '10px 0px 0px 0px' }}>
          <h6><Skeleton style={{height: '12px', width: '65%'}} /></h6>
        </div>
      </ImageListItem>
      <ImageListItem className='cat-img-list-item' style={{ height: 'auto', padding: '10px' }}>
        <Skeleton className='m-h-m-size' style={{ width: '100%', borderRadius: '4px' }} />
        <div style={{ width: '100%', padding: '10px 0px 0px 0px' }}>
          <h6><Skeleton style={{height: '12px', width: '65%'}} /></h6>
        </div>
      </ImageListItem>
      <ImageListItem className='cat-img-list-item' style={{ height: 'auto', padding: '10px' }}>
        <Skeleton className='m-h-m-size' style={{ width: '100%', borderRadius: '4px' }} />
        <div style={{ width: '100%', padding: '10px 0px 0px 0px' }}>
          <h6><Skeleton style={{height: '12px', width: '65%'}} /></h6>
        </div>
      </ImageListItem>
      <ImageListItem className='cat-img-list-item' style={{ height: 'auto', padding: '10px' }}>
        <Skeleton className='m-h-m-size' style={{ width: '100%', borderRadius: '4px' }} />
        <div style={{ width: '100%', padding: '10px 0px 0px 0px' }}>
          <h6><Skeleton style={{height: '12px', width: '65%'}} /></h6>
        </div>
      </ImageListItem>
      <ImageListItem className='cat-img-list-item' style={{ height: 'auto', padding: '10px' }}>
        <Skeleton className='m-h-m-size' style={{ width: '100%', borderRadius: '4px' }} />
        <div style={{ width: '100%', padding: '10px 0px 0px 0px' }}>
          <h6><Skeleton style={{height: '12px', width: '65%'}} /></h6>
        </div>
      </ImageListItem>
      </ImageList>
  );
}

export default HighLightsSkeleton;
