import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookies from 'js-cookie'

export const roundBooksApi = createApi({
  reducerPath: 'roundBooksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['HomePage'],
  // keepUnusedDataFor: 1,
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({

    getHomepageBooks: builder.query({
      query: (data) => ({
        url: `api/homepage/books/${data.languageCode}`,
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }),
      // keepUnusedDataFor: 1,
      keepUnusedDataFor: 86400000,
      invalidatesTags: ['HomePage'],
    }),


    getHomepageDocumentory: builder.query({
      query: (data) => ({
        url: `api/homepage/documentary/${data.languageCode}`,
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }),
      // keepUnusedDataFor: 1,
      keepUnusedDataFor: 86400000,
      invalidatesTags: ['HomePage'],
    }),

    getHomepageCourses: builder.query({
      query: (data) => ({
        url: `api/homepage/courses/${data.languageCode}`,
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }),
      // keepUnusedDataFor: 1,
      keepUnusedDataFor: 86400000,
      invalidatesTags: ['HomePage'],
    }),

    getHomepageNews: builder.query({
      query: (data) => ({
        url: `api/homepage/news/${data.languageCode}`,
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }),
      // keepUnusedDataFor: 1,
      keepUnusedDataFor: 86400000,
      invalidatesTags: ['HomePage'],
    }),

    getHomepageCategories: builder.query({
      query: (data) => ({
        url: `api/homepage/categories/${data.languageCode}`,
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }),
      // keepUnusedDataFor: 1,
      keepUnusedDataFor: 86400000,
      invalidatesTags: ['HomePage'],
    }),

    getHomepageHighlights: builder.query({
      query: (data) => ({
        url: `api/homepage/highlights/${data.languageCode}`,
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }),
      // keepUnusedDataFor: 1,
      keepUnusedDataFor: 86400000,
      invalidatesTags: ['HomePage'],
    }),

    getHomepageAds: builder.query({
      query: (data) => ({
        url: `api/homepage/ads/${data.languageCode}`,
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }),
      // keepUnusedDataFor: 1,
      keepUnusedDataFor: 86400000,
      invalidatesTags: ['HomePage'],
    }),
    
  }),

})

export const { useGetHomepageBooksQuery, useGetHomepageDocumentoryQuery, useGetHomepageCoursesQuery, useGetHomepageNewsQuery, useGetHomepageCategoriesQuery, useGetHomepageHighlightsQuery, useGetHomepageAdsQuery } = roundBooksApi