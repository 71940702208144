import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookies from 'js-cookie'
export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Search'],
  // keepUnusedDataFor: 1,
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({
    getBooksBySearch: builder.query({
      query: (data) => {
        return {
          url: `api/search/${data.languageCode}/${data.query}?page=${data.page}&size=${data.size}&list=${data.list}`,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      keepUnusedDataFor: 86400000,
      providesTags: ['Search'],
    }),

    addToLibrary: builder.mutation({
      query: (data) => {
        return {
          url: `api/books/${data.languageCode}/${data.userId}/${data.book_id}`,
          method: 'POST',
          body: data,
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Search'],
    }),

    removeToLibrary: builder.mutation({
      query: (data) => {
        return {
          url: `api/library/${data.languageCode}/${data.userId}/${data.book_id}`,
          method: 'DELETE',
          body: data,
          headers: {
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Search'],
    }),

  }),
})
export const { useGetBooksBySearchQuery, useAddToLibraryMutation, useRemoveToLibraryMutation } = searchApi