import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Login'],
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({
    getUserData: builder.query({
      query: (data) => {
        return {
          url: `api/login/${data.userId ? data.userId : null}`,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Login'],
    }),
    loginUser: builder.mutation({
      query: (data) => {
        return {
          url: `api/login`,
          method: 'POST',
          body: data,
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),

    forgetPassword: builder.mutation({
      query: (data) => {
        return {
          url: `api/login/${data.email}`,
          method: 'POST',
          body: data,
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),

    resetPassword: builder.mutation({
      query: (data) => {
        return {
          url: `api/login`,
          method: 'PUT',
          body: data,
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),

    updateUser: builder.mutation({
      query: (data) => {
        return {
          url: `api/membership/${data.userId}/${data.mid}`,
          method: 'PUT',
          body: data,
          headers: {
            'Accept': 'application/json',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),


    updateUserDetails: builder.mutation({
      query: (data) => {
        return {
          url: `api/login/${data.userId}`,
          method: 'PUT',
          body: data,
          headers: {
            'Accept': 'application/json',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),


    cancelMembership: builder.mutation({
      query: (data) => {
        return {
          url: `api/membership/${data.userId}/${data.mid}`,
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),





  }),

})

export const { useLoginUserMutation, useForgetPasswordMutation, useGetUserDataQuery, useResetPasswordMutation, useUpdateUserMutation, useUpdateUserDetailsMutation, useCancelMembershipMutation } = loginApi