import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useGetUserDataQuery, useUpdateUserMutation, useUpdateUserDetailsMutation, useCancelMembershipMutation } from '../../../services/login';
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { message, Switch as Clickable, Tag } from "antd";
import Button from '@mui/material/Button';
import { Authentications } from '../../../Helpers/Authentications';
import Box from '@mui/material/Box';
import SideMenu from '../SideMenu';
import { Helmet } from "react-helmet";
const md5 = require('md5');
const drawerWidth = 240;
var moment = require('moment');

function Profile(props) {
  const { isUser, isMember } = Authentications();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { userId, userToken, userEmail, userName, languageCode } = useSelector((state) => state.auth);

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()

  if (!userEmail && !userToken) {
    history.push('/')
  }

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth"
  //   });
  // }, [])


  const userData = useGetUserDataQuery({ userId: userId, userToken: userToken }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const [updateUser, updateUserResult] = useUpdateUserMutation()
  const [updateUserD, updateUserDResult] = useUpdateUserDetailsMutation()

  const [cancelmember, cancelresult] = useCancelMembershipMutation()

  function membershipType(state) {
    let membershipType;
    if (state === 'trial') {
      membershipType = t('trial')
    }
    else if (state === 'membership') {
      membershipType = t('membership')
    } else {
      membershipType = 'None'
    }
    return membershipType;
  }

  function activatedPasstext(plan) {
    return (
      <div><Tag style={{ color: '#f7ee23' }} color={"#252734"}>{t('active')}</Tag>
        {plan === '1_month_full_access' ?
          <span>{t('three_day_benefit1')}</span> :
          <span>{t('seven_day_benefit1')}</span>
        }
      </div>
    )
  }


  function membershipType1(state, plan) {
    let membershipType1;
    if (state === 'trial') {
      membershipType1 = activatedPasstext(plan)
    }
    else if (state === 'membership') {
      membershipType1 = t('membership')
    } else {
      membershipType1 = 'None'
    }
    return membershipType1;
  }

  const [getModal, setModal] = useState(false);
  const [getName, setName] = useState('');
  const [getPhone, setPhone] = useState('');
  function updateUserDetails(data) {
    setModal(data.status)
    setName(data.name)
    setPhone(data.phone)
  }

  function closeModal() {
    setModal(false)
  }

  const updateData = {
    name: getName,
    phone: getPhone,
    userId: userId,
    userToken: userToken,
    update_profile: true
  }

  const [getPasswordModal, setPasswordModal] = useState(false);
  const [getOldPassword, setOldPassword] = useState('');
  const [getNewPassword, setNewPassword] = useState('');
  function changePassword() {
    setPasswordModal(true)
  }
  function closePasswordModal() {
    setPasswordModal(false)
  }


  async function changePasswordForm(data) {
    await updateUserD({
      old_password: md5(data.old_password),
      new_password: md5(data.new_password),
      userId: userId,
      userToken: userToken,
      change_password: true
    })
  }




  useEffect(() => {
    if (updateUserDResult?.isSuccess) {
      setModal(false)
      setPasswordModal(false)
      alert('Successfully updated!')
    }
  }, [updateUserDResult]);


  useEffect(() => {
    if (cancelresult?.isSuccess) {
      alert('Your subscription has been cancelled successfully!')
    }
  }, [cancelresult]);



  function getPriceCalculated() {
    if (isMember?.currency === 'BHD') {
      return isMember?.price / 1000
    } else if (isMember?.currency === 'JOD') {
      return isMember?.price / 1000
    } else if (isMember?.currency === 'KWD') {
      return isMember?.price / 1000
    } else if (isMember?.currency === 'OMR') {
      return isMember?.price / 1000
    } else if (isMember?.currency === 'TND') {
      return isMember?.price / 1000
    } else {
      return isMember?.price / 100
    }
  }






  return (
    <div className="Account-page">

      <Helmet>
        <title>{t('profile_details')} | LiT: Big Ideas in Little Time</title>
      </Helmet>


      <Modal className="refund-modal" show={getModal} onHide={closeModal} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '1.3rem', fontWeight: 400 }}>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {updateUserDResult?.error?.data?.error &&
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', width: '100%' }}>
              <span className="alert alert-danger" style={{ width: '100%', textAlign: 'center' }}>{updateUserDResult?.error?.data?.error}</span>
            </div>
          }
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control dlrdflInput" defaultValue={isUser?.name} onChange={(e) => setName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Phone</label>
                <input type="number" className="form-control dlrdflInput" defaultValue={isUser?.phone} onChange={(e) => setPhone(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <Button
                  style={{ width: '100%', justifyContent: 'center', background: '#446275', fontWeight: 400, height: '40px' }}
                  color="info"
                  variant="contained"
                  size="medium"
                  onClick={() => !updateUserDResult.isLoading && updateUserD(updateData)}>
                  {updateUserDResult.isLoading ? 'Loading...' : t('update')}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal className="refund-modal" show={getPasswordModal} onHide={closePasswordModal} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '1.3rem', fontWeight: 400 }}>Update Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {updateUserDResult?.error?.data?.error &&
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', width: '100%' }}>
              <span className="alert alert-danger" style={{ width: '100%', textAlign: 'center' }}>{updateUserDResult?.error?.data?.error}</span>
            </div>
          }
          <form onSubmit={handleSubmit(changePasswordForm)}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Current Password</label>
                  <input type="password" className="form-control dlrdflInput"
                    id="old_password"
                    aria-invalid={errors.old_password ? "true" : "false"}
                    {...register('old_password', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>New Password</label>
                  <input
                    type="password"
                    className="form-control dlrdflInput"
                    id="new_password"
                    aria-invalid={errors.new_password ? "true" : "false"}
                    {...register('new_password', { required: true, minLength: 8, maxLength: 16 })} />
                  {errors.new_password && errors.new_password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
                  {errors.new_password && errors.new_password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>new_password should be maximum 16 digits</span>}
                  {errors.new_password && errors.new_password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>new_password should be minimum 8 digits</span>}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <Button style={{ width: '100%', justifyContent: 'center', background: '#446275', fontWeight: 400, height: '40px' }}
                    color="info"
                    variant="contained"
                    size="medium"
                    disabled={updateUserDResult.isLoading ? true : false}
                    type="submit" >
                    {updateUserDResult.isLoading ? 'Loading...' : t('update')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div className="container">
        <div className="profile-card">

          <div className="row">
            <div className="col-md-12">
              <h5 className='account-heading' style={{ paddingBottom: '15px' }}>{t('profile_details')}</h5>
            </div>
          </div>



          <div className="row">
            <div className="col-md-12 Category-item">
              <div className='Profile-page-section'>
                <SideMenu />
                <Box
                  className='account-page-content-section'
                  component="main"
                  sx={{ flexGrow: 1, p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                  <div className="section-card">
                    {userData?.isLoading ?
                      <>
                        <Skeleton variant="rect" width={'100%'} />
                        <Skeleton variant="rect" width={'100%'} />
                        <Skeleton variant="rect" width={'100%'} />
                      </>
                      :
                      <>
                        <>
                          {/* <div className="jcsb ai df" style={{ padding: '10px' }}>
                            <h5><strong>{t('profile_details')}</strong></h5>
                          </div> */}
                          <div className="row-card">
                            <span><strong>{t('name')}:&nbsp;&nbsp;</strong></span>
                            <span>{isUser?.name}</span>
                          </div>
                          <div className="row-card">
                            <span><strong>{t('email')}:&nbsp;&nbsp;</strong></span>
                            <span>{isUser?.email}</span>
                          </div>
                          <div className="row-card">
                            <span><strong>{t('phone')}:&nbsp;&nbsp;</strong></span>
                            <span>{isUser?.phone ? isUser?.phone : ''}</span>
                          </div>
                        </>
                      </>
                    }
                  </div>

                  <div className="section-card">

                    {userData?.isLoading ?
                      <>
                        <Skeleton variant="rect" width={'100%'} />
                        <Skeleton variant="rect" width={'100%'} />
                        <Skeleton variant="rect" width={'100%'} />
                      </>
                      :
                      <>
                        <div className="row-card">
                          <div>
                            <div style={{ paddingBottom: '5px' }}>{t('update_profile_text')}</div>
                            <Button
                              onClick={(() => updateUserDetails({
                                status: true,
                                name: isUser?.name,
                                phone: isUser?.phone
                              }))}
                              color="primary"
                              variant="outlined"
                              size="large"
                            ><small>{t('update')}</small></Button>
                          </div>
                        </div>
                        <div className="row-card">
                          <div>
                            <div style={{ paddingBottom: '5px' }}>{t('change_password_text')}</div>
                            <Button
                              onClick={(() => changePassword())}
                              color="warning"
                              variant="outlined"
                              size="large"
                            ><small>{t('change_password')}</small></Button>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
    </div>
  );
}




export default Profile;

