import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import CarouselSkeleton from '../../CarouselSkeletonB';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import isMediaLanguageCode from '../../isMediaLanguageCode';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    "&::-webkit-scrollbar": {
      display: "none"
    },
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

function Courses({ data, loading, length, title, subtitle, languageCode, background, backgrounds, padding, getLanguage, mediaLang }) {

  function checkthiClick(media, bookId, itemId, title, category) {
    if (category === 'courses') {
      return `/${languageCode}/${mediaLang}/tutorial/${bookId}/${itemId}/${title?.replace(/\s+/g, '-').toLowerCase()}`
    } else if (category === 'documentary') {
      return `/${languageCode}/${mediaLang}/tutorial/${bookId}/${itemId}/${title?.replace(/\s+/g, '-').toLowerCase()}`
    } else {
      return `/${languageCode}/${mediaLang}/book/${bookId}/${itemId}/${title?.replace(/\s+/g, '-').toLowerCase()}`
    }
  }

  function checkDirection() {
    if (mediaLang === 'arabic') {
      return true
    } else if (mediaLang === 'urdu') {
      return true
    } else {
      return false
    }
  }


  const fontFamily_Text = {
    textAlign: checkDirection() ? 'right' : 'left',
    padding: 0
  }


  const classes = useStyles();

  return (
    <div className="recentlySection" style={{ background: backgrounds }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{ padding: 0 }}>
            {data &&
              <div style={{ lineHeight: 1, paddingBottom: '15px' }}>
                <h3 className='list-title' style={{ margin: 0, textTransform: 'capitalize' }}>{title}</h3>
                <div style={{ fontSize: '14px', color: '#687074', fontWeight: 400 }}>{subtitle}</div>
              </div>
            }
          </div>
          <div className="col-md-12" style={{ padding: 0 }}>
            {loading && <CarouselSkeleton />}
            {length === 0 && <CarouselSkeleton />}

            <div>
              <ImageList className={classes.imageList} cols={4}>
                {data?.map((item, i) =>
                  <ImageListItem className='v-m-item' style={{ padding: '5px', height: 'auto' }} key={i}>
                    <Link disabled={!item?.book ? true : false} to={checkthiClick(item.media_type, item.book_id, item.id, item.title, item.category)}>
                      <div className="img-list-card">
                        <img
                          alt={'lit.ebtida.com'}
                          src={`https://dj70nht8amdum.cloudfront.net/${item.book_id}/${isMediaLanguageCode(mediaLang)}/${item.id}/${item.image}`}
                          style={{ transition: "all 0.5s", width: '100%', borderRadius: '7px' }}
                        />
                      </div>
                      <div className="Category-text" style={fontFamily_Text}>
                        <h4>{item.title}</h4>
                        {item.author &&
                          <div className="authors">{item.author}</div>
                        }
                        <div className="subtitle">{item.subtitle}</div>
                      </div>
                    </Link>
                  </ImageListItem>
                )}
              </ImageList>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
