import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useGetUserDataQuery, useUpdateUserMutation, useUpdateUserDetailsMutation, useCancelMembershipMutation } from '../../../services/login';
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { message, Switch as Clickable, Tag } from "antd";
import Button from '@mui/material/Button';
import { Authentications } from '../../../Helpers/Authentications';
import { Helmet } from "react-helmet";

import Box from '@mui/material/Box';
import SideMenu from '../SideMenu';
const drawerWidth = 240;


var moment = require('moment');
function Subscription(props) {
  const { isUser, isMember } = Authentications();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { userId, userToken, userEmail, userName, languageCode } = useSelector((state) => state.auth);

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()

  if (!userEmail && !userToken) {
    history.push('/')
  }

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth"
  //   });
  // }, [])


  const userData = useGetUserDataQuery({ userId: userId, userToken: userToken }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const [updateUser, updateUserResult] = useUpdateUserMutation()
  const [updateUserD, updateUserDResult] = useUpdateUserDetailsMutation()

  const [cancelmember, cancelresult] = useCancelMembershipMutation()

  function membershipType(state) {
    let membershipType;
    if (state === 'trial') {
      membershipType = t('trial')
    }
    else if (state === 'membership') {
      membershipType = t('membership')
    } else {
      membershipType = 'None'
    }
    return membershipType;
  }

  function activatedPasstext(plan) {
    return (
      <div><Tag style={{ color: '#f7ee23' }} color={"#252734"}>{t('active')}</Tag>
        {plan === '1_month_full_access' ?
          <span>{t('three_day_benefit1')}</span> :
          <span>{t('seven_day_benefit1')}</span>
        }
      </div>
    )
  }


  function membershipType1(state, plan) {
    let membershipType1;
    if (state === 'trial') {
      membershipType1 = activatedPasstext(plan)
    }
    else if (state === 'membership') {
      membershipType1 = t('membership')
    } else {
      membershipType1 = 'None'
    }
    return membershipType1;
  }

  const [getModal, setModal] = useState(false);
  const [getName, setName] = useState('');
  const [getPhone, setPhone] = useState('');
  function updateUserDetails(data) {
    setModal(data.status)
    setName(data.name)
    setPhone(data.phone)
  }

  function closeModal() {
    setModal(false)
  }

  const updateData = {
    name: getName,
    phone: getPhone,
    userId: userId,
    userToken: userToken,
  }

  const [getPasswordModal, setPasswordModal] = useState(false);
  const [getOldPassword, setOldPassword] = useState('');
  const [getNewPassword, setNewPassword] = useState('');
  function changePassword() {
    setPasswordModal(true)
  }
  function closePasswordModal() {
    setPasswordModal(false)
  }


  async function changePasswordForm(data) {
    await updateUserD({
      old_password: data.old_password,
      new_password: data.new_password,
      userId: userId,
      userToken: userToken
    })
  }




  useEffect(() => {
    if (updateUserDResult?.isSuccess) {
      setModal(false)
      setPasswordModal(false)
      alert('Successfully updated!')
    }
  }, [updateUserDResult]);


  useEffect(() => {
    if (cancelresult?.isSuccess) {
      alert('Your subscription has been cancelled successfully!')
    }
  }, [cancelresult]);



  function getPriceCalculated() {
    if (isMember?.currency === 'BHD') {
      return isMember?.price / 1000
    } else if (isMember?.currency === 'JOD') {
      return isMember?.price / 1000
    } else if (isMember?.currency === 'KWD') {
      return isMember?.price / 1000
    } else if (isMember?.currency === 'OMR') {
      return isMember?.price / 1000
    } else if (isMember?.currency === 'TND') {
      return isMember?.price / 1000
    } else {
      return isMember?.price / 100
    }
  }


  return (
    <div className="Account-page">

      <Helmet>
        <title>{t('membership_details')} | LiT: Big Ideas in Little Time</title>
      </Helmet>

      <div className="container">
        <div className="profile-card">
          <div className="row">
            <div className="col-md-12">
              <h5 className='account-heading' style={{ paddingBottom: '15px' }}>{t('membership_details')}</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 Category-item">
              <div className='Profile-page-section'>
                <SideMenu />
                <Box
                  className='account-page-content-section'
                  component="main"
                  sx={{ flexGrow: 1, p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                  <div className="section-card">
                    {userData?.isLoading ?
                      <>
                        <Skeleton variant="rect" width={'100%'} />
                        <Skeleton variant="rect" width={'100%'} />
                        <Skeleton variant="rect" width={'100%'} />
                      </>
                      :
                      <>
                        {!isMember &&
                          <div className="row-card" style={{ justifyContent: 'center' }}>
                            <div>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Link to="/membership">
                                  <Button
                                    color="warning"
                                    variant="outlined"
                                    size="large"
                                    style={{ display: 'flex', alignItems: 'center', fontWeight: 400 }}
                                  >{t('start_membership')}
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        }
                        <>
                          {isMember &&
                            <>
                              <div className="row-card">
                                <span><strong>{t('active_plan')}:&nbsp;&nbsp;</strong></span>
                                <span>
                                  {membershipType1(isMember?.state, isMember?.plan)}
                                </span>
                              </div>
                              <div className="row-card">
                                <span><strong>{t('price')}:&nbsp;&nbsp;</strong></span>
                                <span>{isMember?.currency} {getPriceCalculated()} </span>
                              </div>

                              <div className="row-card">
                                <span><strong>{t('subscription')}:&nbsp;&nbsp;</strong></span>
                                <span>{isMember?.plan?.split('_').join(' ')}</span>
                              </div>

                              <div className="row-card">
                                <span><strong>{t('start_date')}:&nbsp;&nbsp;</strong></span>
                                <span>{moment(isMember?.start_date).format("MMM Do YYYY")}</span>
                              </div>
                              <div className="row-card">
                                <span><strong>{t('end_date')}: </strong>&nbsp;&nbsp;</span>
                                <span>{moment(isMember?.end_date).format("MMM Do YYYY")}</span>
                              </div>
                              <div className="row-card">
                                <span><strong>{t('auto_renew_on')}:</strong>&nbsp;&nbsp;</span>
                                <div>
                                  <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <Tag color={isMember?.auto_renew ? "#7fc687" : '#ea8087'}>
                                      <small>{isMember?.auto_renew ? t('enabled') : t('disabled')}</small>
                                    </Tag>
                                    {moment(isMember?.end_date).add(1, 'days').format('MMM Do YYYY')}
                                  </span>
                                </div>
                              </div>
                            </>
                          }
                        </>
                      </>
                    }
                  </div>

                  <div className="section-card">

                    {userData?.isLoading ?
                      <>
                        <Skeleton variant="rect" width={'100%'} />
                        <Skeleton variant="rect" width={'100%'} />
                        <Skeleton variant="rect" width={'100%'} />
                      </>
                      :
                      <>
                        {isMember &&
                          <>
                            {isMember?.payment_source === 'stripe' &&
                              <>
                                <div className="row-card">
                                  <div>
                                    <div style={{ paddingBottom: '5px' }}>{t('auto_renew_text')}</div>
                                    <Button
                                      onClick={() => {
                                        if (window.confirm('Are you sure to change?'))
                                          !updateUserResult?.isLoading && updateUser({
                                            auto_renew: isMember?.auto_renew ? false : true,
                                            userId: userId,
                                            userToken: userToken,
                                            mid: isMember?.id
                                          })
                                      }}
                                      style={{ display: 'flex', alignItems: 'center' }}
                                      color={isMember?.auto_renew ? 'error' : 'warning'}
                                      variant="outlined"
                                      size="large"
                                    >
                                      {updateUserResult?.isLoading ?
                                        <small>loading...</small> :
                                        <small>{isMember?.auto_renew ? t('disable_auto_renew') : t('enable_auto_renew')}</small>
                                      }
                                    </Button>
                                  </div>
                                </div>
                                <div className="row-card">
                                  <div>
                                    <div style={{ paddingBottom: '5px' }}>
                                      {isMember?.state === 'trial' && t('cancel_trial_text')}
                                      {isMember?.state === 'membership' && t('cancel_membership')}
                                    </div>
                                    <Button
                                      onClick={() => {
                                        if (window.confirm('Are you sure to cancel your subscription plan?'))
                                          !cancelresult?.isLoading && cancelmember({
                                            userId: userId,
                                            userToken: userToken,
                                            mid: isMember?.id
                                          })
                                      }}
                                      style={{ display: 'flex', alignItems: 'center' }}
                                      color="error"
                                      variant="outlined"
                                      size="large"
                                    >
                                      {cancelresult?.isLoading ?
                                        <small>loading...</small> :
                                        <small>{t('cancel_membership')} {membershipType(isMember?.state)}</small>
                                      }
                                    </Button>
                                  </div>
                                </div>
                              </>
                            }


                            {isMember?.payment_source === 'apple' &&
                              <div style={{ padding: '10px', fontSize: '15px' }}>
                                {t('manage_subs_text')}
                              </div>
                            }

                          </>
                        }
                      </>
                    }
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}




export default Subscription;

