import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next'
function HomePageCard({ }) {
  const { t } = useTranslation()
  return (
    <div className="container">
      <div className="Dashboard-information">
        <br />
        <h5 className='card-title' style={{ textAlign: 'center' }}>{t('homepage_cards.heading')}</h5>
        <br /><br />
        <div className="row">
          <div className="col-md-4 Dashboard-information-sub">
            <div className="Dashboard-information-card">
              <img src="/BooksIcon.png" />
              <h4>{t('homepage_cards.title_1')}</h4>
              <p>{t('homepage_cards.description_1')}</p>
            </div>
          </div>
          <div className="col-md-4 Dashboard-information-sub">
            <div className="Dashboard-information-card">
              <img src="/TutorialsIcon.png" />
              <h4>{t('homepage_cards.title_2')}</h4>
              <p>{t('homepage_cards.description_2')}</p>
            </div>
          </div>
          <div className="col-md-4 Dashboard-information-sub">
            <div className="Dashboard-information-card">
              <img src="/documentaryIcon.png" />
              <h4>{t('homepage_cards.title_3')}</h4>
              <p>{t('homepage_cards.description_3')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePageCard;
