import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import Terms from '../../Components/Terms';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next'
function TermsConditions() {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="statisc-page terms-page">
      <Helmet>
        <title>{t('terms_and_conditions')} | LiT: Big Ideas in Little Time</title>
        <meta name="description" content={'LiT offers highly engaging yet concise Documentaries, Audiobooks and Courses all at one place in your favorite languages all at a cost of a cup of tea.'} />
      </Helmet>
      <br /> <br />
      <div className="container static-container">
        <div className="row" style={{ justifyContent: 'center' }}>
          <div className="col-md-10 tc_m">
            <Terms />
          </div>
        </div>
      </div>
      <br /> <br />
    </div>
  );
}

export default TermsConditions;

