import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import { SearchOutlined } from '@ant-design/icons';
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useGetAllPaymentsQuery, useRemovePaymentsMutation, useDefaultPaymentsMutation } from '../../../services/payments';
import { useGetUserDataQuery } from '../../../services/login';
import TableSkeletonPayment from "../TableSkeletonPayment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../Purchase/Checkout";
import { message, Switch as Clickable, Tag } from "antd";
import { useSelector } from "react-redux";
import { Authentications } from '../../../Helpers/Authentications';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Helmet } from "react-helmet";

import Box from '@mui/material/Box';
import SideMenu from '../SideMenu';
const drawerWidth = 240;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const successMessage = () => {
  return (
    <div className="success-msg">
      <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
      </svg>
      <div className="title">New Payment Added Successfully</div>
    </div>
  )
}



function PaymentMethod() {
  const { isUser, isMember } = Authentications();
  const { userId, userToken, userEmail, userName, languageCode, mediaLang } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()


  if (!userEmail && !userToken) {
    history.push('/')
  }

  const userData = useGetUserDataQuery({ userId: userId, userToken: userToken }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const customerNumber = userData?.data?.user?.customer


  const responseInfo = useGetAllPaymentsQuery({ userId: userId, userToken: userToken }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const paymentData = responseInfo?.data?.result?.data
  const customerData = responseInfo?.data?.customer


  const [removePayment, removeResult] = useRemovePaymentsMutation()
  const [makeDefault, defaultResult] = useDefaultPaymentsMutation()


  const [paymentCompleted, setPaymentCompleted] = useState(false);


  const [getModal, setModal] = useState(false)
  function paymentModal() {
    setModal(true)
  }

  function closeModal() {
    setModal(false)
  }
  useEffect(() => {
    if (paymentCompleted) {
      setModal(false)
    }
  }, [paymentCompleted]);


  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'ud') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  const fontFamily_Text = {
    textAlign: languageCode === 'ar' || languageCode === 'ud' ? 'right' : 'left',
    fontFamily: fontFamilyForAll,
  }

  return (
    <div className="Account-page tabel_cards">

      <Helmet>
        <title>{t('payment_methods')} | LiT: Big Ideas in Little Time</title>
      </Helmet>


      <Modal style={fontFamily_Text} className="purchase_modal" show={getModal} onHide={closeModal} centered animation={false}>
        <Modal.Body>
          <div className="Header-close-modal" style={{ position: 'absolute', right: '10px', top: '10px', zIndex: 1 }} onClick={closeModal}>
            <IconButton
              aria-haspopup="true"
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
          </div>
          <h5 style={{ padding: '20px', textAlign: 'center' }}>{t('add_new_payment_method')}</h5>
          {
            paymentCompleted ?
              successMessage() :
              <React.Fragment>
                <div className="col-md-12 order-md-1">
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      customerNumber={customerNumber && customerNumber}
                      setPaymentCompleted={setPaymentCompleted}
                      getPaymentMethodForm={true}
                      language={languageCode}
                      mediaLang={mediaLang}
                    />
                  </Elements>
                </div>
              </React.Fragment>
          }
        </Modal.Body>
      </Modal>


      <div className="container">
        <div className="profile-card">

          <div className="row">
            <div className="col-md-12">
              <h5 className='account-heading' style={{ paddingBottom: '15px' }}>{t('payment_methods')}</h5>
            </div>
          </div>


          <div className="row cardList__margin">
            <div className="col-md-12">
              <div className='Profile-page-section'>
                <SideMenu />
                <Box
                  className='account-page-content-section'
                  component="main"
                  sx={{ flexGrow: 1, p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >

                  {!isMember &&
                    <div className="row-card" style={{ justifyContent: 'center' }}>
                      <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Link to="/membership">
                            <Button
                              color="warning"
                              variant="outlined"
                              size="large"
                              style={{ display: 'flex', alignItems: 'center', fontWeight: 400 }}
                            >{t('start_membership')}
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  }

                  {isMember &&
                    <>
                      {isMember?.payment_source === 'apple' &&
                        <div style={{ padding: '10px', fontSize: '15px' }}>
                          {t('manage_subs_text')}
                        </div>
                      }

                      {isMember?.payment_source === 'stripe' &&
                        <>
                          <div className="section-card">
                            <div className="jcsb ai df">
                              <div>&nbsp;</div>
                              <div className="table_search_card">
                                <div className="newFlexPagi" style={{ padding: '0px 0px 10px 0px' }}>
                                  {paymentData && paymentData.length === 5 || paymentData && paymentData.length < 5 &&
                                    <Button
                                      color="warning"
                                      variant="outlined"
                                      size="large"
                                      onClick={paymentModal}>{t('add_new_payment_method')}</Button>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tabelOverflow">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>{t('type')}</th>
                                  <th>{t('card')}</th>
                                  <th>{t('country')}</th>
                                  <th>{t('name')}</th>
                                  <th className="sticky-column" style={{ textAlign: 'right' }}>
                                    {t('status')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <>
                                  {responseInfo?.isLoading &&
                                    <TableSkeletonPayment />
                                  }
                                </>
                                {paymentData?.map((item, i) =>
                                  <tr key={i}>
                                    <td>
                                      <div style={{ textTransform: 'capitalize' }}>{item.card?.brand} {item.type}</div>
                                    </td>
                                    <td>
                                      <div>**** **** **** {item.card?.last4}</div>
                                    </td>
                                    <td>
                                      <div>{item.card?.country}</div>
                                    </td>
                                    <td>
                                      <div>{item.billing_details?.name}</div>
                                    </td>
                                    <td className="sticky-column" style={{ display: 'flex', justifyContent: 'right' }}>
                                      <div className="manage-buttons">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          {customerData?.invoice_settings?.default_payment_method === item.id ?
                                            // <Tag style={{ margin: 0 }} color={'#7fc687'}>Default</Tag>
                                            <Button style={{ display: 'flex' }}
                                              color="info"
                                              disabled
                                              variant="outlined"
                                              size="medium"
                                            >
                                              <small>Default</small>
                                            </Button>
                                            :
                                            <Button style={{ display: 'flex', marginRight: '10px' }}
                                              color="info"
                                              variant="outlined"
                                              size="medium"
                                              onClick={() => makeDefault({ payment: item.id, customer: customerData?.id, country: item.card?.country, userId: userId, userToken: userToken })}>
                                              {defaultResult.isLoading && defaultResult?.originalArgs?.payment === item.id ?
                                                <div className="spinner-border spinner-border-sm text-dark" role="status"></div> :
                                                <small>Make this default</small>}
                                            </Button>
                                          }
                                          {customerData?.invoice_settings?.default_payment_method !== item.id &&
                                            <Button style={{ display: 'flex' }}
                                              color="error"
                                              variant="outlined"
                                              size="medium"
                                              onClick={() => removePayment({ payment: item.id, userToken: userToken, userId: userId })}>
                                              {removeResult.isLoading && removeResult?.originalArgs?.payment === item.id ?
                                                <div className="spinner-border spinner-border-sm text-dark" role="status"></div> :
                                                <small>Remove</small>}
                                            </Button>
                                          }
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                                }
                              </tbody>
                            </table>
                            {paymentData?.length === 0 &&
                              <div className="no-data-found"> No data found</div>
                            }
                          </div>
                        </>
                      }
                    </>
                  }
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default PaymentMethod;