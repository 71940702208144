import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import './index.scss';
import Hls from "hls.js";
import plyr from "plyr";
import "plyr/dist/plyr.css";
import IconButton from '@mui/material/IconButton';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Forward10Icon from '@mui/icons-material/Forward10';
import Replay10Icon from '@mui/icons-material/Replay10';

const controls = `
<div class="plyr__controls voice-controls book-lit-player" style="display: block;">
  <div>
   
    <div style="display: flex; align-items: center; color: #000; width: 100%">
    <div class="plyr__time plyr__time_voice plyr__time--current" aria-label="Current time" style="padding: 0px 10px 0px 10px;">00:00</div>
    <div class="plyr__progress" style="width: 100%;">
    <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
      <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
      <span role="tooltip" class="plyr__tooltip">00:00</span>
  </div> 
    <div class="plyr__time plyr__time_voice plyr__time--duration" aria-label="Duration" style="padding: 0px 10px 0px 10px;">00:00</div>
  </div> 
  </div> 
</div>
`;


function Player({ book_id, language, book_item_id, id, media }) {
  // const src = `https://m.ebtida.com/a/${book_id}/${language}/${book_item_id}/${id}/${media}/index.m3u8`;
  const src = `https://d1ozu9ddqfby9k.cloudfront.net/a/${book_id}/${language}/${book_item_id}/${id}/${media}/audio_${id}_master.m3u8`
  const video = document.getElementById("player");

  const playerRef = useRef(null);


  const [getOnReady, setOnReady] = useState(false);
  video?.addEventListener('ready', (event) => {
    setOnReady(event.detail?.plyr?.isAudio)
  });


  const [getStopPlyr, setStopPlyr] = useState(false);

  video?.addEventListener('ended', () => {
    setStopPlyr(true)
  });




  React.useMemo(() => {
    const defaultOptions = {
      controls: controls,
      seekTime: 10
    };
    const hls = new Hls();
    hls.loadSource(src);
    hls.attachMedia(video);

    hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
      const availableQualities = hls.levels.map((l) => l.height);
      window.hls.currentLevel = -1
      availableQualities.unshift(0);
      defaultOptions.quality = {
        default: 0, //Default - AUTO
        options: availableQualities,
        forced: true,
        onChange: (e) => updateQuality(e)
      };
      hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
        var span = document.querySelector(
          ".plyr__menu__container [data-plyr='quality'][value='0'] span"
        );
        if (hls.autoLevelEnabled) {
          span.innerHTML = `Auto (${hls.levels[data.level].height}p)`;
        } else {
          span.innerHTML = `Auto`;
        }
      });
      new plyr(video, defaultOptions);
    });
    hls.attachMedia(video);
    window.hls = hls;

  }, [src, video]);
  function updateQuality(newQuality) {
    if (newQuality === 0) {
      window.hls.currentLevel = -1; //Enable AUTO quality if option.value = 0
    } else {
      window.hls.levels.forEach((level, levelIndex) => {
        if (level.height === newQuality) {
          window.hls.currentLevel = levelIndex;
        }
      });
    }
  }

  const [isPlaying, setIsPlaying] = useState(false);
  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  useEffect(() => {
    if (getOnReady) {
      if (isPlaying) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    }

    // if (getStopPlyr) {
    //   playerRef?.current?.stop()
    // }

  }, [isPlaying, playerRef, getOnReady]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', border: '1px solid #eee', borderRadius: '100px', background: '#eee', direction: 'ltr' }}>
        <IconButton
          style={{ background: getOnReady ? '#eeee' : '#fff0' }}
          className='custom-button-arrows center-i'
          id="composition-button"
          aria-haspopup="true"
          onClick={togglePlayPause}
        >
          {getOnReady &&
            <>
              {isPlaying ?
                <PauseIcon style={{ width: '2rem', height: '2rem' }} /> :
                <PlayArrowIcon style={{ width: '2rem', height: '2rem' }} />
              }
            </>
          }
        </IconButton>

        <audio className="audio-plyr" ref={playerRef} id="player" autoPlay={false}>
        </audio>

      </div>
    </>
  );
}

export default Player;
